import React from 'react';
import styled from 'styled-components';
import Slider from '@material-ui/core/Slider';
/**import Slider from '@material-ui/lab/Slider';*/
import Settings from 'components/ui/Settings';
import SliderLegend from 'components/ui/SliderLegend';
import Help from 'components/ui/Help';
import SmallChart from 'components/ui/SmallChart';
import { trayectoriasPib } from 'config';

const Spacer = styled.div`
  display: block;
  height: 20px;
`;

/**
 * Permite seleccionar los parámetros generales comunes
 * a diferentes políticas energéticas.
 */
const GeneralParameters = ({ settings, onChange }) => {
  const selectedTrayectoriaPib = settings.tasa_pib || 2;

  return (
    <Settings.Panel>
      <h4>Parámetros Generales</h4>
      <Settings.Item>
        <Help title={(
          <label>
            Tasa PIB
          </label>
        )}>
          <SmallChart
            id="tasa_pib"
            info="Trayectoria PIB"
            data={trayectoriasPib.find(item => item.value === selectedTrayectoriaPib)}
          />
        </Help>
        <Spacer />
        <Slider
          value={selectedTrayectoriaPib}
          min={1}
          max={3}
          step={1}
          onChange={(e, value) => {
            onChange({
              ...settings,
              tasa_pib: value,
            })
          }}
        />
        <SliderLegend
          options={[
            {
              label: 'Baja',
              value: 1,
            },
            {
              label: 'Media',
              value: 2,
            },
            {
              label: 'Alta',
              value: 3,
            }
          ]}
          onChange={(value) => {
            onChange({
              ...settings,
              tasa_pib: value,
            })
          }}
        />
      </Settings.Item>
    </Settings.Panel>
  );
}

export default GeneralParameters;
