import BigNumber from './layout/bigNumber';
import Fact from './layout/fact';
import Wrapper from './layout/wrapper';
import React from 'react';

const Factor_Emision = ({año, newValue}) => {
    return (
        <Wrapper>
          <div>
            <Fact>
              <h4>
              El factor de emisión de la red eléctrica es:
              </h4>
              <BigNumber>
                <h1>
                  {  newValue.toFixed(2).replace('.', ',') } 
                </h1>
                <label> tCO<sub>2</sub>/MWh en el año {año}.</label>
              </BigNumber>
            </Fact>
          </div>
        </Wrapper>
      );
}
export default Factor_Emision;
