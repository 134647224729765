import React from 'react';
import HelpContent from './HelpContent';
//import proyeccionParticipacion from 'img/proyeccion_participacion.png';

const ReglamentacionTermicaViviendasHelp = () => (
  <HelpContent>
    <h1>Aumento de la Reglamentacion Termica Viviendas</h1>
    <p>
    Esta medida consiste en aumentar la exigencia de la reglamentación térmica de las viviendas nuevas. La envolvente térmica consiste en una serie de 
    elementos constructivos a través de los cuales se produce el flujo térmico entre el ambiente interior y el ambiente exterior del edificio. 
    Está constituida básicamente por los complejos de techumbre, muros, pisos y ventanas. Edificar de manera sustentable, por medio de la incorporación 
    de estándares de sustentabilidad en el diseño, construcción y reacondicionamiento de edificaciones, a fin de minimizar los requerimientos energéticos
    y las externalidades ambientales a lo largo de su ciclo de vida, alcanzando niveles adecuados de confort.
    </p>
    <p>
    De acuerdo a la Política Energética de largo plazo, se espera que para 2050 el 100% de las edificaciones nuevas cumple con el estándar de 
    sustentabilidad y de confort y se alcanza una meta (establecida en un plan de reacondicionamiento) para edificaciones existentes.
    </p>

    <p>
    La trayectoria 1 considera que para el año 2040 las viviendas nuevas tiene un estándar tal que demanda energética en calefacción inferior a 50 kWh/m2 al año. 
    </p>

    <p>
    La trayectoria 2 considera que para el año 2040 se alcanzaran los mejores estándares internacionales para las viviendas nuevas, el cual corresponde al modelo alemán denominado Passivhaus. 
    Las viviendas cuentan con clima interior confortable, máxima calidad de aire interior, buen aprovechamiento de la energía solar en climatización y 
    gran aislación térmica. Para cumplir este estándar la vivienda debe tener una demanda energética en calefacción inferior a 15 kWh/m2 al año. 
    </p>

  </HelpContent>
);

export default ReglamentacionTermicaViviendasHelp;
