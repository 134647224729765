export const SCENARIOS_FILE_PATH = './scenarios.json';

export const chartSettings = [
  {
    id: 'emisiones_netas',
    label: 'Emisiones netas de GEI',
    name: 'emisiones Netas de GEI',
    unit: 'Millón ton. de CO₂e',
    stacked: true,
    maxValue: 160,
    minValue: -80,
    stepSize: 40,
    chartType: 'bar',
    multipleEnabled: true,
    description: `Corresponde a la estimación de Gases de Efecto Invernadero de los sectores considerados
    en el Inventario Nacional de Gases de Efecto Invernadero de Chile (dióxido de carbono -
    CO2, metano - CH4, óxido nitroso -N2O, hidrofluorocarbonos – HFC, perfluorocarbonos –
    PFC y hexafluoruro de azufre - SF6), expresado en unidades de toneladas de CO2eq (CO2
    equivalente). Se añade reduccion de emisiones por accion forestal`,
  },
  {
    id: 'emisiones_gei',
    label: 'Emisiones de GEI',
    name: 'emisiones de GEI',
    unit: 'Millón ton. de CO₂e',
    stacked: true,
    maxValue: 160,
    minValue: 0,
    stepSize: 40,
    multipleEnabled: true,
    description: `Corresponde a la estimación de Gases de Efecto Invernadero de los sectores considerados
    en el Inventario Nacional de Gases de Efecto Invernadero de Chile (dióxido de carbono -
    CO2, metano - CH4, óxido nitroso -N2O, hidrofluorocarbonos – HFC, perfluorocarbonos –
    PFC y hexafluoruro de azufre - SF6), expresado en unidades de toneladas de CO2eq (CO2
    equivalente).`,
  },
  {
    id: 'intensidad_emisiones',
    label: 'Intensidad Emisiones',
    name: 'intensidad de Emisiones',
    unit: 'ton. CO₂e / Millones CLP',
    maxValue: 1,
    stepSize: 0.2,
    multipleEnabled: true,
    description: `Corresponde al cociente de las emisiones de GEI (en toneladas de CO2eq) y el PIB real de
    Chile (en millones de pesos constantes del año 2011) para cada año del horizonte 2017 -
    2050.`,
  },
  //{
  //  id: 'emisiones_pm2',
  //  label: 'Emisiones PM2.5, PM10, SOx y Nox',
  //  stacked: true,
  //  maxValue: 20,
  //  stepSize: 5,
  //  multipleEnabled: true,
  //  description: `Corresponde a la estimación de emisiones locales (en toneladas) para los contaminantes
  //  material particulado (de menos de 2,5 y 10 micrones), óxidos de azufre y óxidos nitrosos.`,
  //},
  {
    id: 'demanda_energia',
    label: 'Demanda Energía',
    name: 'demanda de energía por sector',
    unit: 'TCal',
    stacked: true,
    maxValue: 600000,
    stepSize: 100000,
    multipleEnabled: true,
    description: `Cuantifica el consumo de energía (en Teracalorías) de los sectores productivos de la
    demanda energética nacional de acuerdo a las categorías del Balance Nacional de Energía
    (BNE)`,
  },
  {
    id: 'demanda_electrica',
    label: 'Demanda Eléctrica',
    name: 'demanda eléctrica por sector',
    unit: 'GWh',
    stacked: true,
    maxValue: 190000,
    minValue: 0,
    stepSize: 20000,
    multipleEnabled: true,
    description: `Proyección de la demanda eléctrica por sector. No inclye las pérdidas eléctricas del sistema de distribución
    y del sistema de transimisión`,
  },
  //{
  // id: 'demanda_electrica',
  //  label: 'Demanda Eléctrica',
  //  unit: 'TCal',
  //  stacked: true,
  //  maxValue: 600000,
  //  stepSize: 100000,
  //  multipleEnabled: true,
  //  description: `Cuantifica la demanda eléctrica de los sectores productivos de la
  //  demanda energética nacional de acuerdo a las categorías del Balance Nacional de Energía
  //  (BNE)`,
  //},
  {
    id: 'generacion',
    label: 'Generación eléctrica',
    name: 'la matriz de generación eléctrica',
    unit: 'GWh',
    stacked: true,
    maxValue: 200000,
    stepSize: 20000,
    customColors: [
      'rgba(97, 138, 204, 1)',
      'rgba(211, 141, 10, 1)',
      'rgba(0, 0, 0, 1)',
      'rgba(247, 226, 108, 1)',
      'rgba(8, 165, 16, 1)',
      'rgba(149, 152, 153, 1)',
      'rgba(198, 17, 7, 1)',
      'rgba(142, 33, 2, 1)',
      'rgba(54, 179, 237, 1)',
      'rgba(5, 69, 99, 1)',
      'rgba(59, 186, 167, 1)',
      'rgba(239, 232, 35, 1)',
      'rgba(244, 201, 100, 1)',
    ],
    multipleEnabled: true,
    description: `Corresponde a la producción bruta anual de electricidad (en GWh) agrupada por tipo de
    tecnología de generación eléctrica, incluido el almacenamiento.`,
  },
  //{
  //  id: 'pib',
  //  label: 'Producto Interno Bruto',
  //  unit: 'Miles de millones de CLP de 2013 (real)',
  //  maxValue: 410000,
  //  multipleEnabled: true,
  //  description: `Es el valor total de los bienes y servicios producidos en el país en un periodo determinado (en millones de pesos del año 2013).`,
  //},
  //{
  //  id: 'empleo',
  //  label: 'Empleo',
  //  stacked: false,
  //  minValue: 1,
  //  maxValue: 1.6,
  //  customColors: [
  //    '#1c447c',
  //    '#f20c45',
  //  ],
  //  description: `Variación del nivel de ocupación (empleo) a nivel nacional (en porcentaje) categorizado por genero, urbano/rural y nivel de calificación.`,
  //},
  //{
  //  id: 'ingresos_quintiles',
  //  label: 'Ingresos por quintiles',
  //  stacked: false,
  //  minValue: 0.99,
  //  maxValue: 1.1,
  //  description: `Corresponde a la variación del ingreso promedio de los hogares de cada quintil de ingresos a nivel nacional (en porcentaje).`,
  //},
  // {
  //   id: 'impacto_inversiones',
  //   label: 'Impacto en inversiones',
  //   unit: 'GWh',
  //   stacked: true,
  // },
  //{
  //  id: 'precios_energia',
  //  label: 'Precios de la electricidad',
  //  stacked: false,
  //  maxValue: 5000,
  //  unit: 'USD/ton.',
  //  unit2: 'USD/MWh.',
  //  description: `Es el valor del Precio Medio de Mercado (en USD/MWh) del sistema eléctrico nacional.
  //  Corresponde a la ponderación de las proyecciones de costos nivelados de cada tecnología
  //  de generación presente en el sistema eléctrico según su participación en la matriz de
  //  generación.`,
  //},
  // {
  //   id: 'gastos_ingresos',
  //   label: 'Gastos, ingresos, transferencias del gobierno',
  //   stacked: true,
  //   maxValue: 200,
  // },

  // Emisiones netas, incluye reduccion de emisiones por accion forestal

  {
    id: 'potencia_instalada',
    label: 'Potencia instalada generación eléctrica',
    name: 'la potencia instalada del sector generación eléctrica',
    unit: 'MW',
    stacked: true,
    maxValue: 70000,
    minValue: 0,
    stepSize: 10000,
    multipleEnabled: true,
    description: `Proyección de la potencia instalada por tipo de tecnología`
  },
  {
    id: 'factor_emision_red_electrica',
    label: 'Factor de emisión red eléctrica',
    name: 'factor de emisión de la red eléctrica',
    unit: 'tCO₂e/MWh',
    stacked: false,
    maxValue: 0.8,
    minValue: 0,
    stepSize: 0.2,
    multipleEnabled: true,
    description: `Factor de emisión en tCO2e/MWh del Sistema Eléctrico Nacional.
    Este factor se utiliza para calcular la huella de CO2 asociado al consumo
    eléctrico (Alcance 2) de los hogares, comercio, industria, transporte, etc.`,
  }
];

export const trayectoriasSPET = [
  {
    value: 1,
    series: [
      {
        label: 'Límite industria y minería',
        serie: [16.3, 16.4, 17.0, 17.4, 17.7, 18.1, 18.5, 18.8, 19.0, 18.9, 18.9, 18.8, 18.8, 18.9, 18.8, 18.7, 18.6, 18.4, 18.3, 18.1, 18.0, 17.8, 17.6, 17.4, 17.2, 16.9, 16.7, 16.4, 16.1, 15.7, 15.2, 14.7, 14.3, 13.8],
      },
      {
        label: 'Límite generación eléctrica',
        serie: [33.1, 33.9, 35.5, 38.8, 32.5, 36.5, 39.4, 37.7, 35.5, 33.9, 33.4, 33.6, 33.1, 32.7, 31.9, 31.3, 30.3, 29.5, 28.9, 27.8, 27.4, 26.4, 25.8, 24.5, 24.2, 23.4, 22.5, 21.8, 21.1, 19.4, 17.9, 16.4, 15.4, 14.5],
      },
      {
        label: 'Límite Total',
        serie: [
          49.4, 50.3, 52.4, 56.2, 50.2, 54.7, 57.9, 56.5, 54.5, 52.8, 52.3, 52.3, 51.9, 51.6, 50.7, 50.0, 48.9, 47.9, 47.2, 45.9, 45.4, 44.1, 43.4, 41.9, 41.4, 40.3, 39.1, 38.2, 37.2, 35.1, 33.1, 31.1, 29.7, 28.3
        ],
      }
    ],
    unit: 'MMtCO₂e',
  },
  {
    value: 2,
    series: [
      {
        label: 'Límite industria y minería',
        serie: [
          16.3, 16.4, 17.0, 17.4, 17.7, 18.1, 18.5, 18.8, 19.0, 18.3, 17.8, 17.1, 16.6, 16.0, 15.9, 15.7, 15.6, 15.4, 15.2, 15.1, 14.9, 14.6, 14.4, 14.1, 13.9, 13.7, 13.4, 13.1, 12.7, 12.2, 11.7, 11.1, 10.6, 10.1
        ],
      },
      {
        label: 'Límite generación eléctrica',
        serie: [33.1, 33.9, 35.5, 38.8, 32.5, 36.5, 39.4, 37.7, 35.5, 33.0, 31.5, 30.6, 29.1, 27.7, 27.0, 26.4, 25.5, 24.7, 24.1, 23.1, 22.7, 21.7, 21.1, 20.0, 19.6, 18.9, 18.0, 17.4, 16.7, 15.1, 13.7, 12.3, 11.5, 10.5],
      },
      {
        label: 'Límite Total',
        serie: [49.4, 50.3, 52.5, 56.2, 50.2, 54.6, 57.9, 56.5, 54.5, 51.3, 49.3, 47.7, 45.7, 43.7, 42.9, 42.1, 41.1, 40.1, 39.3, 38.2, 37.6, 36.3, 35.5, 34.1, 33.5, 32.6, 31.4, 30.5, 29.4, 27.3, 25.4, 23.4, 22.1, 20.6],
      }
    ],
    unit: 'MMtCO₂e',
  },
  {
    value: 3,
    series: [],
    unit: 'MMtCO₂e',
  },
];

export const trayectoriasRetiroCarbon = [
  {
    value: 1,
    series: [4767, 4767, 4627, 4500, 4500, 4394, 4288, 3871, 3871, 3611, 3337, 3219, 3081, 3081, 3081, 2945, 2563, 2185, 1800, 1150, 326, 326, 326, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    unit: 'Potencia (MW)',
  },
  {
    value: 2,
    series: [
      4767, 4767, 4767, 4767, 4662, 4555, 4352, 4283, 4212, 3999, 3738, 3465, 3347, 3209, 3081, 3081, 2945, 2563, 2185, 1800, 1150, 326, 326, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
    ],
    unit: 'Potencia (MW)',
  },
];

export const trayectoriasElectromovilidad = [
  {
    value: 1,
    series: [
      [0, 0, 0, 0, 0, 0, 0, 0, 2.6, 5, 7.3, 9.5, 11.6, 13.6, 15.5, 17.3, 19, 20.7, 22.4, 23.9, 25.5, 27, 28.4, 29.7, 31.1, 32.4, 33.6, 34.9, 36, 37.2, 38.4, 39.5, 40.5, 41.5],
      [0, 1.5, 1.5, 1.5, 1.5, 13.8, 13.8, 13.8, 13.8, 26.2, 26.2, 26.2, 26.2, 38.5, 38.5, 38.5, 38.5, 50.8, 50.8, 50.8, 50.8, 63.1, 63.1, 63.1, 63.1, 75.4, 75.4, 75.4, 75.4, 87.7, 87.7, 87.7, 87.7, 100],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2.8, 5.4, 7.8, 10.2, 12.5, 14.7, 16.8, 18.9, 20.8, 22.7, 24.5, 26.3, 28, 29.6, 31.2, 32.7, 34.3, 35.7, 37.1, 38.4, 39.8],
      [0, 0, 0, 0, 0, 0, 0, 0, 4, 8, 12, 15, 19, 23, 27, 31, 35, 39, 42, 46, 50, 54, 58, 62, 65, 69, 73, 77, 81, 85, 89, 92, 96, 100],
      [0, 0, 0, 0, 0, 0, 0, 0, 2, 3, 7, 13, 26, 28, 29, 31, 33, 35, 38, 40, 43, 45, 48, 52, 56, 60, 64, 69, 73, 79, 84, 90, 96, 100],
      [0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 3, 4, 6, 8, 10, 14, 19, 25, 27, 31, 35, 40, 46, 52, 56, 61, 65, 70, 75, 81, 87, 93, 100, 100],
    ],
    unit: '',
  },
  {
    value: 2,
    series: [],
    unit: '',
  },
];

export const trayectoriasPib = [
  {
    value: 1,
    series:[1.5, 4.0, 3.0, 2.9, 2.9, 2.8, 2.7, 2.7, 2.6, 2.6, 2.5, 2.5, 2.4, 2.3, 2.3, 2.2, 2.2, 2.1, 2.1, 2.1, 2.0, 2.0, 1.9, 1.9, 1.8, 1.8, 1.8, 1.7, 1.7, 1.6, 1.6, 1.6, 1.5, 1.5],
    unit: '%',
  },
  {
    value: 2,
    series: [1.5, 4.0, 1.1,-5.75, 11.70,2.55,-0.20,1.75,2.50,2.40,2.30,2.20,2.13,2.05,1.98,1.90,1.83,1.76,1.68,1.61,1.53,1.46,1.39,1.31,1.24,1.17,1.09,1.02,0.94,0.87,0.80,0.72,0.65,0.57
    ],
    unit: '%',
  },
  {
    value: 3,
    series: [1.5, 4.0, 3.5, 3.5, 3.4, 3.4, 3.4, 3.3, 3.3, 3.2, 3.2, 3.2, 3.1, 3.1, 3.1, 3.0, 3.0, 3.0, 2.9, 2.9, 2.9, 2.8, 2.8, 2.8, 2.8, 2.7, 2.7, 2.7, 2.6, 2.6, 2.6, 2.6, 2.5, 2.5,],
    unit: '%',
  },
];

export const trayectoriasPrecioCombustible = [
  {
    value: 1,
    series: [],
    unit: '',
  },
  {
    value: 2,
    series: [
      [ 657.1, 657.1, 657.1, 692, 718.1, 738.3, 750.6, 761.9, 781.6, 799.9, 811.2, 816.5, 827.5, 846.7, 864.7, 886, 887, 901.1, 906.6, 928.9, 933, 942.1, 955.6, 963.2, 968.6, 970.1, 974.8, 979.1, 984, 991.5, 1000.4, 1002.2, 1013.6, 1021.6],
      [ 1428.417505, 1428.417505, 1428.417505, 1504.263656, 1561.102206, 1604.877168, 1631.634463, 1656.166462, 1699.090704, 1738.708695, 1763.344937, 1774.945399, 1798.749408, 1840.613046, 1879.696307, 1926.003388, 1928.075815, 1958.889877, 1970.761898, 2019.300644, 2028.232741, 2047.931202, 2077.183487, 2093.896193, 2105.435134, 2108.812693, 2119.022423, 2128.270044, 2139.079131, 2155.354825, 2174.565163, 2178.670867, 2203.354492, 2220.75433],
      [ 2185.117505, 2185.117505, 2185.117505, 2295.863656, 2378.802206, 2442.777168, 2481.834463, 2517.666462, 2580.290704, 2638.208695, 2674.144937, 2691.045399, 2725.849408, 2786.913046, 2843.996307, 2911.603388, 2914.675815, 2959.589877, 2976.961898, 3047.800644, 3060.832741, 3089.631202, 3132.383487, 3156.696193, 3173.635134, 3178.512693, 3193.422423, 3206.970044, 3222.679131, 3246.454825, 3274.565163, 3280.470867, 3316.554492, 3341.95433],
    ],
    unit: '',
  },
  {
    value: 3,
    series: [],
    unit: '',
  },
];