import React from 'react';
import HelpContent from './HelpContent';
import HidrogenoMaritimo from 'img/maritimo_hidrogeno.PNG';

const HidrogenoMaritimoHelp = () => (
  <HelpContent>
    <h1>Introducción de hidrógeno en transporte marítimo</h1>
    <p>
    Esta medida evalúa el impacto de la introducción de hidrógeno en el transporte marítimo de carga a nivel nacional. 
    La siguiente un prototipo de buque comercial que funcionaría con hidrógeno, el cual se encuentra
    en desarrollo por las empresas Moss Maritime, Equinor, Wilhelmsen y DNV-GL.
    </p>
	<img src={HidrogenoMaritimo} alt="Hidrógeno en transporte marítimo" />
    <label>Figura: Hidrógeno en transporte marítimo. Fuente: Wilhelmsen https://www.wilhelmsen.com/</label>
    <p>
      La trayectoría 1 considera que el 10% del transporte marítimo utilizará hidrógeno hacia el año 2050.
    </p>
    <p>
      La trayectoría 2 considera que el 20% del transporte marítimo utilizará hidrógeno hacia el año 2050.
    </p>
  </HelpContent>
);

export default HidrogenoMaritimoHelp;
