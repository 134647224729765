import styled from 'styled-components';
/*import MUISlider from '@material-ui/lab/Slider';*/
import MUISlider from '@material-ui/core/Slider';

/**
 * Wrapper para el componente Slider de material ui
 */
export default styled(MUISlider)`
  margin-top: 15px;
`;
