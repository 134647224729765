import React from 'react';
import SST_industria from 'img/SST_industria_mineria.png';
import HelpContent from './HelpContent';

/*
 * Ayuda para Introducción Energías Renovables 
 */

const IntroERHelp = () => (
  <HelpContent>
    <h1>Energía solar en procesos térmicos de la industria</h1>
    <p>
      Esta medida tiene como objetivo la reducción de emisiones de GEI aprovechando el uso de
      energías renovables no convencionales en procesos productivos. En particular, se considera
      el uso de Sistemas Solares Térmicos (SST) como fuente de calor en procesos térmicos de baja
      temperatura de la industria y minería.
    </p>
    <img src={SST_industria} alt="Sistema Solar Térmico" />
    <label>Figura: Sistema Solar Térmico</label>
  
    <p>
    Trayectoria 1: Los SST satisfacen el 13% de la demanda de usos térmicos en industrias varias, papel y celulosa, cemento, azúcar, siderurgia y minas varias al 2050. En minería de cobre alcanzan un 16%.
    </p>
    <p>
    Trayectoria 2: Los SST satisfacen el 30% de la demanda de usos térmicos en industrias varias, papel y celulosa, cemento, azúcar, siderurgia y minas varias al 2050. En minería de cobre alcanzan un 30%.
    </p>
  </HelpContent>
);

export default IntroERHelp;
