import React, { useState, useEffect} from 'react';
import ReactDOM from 'react-dom'
import styled from 'styled-components';
import domToImage from 'dom-to-image';
import { saveAs } from 'file-saver';
import { string, bool } from 'prop-types';
import axios from 'axios';
import ChartJs from 'chart.js';
import Color from 'color';
import Loader from 'components/ui/Loader';
import Message from 'components/ui/Message';
import Slider from '@material-ui/core/Slider';
import KPI from './KPI';
import EmisionesChart from './EmisionesChart'
import csvtojson from 'csvtojson/v2';
import XLSX from 'xlsx'
import {chartSettings as charts} from 'config'
import {
  MIN_YEAR,
  MAX_YEAR,
  YEAR_CATEGORY,
  ESCENARIO_BASE,
  ESCENARIO_BASE_LOWER,
  BASE,
  ELECTRICIDAD,
  COLORS,
  NATURAL_SOURCES,
} from 'utils/constants';
import stackFill from 'utils/plugin.stackFill'; 
import { parseValue, categoryLabel, truncTwoDecimals, toTwoDecimals, numberToTwoDecimals} from 'utils/helpers';
import Select from 'components/ui/Select';
import PdfGenerator from 'utils/generate-pdf/pdfGenerator';
import {pdf} from '@react-pdf/renderer';

/**
 * Cargar los datos del gráfico
 */
const getData = (datasetPath) => (
  axios.get(datasetPath)
  .then((response) => (
    csvtojson({ignoreEmpty:true})
    // TODO: dynamic path depending on settings

    // El problema es que axios lee con codificacion utf-8 y el archivo csv de entrada tiene una ñ en año
    // para leerlo deberia setear a axios con algo como "codificacion: latin-1" pero siguiendo las guias encontradas nada funciono, por eso se dejo
    // esta solucion temporal
    .fromString(response.data.replace("a�o", YEAR_CATEGORY))
  ))
);

/**
 *  Obtenener el color de fondo de usa serie
 *  basado en el color de la serie
 */
const getBg = (code) => {
  if (code === 'rgba(0, 0, 0, 1)') {
    return 'rgba(0, 0, 0, 0.5)'
  }
  const color = Color(code);
  return color.alpha(0.4).rgb().string();
}

/**
 * Determinar si la serie actual corresponde al escenario base
 */
const isBase = (scenario) => {
  const scenarioName = scenario.toLowerCase();
  return scenarioName === ESCENARIO_BASE_LOWER || scenarioName === BASE;
}

/**
 * Determinar la altura del canvas para el gráfico
 */
const getCanvasHeight = (categories, mode) => {
  const offset = mode === 'emisiones_gei' || mode === 'emisiones_pm2' ? 100 : 0

  const W = window.innerWidth;
  if (W < 720) {
    return offset + 400 + 15 * categories.length;
  } else if (W < 1200) {
    return 200 + 10 * categories.length;
  } else {
    return 150;
  }
}

const getCanvasHeightPDF = (categories, mode) => {
  return 300 + 10 * categories.length;
}

/**
 * Initializa el gráfico, dados los datos y los parámetros de configuración del escenario.
 */
let chart = null
const initChart = (
  minYear,
  maxYear,
  datasetPath,
  chartSettings,
  setError,
  setLoading,
  setChart,
  scenarioName,
  id,
  isMultiscenario,
  pdf,
  // container,
) => {
  let elem = document.getElementById(id);
  if (pdf) {
      elem.innerHTML = '<h4>Cargando gráfico...</h4>';
  } else {
    if(elem){
      elem.innerHTML = '<h4>Cargando gráfico...</h4>'
    }
    else{
      return null;
    }
    setLoading(true);
  }
  
  getData(datasetPath).then(dataset => {
    // get list of categories
    let cats = Object.keys(dataset[0]).filter(cat => cat !== YEAR_CATEGORY);
    if(chartSettings.id === "emisiones_netas" && !isMultiscenario)
      cats.push("Emisiones Netas")
      if(chartSettings.id === "demanda_electrica" || chartSettings.id === "demanda_energia" || chartSettings.id === "generacion")
      cats.push("Total")
    const categories = cats;
    
    let years = [];
    for (let year = minYear; year <= maxYear; year++) {
      years.push(year);
    }

    const canvasId = Math.random();
    if(pdf){
      elem.innerHTML =  `<canvas id="${canvasId}" height="${getCanvasHeightPDF(categories, chartSettings.id)}" width="${800}" ></canvas>`;
    }
    else{
      elem.innerHTML = `<canvas id="${canvasId}" height="${getCanvasHeight(categories, chartSettings.id)}"></canvas>`;
      setError(false);
    }
    

    // Y AXES
    const yAxes = [
      {
        id: 'non-stacked',
        stacked: false,
        scaleLabel: {
          display: true,
          labelString: chartSettings.unit || '',
        },
        gridLines: {
          color: '#eeeeee',
        },
      },
      {
        id: 'stacked',
        display: false,
        stacked: chartSettings.stacked,
        gridLines: {
          color: '#eeeeee',
        },
      },
    ];
  
    const ticks = {
      suggestedMin: chartSettings.minValue || 0,
      suggestedMax: chartSettings.maxValue,
      stepSize: chartSettings.stepSize,
    };
    yAxes[0].ticks = ticks;
    yAxes[1].ticks = ticks;
    
    const electricidad = categories.filter(cat => cat === ELECTRICIDAD);
    if (electricidad.length) {
      yAxes.push({
        id: 'elec-axis',
        display: true,
        stacked: false,
        position: 'right',
        scaleLabel: {
          display: true,
          labelString: 'USD/MWh',
        },
        ticks: {
          min: isBase(scenarioName) ? 99 : 190,
          max: isBase(scenarioName) ? 100.1 : 215,
        },
      })
    }

    // Allow only data in the date range
    let filteredDataset = {}
    if((chartSettings.id === "emisiones_netas" || chartSettings.id === "demanda_electrica" || chartSettings.id === "demanda_energia" || chartSettings.id === "generacion") && !isMultiscenario){
      filteredDataset = dataset.filter(item => {
        const year = parseInt(item[YEAR_CATEGORY]);
        return year >= minYear && year <= maxYear;
      })
      .map( item => {
        let sum = 0
        categories.forEach(category => {
          if(!isBase(category)){
            sum += truncTwoDecimals(parseValue(item[category]))
          }
        });
        if(chartSettings.id === "emisiones_netas" && !isMultiscenario)
          item["Emisiones Netas"] = (sum.toFixed(2)).toString()
        if((chartSettings.id === "demanda_electrica" || chartSettings.id === "demanda_energia" || chartSettings.id === "generacion") && !isMultiscenario){
          item["Total"] = (sum.toFixed(2)).toString()
        }
        return item
      }).map(item => {
        categories.forEach(category => {
          item[category] = (parseValue(item[category]).toFixed(2)).toString()
        })
        return item
      });
    }
    else{
      filteredDataset = dataset.filter(item => {
        const year = parseInt(item[YEAR_CATEGORY]);
        return year >= minYear && year <= maxYear;
      }).map(value => toTwoDecimals(value));
    }

    const ctx = elem.getElementsByTagName("canvas")[0] ? elem.getElementsByTagName("canvas")[0].getContext('2d') : null;
    if (ctx) {
      const colors = chartSettings.customColors || COLORS; 
      const defaultSettings = {
        type: 'line',
        borderWidth: 1,
        pointRadius: 1,
        pointHitRadius: 10,
        fill: false,
        stackFill: true,
      };
      const barSettings = {
        type: 'bar',
        borderWidth: 1,
        pointRadius: 0.5,
        pointHitRadius: 10,
        fill: false,
        stackFill: true,
      };
      chart = new ChartJs(ctx, {
        type: chartSettings.chartType && !isMultiscenario ? chartSettings.chartType : 'line',
        plugins: [stackFill],
        data: {
          datasets: [
            ...categories.filter(cat => (!isBase(cat) && cat !== "Emisiones Netas" && cat !== ELECTRICIDAD && cat !== "Total")).map((cat, i) => ({
              label : categoryLabel(cat),
              borderColor: colors[i],
              backgroundColor: chartSettings.stacked && !isMultiscenario ? getBg(colors[i], chartSettings.id) : 'transparent',
              data: filteredDataset.map(data => truncTwoDecimals(parseValue(data[cat]))),
              yAxisID: isMultiscenario ? 'non-stacked' : 'stacked',
              order: 2,
              ...chartSettings.chartType === 'bar' && !isMultiscenario ? barSettings : defaultSettings,
              
            })),
            ...categories.filter(cat => cat === "Emisiones Netas").map(cat => ({ //cambiar por emisiones netas
              label : categoryLabel(cat),
              borderColor: 'black',
              backgroundColor: 'transparent',
              order: 0,
              data: filteredDataset.map(data => parseValue(data[cat])),
              yAxisID: 'non-stacked',
              ...defaultSettings,
              borderWidth: 2,
            })),
            ...categories.filter(cat => isBase(cat) && (chartSettings.id !== "emisiones_netas" || isMultiscenario) ).map(cat => ({
              label : categoryLabel(cat),
              borderColor: 'black',
              backgroundColor: 'transparent',
              order: 0,
              data: filteredDataset.map(data => parseValue(data[cat])),
              yAxisID: 'non-stacked',
              ...defaultSettings,
              borderWidth: 1,
            })),
            ...categories.filter(cat => cat === ELECTRICIDAD).map(cat => ({
              label : categoryLabel(cat),
              borderColor: colors[2],
              backgroundColor: 'transparent',
              data: filteredDataset.map(data => parseValue(data[cat])),
              yAxisID: 'elec-axis',
              ...defaultSettings,
            })),
            ...categories.filter(cat => cat === "Total").map(cat => ({
              label : categoryLabel(cat),
              borderColor: 'rgba(240, 240, 240, 1)',
              backgroundColor: 'transparent',
              order: 0,
              data: filteredDataset.map(data => parseValue(data[cat])),
              yAxisID: 'non-stacked',
              ...defaultSettings,
              borderWidth: 2,
            })),
          ],
          labels: years.map(y => String(y)),
        },
        options:{
          animation: {
            duration: pdf ? 0 : 400,
            easing: 'linear',
            onComplete: function(){
              if(pdf){
                setChart(this.chart)
              }
              else{
                setChart(null)
                setChart(this.chart)
                setLoading(false);
              }
              
            }
          },
          responsive: pdf ? false: true,
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              boxWidth: 15,
              padding: 20,
            },
            onClick: pdf ? () => {} :  function(e, legendItem){
              var index = legendItem.datasetIndex;
              var ci = this.chart;
              if (ci.getDatasetMeta(index).hidden === null){
                ci.getDatasetMeta(index).hidden = false
              }
              ci.getDatasetMeta(index).hidden = !ci.getDatasetMeta(index).hidden
              
              // resta el valor de el indicador escondido al total
              if(legendItem.text !== "Total"){
                ci.data.datasets.forEach(item => {
                if (item["label"] === "Total"){
                  var total_acumulado = item["data"]
                  var hidden_value = ci.data.datasets[index]["data"]
                  item["data"] = total_acumulado.map((num, idx) => (
                    ci.getDatasetMeta(index).hidden === true ?
                      numberToTwoDecimals(Number(num) - hidden_value[idx])
                    : numberToTwoDecimals(Number(num) + hidden_value[idx])
                  ))
                }})}
              var notHiddenData = ci.data.datasets.filter( (item, i)=> {
                var meta = ci.getDatasetMeta(i)
                return !meta.hidden && !isBase(item["label"]) && item["label"] !== "Emisiones Netas" 
              }).map(item => item["data"]).map(values => values.map(value => truncTwoDecimals(value)))
              let min_value = 0
              if(notHiddenData.length > 0){
                let aux = new Array(notHiddenData[0].length).fill(0);
                notHiddenData.forEach(element => {
                  for (let index = 0; index < element.length; index++) {
                    min_value = Math.max(Math.min(min_value, element[index]), chartSettings.minValue)
                    aux[index] += truncTwoDecimals(element[index])
                  }
                });
                if(ci.data.datasets.filter(item => item.label === "Emisiones Netas").length > 0){
                  const ticks = {
                    suggestedMin: min_value || 0,
                    suggestedMax: chartSettings.maxValue,
                    stepSize: chartSettings.stepSize,
                  };
                  ci.options.scales.yAxes[0].ticks = ticks;
                  ci.options.scales.yAxes[1].ticks = ticks;
                  let index = ci.data.datasets.findIndex( item => item.label === "Emisiones Netas")
                  ci.data.datasets[index]["data"] = aux.map(value => truncTwoDecimals(value).toFixed(2))
                }
                
              }
              ci.update();
            }
          },
          scales: {
            xAxes: [{
              scaleLabel: {
                labelString: 'Año'
              },
              gridLines: {
                color: '#f5f5f5',

              },
              stacked: true,
              ticks: {
                callback: (value) => {
                  if (value === MIN_YEAR
                    || value % 5 === 0) {
                    return value;
                  }
                  return '';
                },
                minRotation: 0,
                maxRotation: 0,
              },
            }],
            yAxes
          },
        },
      });
    }

  }).catch(error => {
    console.error(error);
    if(!pdf)
      setError('Error cargando set de datos')
  });
};

const Wrapper = styled.div`
  background-color: white;
  border-radius: 3px;
  position: relative;
  padding: 20px; 

  @media(max-width:720px) {
    padding: 0;
  }
`;

const ChartContainer = styled.div`
  width: 100%;  
  font-size: 11px;
  margin-bottom: 40px;
  padding-top: 40px;

  @media(min-width:720px) {
    height: 550px;
    padding-top: 0;
    margin-bottom: 20px;
  }

  canvas {
    padding-bottom: 10px;
  }

  h4 {
    width: 100%;
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;
    color: #999;
    font-weight: normal;
  }
`;

const ChartOptions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  padding-right: 20px;

  a, span {
    color: #666;
    font-size: 12px;
    display: inline-block;
    margin-left: 10px;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const SliderWrap = styled.div`
  padding: 0 20px 20px 40px;
`;

const SelectContainer = styled.div`
  padding: 0 60px 20px 20px;
  display: flex;
`;


const downloadAsImage = (name, id) => {
  const chartNode = document.getElementById(id);
  if (chartNode) {
    domToImage.toBlob(chartNode)
      .then(function (blob) {
          saveAs(blob, name + '.png');
      });
  }
}

const descargarXLSX = (path) => {
  
  let labels = chart.data.labels
  let chart_data = chart.data.datasets.filter( (_, i) => chart.getDatasetMeta(i).hidden !== true)
  let titles = chart_data.map(item => item.label)
  titles.unshift("año")
  let xlsx_data = []
  xlsx_data.push(titles)
  labels.forEach((anno, index) => {
    let data_anno = chart_data.map(item => item.data[index])
    data_anno.unshift(anno) 
    xlsx_data.push(data_anno)
  });

  let name = path.split("/")

  var wb = XLSX.utils.book_new();
  wb.SheetNames.push("Test Sheet");
  var ws = XLSX.utils.aoa_to_sheet(xlsx_data);
  wb.Sheets["Test Sheet"] = ws;
  
  var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
  
  let s2ab = (s) => { 
    var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    var view = new Uint8Array(buf);  //create uint8array as viewer
    for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;    
  } 
  saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), name[name.length - 1].split(".")[0] + '.xlsx');
}

const loadKpi = (year, datasetPath, chartSettings, setKpiAmounts, setKpiDict, kpiDict) => {
  getData(datasetPath).then(dataset => {
    const data = JSON.parse(JSON.stringify(dataset.find(item => parseInt(item[YEAR_CATEGORY]) === year))); //copia del dataset
    const data2030 = dataset.find(item => parseInt(item[YEAR_CATEGORY]) === 2030);
    const baseYear = data[ESCENARIO_BASE] || data[ESCENARIO_BASE_LOWER] || data[BASE]; 

    // esto con data2030 son cosas bien especificas porque se refieren al indicador carbon budget (dado en una fecha especifica 2020-2030 en este caso)
    let sumOthersBetween2020_2030 = 0;
    dataset.forEach(item => {
      if(item[YEAR_CATEGORY] >= 2020 && item[YEAR_CATEGORY] <= 2030) {
        let tempSumOthers = Object.keys(item)
        .filter(key => !isBase(key))
        .reduce((acc, key) => {
          return truncTwoDecimals(acc) + truncTwoDecimals(parseValue(item[key]));
        }, 0) - item[YEAR_CATEGORY];
        sumOthersBetween2020_2030 += tempSumOthers;
      };
    });

    delete data[YEAR_CATEGORY];

    let base = parseValue(baseYear);
    let sumOthers = Object.keys(data)
      .filter(key => !isBase(key))
      .reduce((acc, key) => {
          return acc + parseValue(data[key]);
        }, 0
      );
    
    base = base ? Number(parseFloat(base).toFixed(2)) : 0;
    sumOthers = sumOthers ? Number(sumOthers.toFixed(2)) : 0;

    if (chartSettings.id === 'generacion') {
      const total = Object.keys(data).reduce((acc, key) => (
        parseValue(data[key]) + acc
      ), 0);
      const renewables = Object.keys(data).filter(key => (
        NATURAL_SOURCES.indexOf(key) !== -1
      )).reduce((acc, key) => (
        parseValue(data[key]) + acc
      ), 0);
      const carbon = parseValue(data.carbon);
      const renewablesPerc = (renewables/total*100).toFixed(1);
      const carbonPerc = (carbon/total*100).toFixed(1);
      
      setKpiAmounts([renewablesPerc, carbonPerc]);
      
    }
    else if(chartSettings.id === 'emisiones_gei') {
      //console.log(year);
      const baseYear2030 = data2030[ESCENARIO_BASE] || data2030[ESCENARIO_BASE_LOWER] || data2030[BASE]; 
      delete data2030[YEAR_CATEGORY];
  
      let sumOthers2030 = Object.keys(data2030)
        .filter(key => !isBase(key))
        .reduce((acc, key) => {
            return acc + parseValue(data2030[key]);
          }, 0
        );
  
      sumOthers2030 = sumOthers2030 ? Number(sumOthers2030.toFixed(1)) : 0;

      setKpiDict({
        ...kpiDict,
        "tco2_2030": sumOthers2030,
        "tco2_entre_2020_2030": sumOthersBetween2020_2030
      });
      setKpiAmounts([base, sumOthers]);
    }

    else if(chartSettings.id === 'potencia_instalada') {

      let potencia_instalada_solar_fv = Object.keys(data)
      .filter(key => key === "solar fv")
      .reduce((acc, key) => {
          return acc + parseValue(data[key]);
        }, 0
      );

      let potencia_instalada_solar_csp = Object.keys(data)
      .filter(key => key === "solar csp")
      .reduce((acc, key) => {
          return acc + parseValue(data[key]);
        }, 0
      );

      let potencia_instalada_almacenamiento = Object.keys(data)
      .filter(key => key === "almacenamiento")
      .reduce((acc, key) => {
          return acc + parseValue(data[key]);
        }, 0
      );

      let potencia_instalada_eolica = Object.keys(data)
      .filter(key => key === "eolica")
      .reduce((acc, key) => {
          return acc + parseValue(data[key]);
        }, 0
      );

      setKpiDict({
        ...kpiDict,
        "potencia_instalada_solar_fv": potencia_instalada_solar_fv,
        "potencia_instalada_solar_csp": potencia_instalada_solar_csp,
        "potencia_instalada_eolica": potencia_instalada_eolica,
        "potencia_instalada_almacenamiento": potencia_instalada_almacenamiento,
      });
      setKpiAmounts([base, sumOthers]);
    }
    else {
      setKpiAmounts([base, sumOthers]);
      // si se quiere mostrar mas de 1 kpi, agregarlo en el diccionario kpiDict y luego usarlo en el componente KPI
      /* Por ejemplo agregar el nuevo kpi KPI-A con un valor de 200 y KPI-B con un valor de 100
      setKpiDict({
        ...kpiDict,
        "KPI-A": 200,
        "KPI-B": 100
        ...
      })

      Todo esto bajo un if(condicion para agregar otro kpi), recordar manejar estos datos en KPI e iniciar estos valores en 0 al inicio de loadKpi
      Se eligio un diccionario para no manejar indices arbitrarios, asi cuando se quieran llamar en el componente KPI
      simplemente se llaman de la forma kpiDict["Nombre del KPI"].
      */
    }
  })
} 

const emisionesKpi = (year, datasetPath, setEmisiones) => {
  getData(datasetPath).then(dataset => {
    const data = dataset.find(item => parseInt(item[YEAR_CATEGORY]) === year);
    const baseYear = data[ESCENARIO_BASE] || data[ESCENARIO_BASE_LOWER] || data[BASE]; 
    delete data[YEAR_CATEGORY];

    let base = parseValue(baseYear);
    let sumOthers = Object.keys(data)
      .filter(key => !isBase(key))
      .reduce((acc, key) => {
          return acc + parseValue(data[key]);
        }, 0
      );
    setEmisiones([base, sumOthers])
  })
}


/**
 * Gráfico principal.
 * Renderiza el gráfico correspondiente al indicador y a el escenario seleccionado
 * @param {string} id - Id for the main dom element
 * @param {string} mode - Indicador seleccionado
 * @param {string} currentScenario - Escenario seleccionado
 * @param {string} datasetPath - Ruta del set de datos correspondiente
 * @param {object} chartSettings - Opciones de configuración para este gráfico  
 * @param {bool} showKPI
 * @param {bool} allowDownloads
 */
const Chart = ({
  id,
  mode,
  currentScenario,
  datasetPath,
  chartSettings,
  showKPI,
  allowDownloads,
  isMultiscenario,
  settings
}) => {
  const [error, setError] = useState(0);
  const [loading, setLoading] = useState(false);

  const [kpiAmounts, setKpiAmounts] = useState([0, 0]);
  const [kpiDict, setKpiDict] = useState({
    "tco2_2030": 0,
    "tco2_entre_2020_2030": 0,
    "potencia_instalada_solar_fv": 0,
    "potencia_instalada_solar_csp": 0,
    "potencia_instalada_eolica": 0,
    "potencia_instalada_almacenamiento": 0,
  });
  const [kpiYear, setKpiYear] = useState(2030);
  const [imgData, setImgData] = useState(null);
  const [chart, setChart] = useState(null);
  const [emisiones, setEmisiones] = useState([0, 0])
  // initial final years control
  const [years, setYears] = useState([MIN_YEAR, MAX_YEAR]);
  const [loadingPdf, setLoadingPdf] = useState(false);
  let l = []
  for (var i = MIN_YEAR; i <= MAX_YEAR; i++) {
    l.push({ value: i, label: i});
  }
  const arrayYears = l;
  const sliderChange = (_, newValue) => {
    setYears(newValue);
  };


  // En este punto settings trae los insttrumentos, las politicas y el pib

  const generatePDFDocument = async (name) => {
    setLoadingPdf(true);
    // let charts2 = charts.filter(item => item.id !== "emisiones_netas");
    let charts2 = charts;
    let chartsData = new Array(charts2.length).fill(0);
    let kpis = new Array(charts2.length).fill(0);
    charts.forEach((item, index) => {
      let idc = Math.random();
      ReactDOM.render(React.createElement(ChartContainer, {id: idc}, null), document.getElementById("pdf-charts" + index));
      const chartCallback = (value) =>{
        chartsData[index] = truncTwoDecimals(value.canvas)
      } 
      const kpiCallback = (value) =>{
        kpis[index] = value;
      } 
      initChart(years[0], years[1], currentScenario.datasets[item.id], item, 
        null, 
        null,
        chartCallback,
        currentScenario.name, idc, false, true);
      
      loadKpi(kpiYear, currentScenario.datasets[item.id], item, kpiCallback, setKpiDict, kpiDict);
    })
    const descargar = () => {
      setTimeout(async function() {
        if(chartsData.includes(0) || kpis.includes(0)){
          descargar();
        }
        else{
          setTimeout(async function (){
            const blob = await pdf(
            <PdfGenerator
              key="pdf"
              graphImgs={chartsData}
              settings={charts}
              datasetPath= {datasetPath}
              kpis={kpis}
              year={kpiYear}
              escenario={currentScenario}
              />
            ).toBlob();
        
            saveAs(blob, name);
            setLoadingPdf(false);
        }, 20)
        }
      
      }, 1000);
    }
    descargar();
  };

  useEffect(() => {
    if (datasetPath) {
      if(showKPI){
        loadKpi(kpiYear, datasetPath, chartSettings, setKpiAmounts, setKpiDict, kpiDict);
        emisionesKpi(kpiYear, currentScenario.datasets.emisiones_gei, setEmisiones)
      }
      initChart(years[0], years[1], datasetPath, chartSettings, setError, setLoading, setChart, currentScenario.name, id, isMultiscenario);
    }
  }, [years, kpiYear, datasetPath, chartSettings, currentScenario, id, showKPI, isMultiscenario ]);
  useEffect(() => {
  }, [loadingPdf]);
  useEffect(() => {
    if(mode === 'emisiones_netas') {
      setKpiYear(2050); //caso de borde pedido para emisiones netas de gei -> 2050 por defecto
    }
    else {
      setKpiYear(2030);
    }
  }, [mode]);

  return (
    <Wrapper>
      {
        error ? (
          <Message error>
            { error }
          </Message>
        ) : datasetPath ? (
          <React.Fragment>
            {
              allowDownloads && (
                <ChartOptions>
                  <span onClick={() => downloadAsImage(mode, id)}>
                    Descargar gráfico
                  </span>

                  <span onClick={() => descargarXLSX(datasetPath)}>
                    Descargar datos
                  </span>

                 {loadingPdf ? 
                  <span>
                    Cargando PDF...
                  </span> :
                    <span onClick={() => generatePDFDocument(currentScenario.name)}>
                    Descargar PDF
                  </span>
                 }

                </ChartOptions>
              )
            }
            <SliderWrap>
              <Slider
                value={years}
                onChange={sliderChange}
                min={MIN_YEAR}
                max={MAX_YEAR}
                valueLabelDisplay="auto"
              />
            </SliderWrap>
            
            <ChartContainer id={id}>
              <Loader />
            </ChartContainer>
            {
              loading ? (
                <Loader />
              ) : showKPI ? 
              [ 
                (chartSettings.id === "demanda_electrica" ? null : 
                (<SelectContainer key="select-container">
                  <Select 
                  key="select-year"
                  value={kpiYear}
                  options={arrayYears}
                  onChange={value => {
                    // loadKpi(value);
                    setKpiYear(value);
                    }}/> </SelectContainer>
                )), 
              (
                <KPI
                  key="kpi-year"
                  mode={mode}
                  currentScenario={currentScenario}
                  base={kpiAmounts[0]}
                  newValue={kpiAmounts[1]}
                  kpiDict = {kpiDict}
                  año={kpiYear}
                />
              )] : null
            }
            <div style={{position: 'absolute', left : -10000 }} id="pdf-charts0"></div>
            <div style={{position: 'absolute', left : -10000 }} id="pdf-charts1"></div>
            <div style={{position: 'absolute', left : -10000 }} id="pdf-charts2"></div>
            <div style={{position: 'absolute', left : -10000 }} id="pdf-charts3"></div>
            <div style={{position: 'absolute', left : -10000 }} id="pdf-charts4"></div>
            <div style={{position: 'absolute', left : -10000 }} id="pdf-charts5"></div>
            <div style={{position: 'absolute', left : -10000 }} id="pdf-charts6"></div>
            <div style={{position: 'absolute', left : -10000 }} id="pdf-charts7"></div>
            <div style={{position: 'absolute', left : -10000 }} id="pdf-charts8"></div>
            <div style={{position: 'absolute', left : -10000 }} id="pdf-charts9"></div>
            <div style={{position: 'absolute', left : -10000 }} id="pdf-charts10"></div>
            <div style={{position: 'absolute', left : -10000 }} id="pdf-charts11"></div>
            <div style={{position: 'absolute', left : -10000 }} id="pdf-charts12"></div>
            <div style={{position: 'absolute', left : -10000 }} id="pdf-charts13"></div>
            <div style={{position: 'absolute', left : -10000 }} id="pdf-charts14"></div>
            <div style={{position: 'absolute', left : -10000 }} id="pdf-charts15"></div>
            <div style={{position: 'absolute', left : -10000 }} id="pdf-charts16"></div>

            <div style={{position: 'absolute', left : -10000 }} id="emisiones"> <EmisionesChart chile={Number((emisiones[1] / 18).toFixed(2))} /> </div>
            
            
            
          </React.Fragment>
        ) : (
          <Message info>
            No hay set de datos disponibles para la selección actual.
          </Message>
        )
      }
    </Wrapper>
  );
};

Chart.propTypes = {
  datasetPath: string,
  stacked: bool,
};

export default Chart;
