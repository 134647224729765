import React from 'react';
import HelpContent from './HelpContent';
//import proyeccionParticipacion from 'img/proyeccion_participacion.png';

const TeletrabajoHelp = () => (
  <HelpContent>
    <h1>Teletrabajo y plasticidad laboral</h1>
    <p>
    Esta medida analiza el impacto del teletrabajo en la reducción de viajes motorizados. La implementación masiva de esta modalidad de trabajo y la plasticidad laboral
    podría tener múltiples beneficios asociados al transporte: disminución de la demanda de punta de viajes motorizados, descongestión de calles, menor infraestructura 
    vial para satisfacer demanda de transporte, disminución de consumo de energía, etc.
    </p>
    
    <p>
    La Organización Internacional del Trabajo (OIT) define al teletrabajo “cualquier trabajo efectuado en un lugar donde, lejos de las oficinas o talleres 
    centrales, el trabajador no mantiene contacto personal con sus colegas, pero puede comunicarse con ellos a través de las nuevas tecnologías”. 
    En (Gabriela & Romanik 2011) se identifican distintos tipos de teletrabajo, entre los que se destaca:
    
    <ul>
    <li>Teletrabajo desde el hogar o telehomeworking.</li>
    <li>Teletrabajo cercano al hogar: telecentros comunitarios y oficinas satélites.</li>
    <li>Teletrabajo en cualquier lugar: telecentros y call centers.</li>
    <li>Teletrabajo en diferentes lugares: nómada o móvil.</li>
    <li>Teletrabajo a través de países: transfronterizo (países vecinos) y offshore (países distantes).</li>
    <li>Teletrabajo permanente.</li>
    <li>Teletrabajo parcial o suplementario.</li>
    <li>Teletrabajador independiente o self-employed.</li>
    <li>Teletrabajador dependiente.</li>
    </ul>
    </p>
    
    <p>
    La trayectoría 1 considera que el una reducción de 10% de los viajes motorizados en vehículos particulares producto del teletrabajo.
    </p>
    <p>
    La trayectoría 2 considera que el una reducción de 15% de los viajes motorizados en vehículos particulares producto del teletrabajo.
    </p>
  </HelpContent>
  
);

export default TeletrabajoHelp;
