
import React from 'react';
import BigNumber from './layout/bigNumber';
import Fact from './layout/fact';
import Wrapper from './layout/wrapper';

const Netas = ({amount,perCapita, año}) => {
    return (
  <Wrapper>
          <div>
            <Fact>
            <h4> Las emisiones netas totales para el año {año} corresponden a:</h4>
            <BigNumber>
              <h1>
                { amount.toFixed(1).replace('.', ',').toLocaleString('es-ES') }
              </h1>
              <label>
                millones de toneladas de CO<sub>2e</sub>
              </label>
            </BigNumber>
            </Fact>
            <Fact baseline>
            <h4> Este escenario :</h4>
            
              { amount > 0 ?
              <BigNumber>
              <h1 className="warning">
                No
              </h1>
  
              <label>
                cumple con carbono neutralidad al año {año}
              </label>
            </BigNumber>
            : <BigNumber>
            <h1 className="success">
              Si
            </h1>
  
            <label>
              cumple con carbono neutralidad al año {año}
            </label>
          </BigNumber>
          }
          </Fact>
            <Fact>
              <h4>
              Las emisiones netas per cápita  de este escenario corresponden a:
              </h4>
              <BigNumber>
                <h1>
                  { perCapita.toFixed(1).replace('.', ',') }
                </h1>
                <label>
                  toneladas de CO<sub>2e</sub> al año {año}
                </label>
              </BigNumber>
            </Fact>
          </div>
        </Wrapper>
  );
        }

export default Netas;