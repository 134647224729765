import BigNumber from './layout/bigNumber';
import Fact from './layout/fact';
import Wrapper from './layout/wrapper';
import React from 'react';
import car from 'img/car.svg';
import home from 'img/home.svg';

const Energetic_Demand = ({amount, cars, homeValue, año}) => {
    return (
      <Wrapper>
        <Fact>
          <h4>
            Reducción de consumo de energía para el año {año}
          </h4>
          <BigNumber>
            <h1 style={{ marginTop: 80}}>
              { Number(Math.floor(amount)).toLocaleString('es-ES') }
            </h1>
            <label>
              TCal
            </label>
          </BigNumber>
        </Fact>
        <div>
          <Fact>
            <Wrapper>
              <div style={{ width: 240 }}>
                <img src={home} width={80} alt="home" />
              </div>
              <p style={{ paddingTop: 20 }}>
                Esta reducción equivale al Consumo de energía eléctrica de <b>{ Number(Math.floor(homeValue)).toLocaleString('es-ES') }</b> hogares chilenos en un año
              </p>
            </Wrapper>
          </Fact>
          <Fact>
            <Wrapper>
              <div style={{ width: 190 }}>
                <img src={car} width={80} alt="car" />
              </div>
              <p style={{ paddingTop: 20 }}>
                Esta reducción equivale al Consumo promedio anual de <b>{ Math.ceil(Number(cars)).toLocaleString('es-ES') }</b> automóviles
              </p>
            </Wrapper>
          </Fact>
        </div>
      </Wrapper>
    )
  }

export default Energetic_Demand;