import React, { useEffect } from 'react';
import styled from 'styled-components';
import ChartJs from 'chart.js';
import {
  MIN_YEAR,
  MAX_YEAR,
  COLORS,
} from 'utils/constants';


/**
 * SmallChart.js
 * Pequeño gráfico que permite visualizar las trayectorias de una medida.
 */

const initChart = (id, data) => {
  const elem = document.getElementById(id);
  const canvasId = Math.random();
  elem.innerHTML = `<canvas id="${canvasId}" height="240px"></canvas>`;
  const ctx = document.getElementById(canvasId).getContext('2d');
  // multiple series
  let multiple = data.series.length && Array.isArray(data.series[0].serie);
  let years = [];
  for (let year = MIN_YEAR; year <= MAX_YEAR; year++) {
    years.push(year);
  }
  
  new ChartJs(ctx, {
    type: 'line',
    data: {
      datasets: multiple ? data.series.map((item, i) => ({
        data: item.serie,
        label: item.label,
        backgroundColor: 'transparent',
        borderColor: COLORS[i],
        borderWidth: 1,
        pointRadius: 1,
      })) : [{
        data: data.series,
        backgroundColor: 'transparent',
        borderColor: COLORS[0],
        borderWidth: 1,
        pointRadius: 1,
      }],
      labels: years,
    },
    options: {
      responsive: true,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [{
          gridLines: {
            color: '#eeeeee',
          },  
          ticks: {
            minRotation: 0,
            maxRotation: 0,
          },        
        }],
        yAxes: [
          {
            id: 'y-axes',
            stacked: false,
            scaleLabel: {
              display: true,
              labelString: data.unit,
            },
            gridLines: {
              color: '#eeeeee',
            },
            ticks: {
              fontSize: 9,
            },
          },
        ],
      },
    },
  });
}

const Wrapper = styled.div`
  width: 100%;
  padding: ${props => `${props.padding}px`};
  border-radius: 5px;
  font-size: 11px;
  background-color: white;

  & > div > div > svg {
    margin-left: -20px;
    margin-bottom: 10px;
  }
`;

const ChartWrapper = styled.div`
  width: 100%;
  margin: 5px;
  margin-bottom: 10px;
`;

const Info = styled.div`
  width: 100%;
  padding: 10px;
  background-color: #333;
  color: white;
  font-size: 14px;
`;

const SmallChart = ({ id, data, info }) => {
  useEffect(() => {
    if (data) {
      initChart(id, data);
    }
  }, [id, data]);

  return (
    <Wrapper padding={data ? 10 : 0}>
      <ChartWrapper id={id} />
      {
        info && (
          <Info>{ info }</Info>
        )
      }
    </Wrapper>
  );
}

export default SmallChart;
