import React from 'react';
import styled from 'styled-components';
import Container from 'components/layout/Container';
import { Link } from "react-router-dom";

const Wrapper = styled(Container)`
  margin: 20px auto;
`;

const Menu = styled.div`
  list-style: none;
  justify-content: center;
  display:flex;
  
  li {
    font-weight: 700;
    display: inline-block;
    margin-right: 20px;
    margin-left: 20px;
    font-size: 14px;
    color: var(--medium-grey-color);

    a {
      text-decoration: none;
      color: var(--medium-grey-color);
      :hover {
        color: black;
    }
    }
  }
`;
const NavHome = () => (
  <Wrapper>
    <Menu>
      <li>
        <Link to="/" style={{'color': 'black'}}>Inicio</Link>
      </li>
      <li>
        <Link to="/acercade">Modelo de proyección PMR</Link>
      </li>
      <li>
        <a href="http://www.diplomacambioclimatico.cl/" target="_blank" rel="noopener noreferrer">
        Diplomado Cambio Climático
        </a>
      </li>
      <li>
        <a href="http://centroenergia.cl/contacto/" target="_blank" rel="noopener noreferrer">
        Contacto
        </a>
      </li>
    </Menu>
  </Wrapper>
);

export default NavHome;