import React from 'react';
import HelpContent from './HelpContent';
import taxi_electrico from 'img/taxiselectricos.png';
//import proyeccionParticipacion from 'img/proyeccion_participacion.png';

const ElectromovilidadTaxisColectivosHelp = () => (
  <HelpContent>
    <h1>Electromovilidad en taxis y taxis colectivos</h1>
    <p>
      Esta medida tiene como objetivo la introducción de vehículos eléctricos en taxis colectivos y taxis.
    </p>
    <img src={taxi_electrico} alt="Taxi Eléctrico" />
    <label>Figura: Taxi Eléctrico</label>
    <p>
    Los supuestos de la trayectoria 1 son los siguientes:
    </p>
    <p>    
      Se alcanza una participación de 100% de taxis eléctricos hacia el año 2040. 
    </p>
    <p>
    Los supuestos de la trayectoria 2 son los siguientes:
    </p>  
    <p>
      Se alcanza una participación de 100% de taxis eléctricos hacia el año 2030.  
    </p>
  </HelpContent>
);

export default ElectromovilidadTaxisColectivosHelp;
