import React, { useState } from 'react';
import { func, object } from 'prop-types';
import Settings from 'components/ui/Settings';
import Checkbox from 'components/ui/Checkbox';
import Slider from 'components/ui/Slider';
import Help from 'components/ui/Help';
import SmallChart from 'components/ui/SmallChart';
import SliderLegend from 'components/ui/SliderLegend';
import { trayectoriasRetiroCarbon, trayectoriasElectromovilidad } from 'config';
import IntroERHelp from 'components/help/IntroERHelp';
import IntroReemplazoCombuHelp from 'components/help/IntroReemplazoCombustibleHelp';
import IntroHidrogenoMineriaHelp from 'components/help/IntroHidrogenoMineriaHelp';
import EficienciaTransporteHelp from 'components/help/EficienciaTransporteHelp';
import EficienciaMineriaHelp from 'components/help/EficienciaMineriaHelp';
import RetiroCentralesHelp from 'components/help/RetiroCentralesHelp';
import ElectromovilidadLivianoHelp from 'components/help/ElectromovilidadLivianoHelp';
import ElectromovilidadBusesHelp from 'components/help/ElectromovilidadBusesHelp';
import ERBajaTensionHelp from 'components/help/ERBajaTensionHelp';
import ElectrificacionCalefaccionResidencialHelp from 'components/help/ElectrificacionCalefaccionResidencialHelp';
import HidrogenoCamionesHelp from 'components/help/HidrogenoCamionesHelp';
import HidrogenoAereoHelp from 'components/help/HidrogenoAereoHelp';
import HidrogenoMotricesHelp from 'components/help/HidrogenoMotricesHelp';
import HidrogenoTermicoHelp from 'components/help/HidrogenoTermicoHelp';
import HidrogenoMaritimoHelp from 'components/help/HidrogenoMaritimoHelp';
import TeletrabajoHelp from 'components/help/TeletrabajoHelp';
import ElectrificacionFinalComercialHelp from 'components/help/ElectrificacionFinalComercialHelp';
import TransportePublicoHelp from 'components/help/TransportePublicoHelp';
import TransporteNoMotorizadoHelp from 'components/help/TransporteNoMotorizadoHelp';
import SolaresResidencialHelp from 'components/help/SolaresResidencialHelp';
import ElectrificacionIndustriaMineriaHelp from 'components/help/ElectrificacionIndustriaMineriaHelp';
import ElectrificacionCoccionMineriaHelp from 'components/help/ElectrificacionCoccionMineriaHelp';
import ReglamentacionTermicaViviendasHelp from 'components/help/ReglamentacionTermicaViviendasHelp';
import ElectromovilidadTaxisColectivosHelp from 'components/help/ElectromovilidadTaxisColectivosHelp';

/**
 * Opciones de configuración para las diferentes políticas energéticas, y la explicación de cada una. 
 */
const EnergeticPolicies = ({ settings, onChange }) => {

  // Nomenclatura frecuente -> primeras tres letras de cada palabra de una id
  const selectedTrayectoriaIntroHidroMin = settings.introduccion_hidrogeno_mineria.trayectoria || 1;
  //const selectedTrayectoriaID = settings.ID.trayectoria || 1;
  const selectedTrayectoriaHidroCam = settings.hidrogeno_camiones.trayectoria || 1; 
  const selectedTrayectoriaHidAer = settings.hidrogeno_aereo.trayectoria || 1; 
  const selectedTrayectoriaHidMar = settings.hidrogeno_maritimo.trayectoria || 1; 
  const selectedTrayectoriaHidMot = settings.hidrogeno_motrices.trayectoria || 1; 
  const selectedTrayectoriaHidTer = settings.hidrogeno_termico.trayectoria || 1; 
  const selectedTrayectoriaSolRes = settings.solares_residencial.trayectoria || 1; 
  const selectedTrayectoriaEleIndMin = settings.electrificacion_industria_mineria.trayectoria || 1; 
  const selectedTrayectoriaEleCalRes = settings.electrificacion_calefaccion_residencial.trayectoria || 1; 
  const selectedTrayectoriaEleCocMin = settings.electrificacion_coccion_mineria.trayectoria || 1; 
  const selectedTrayectoriaEleFinCom = settings.electrificacion_final_comercial.trayectoria || 1; 
  const selectedTrayectoriaRegTerViv = settings.reglamentacion_termica_viviendas.trayectoria || 1; 
  const selectedTrayectoriaTraPub = settings.transporte_publico.trayectoria || 1; 
  const selectedTrayectoriaTraNoMot = settings.transporte_no_motorizado.trayectoria || 1; 
  const selectedTrayectoriaTel = settings.teletrabajo.trayectoria || 1; 
  const selectedTrayectoriaEleTaxCol = settings.electromovilidad_taxis_y_colectivos.trayectoria || 1; 
  const selectedTrayectoriaIntroER = settings.introduccion_energias_renovables.trayectoria || 1;
  const selectedTrayectoriaReemplazo = settings.reemplazo_combustibles_procesos_term.trayectoria || 1;
  const selectedTrayectoriaEficienciaTransp = settings.eficiencia_energetica_transporte.trayectoria || 1;
  const selectedTrayectoriaEficienciaMin = settings.eficiencia_energetica_industria_mineria.trayectoria || 1;
  const selectedTrayectoriaRetiroCarbon = settings.retiro_centrales_carbon.trayectoria || 1;
  const selectedTrayectoriaElectromovilidad = settings.electromovilidad.trayectoria || 1;
  const selectedTrayectoriaEleBus = settings.electromovilidad_buses.trayectoria || 1;
  const selectedTrayectoriaEEBT = settings.introduccion_er_baja_tension.trayectoria || 1;

  const [introERVisible, setIntrodERVisible] = useState(true);
  const [eficienciaTranspVisible, setEficienciaTranspVisible] = useState(true);
  const [eficienciaMinVisible, setEficienciaMinVisible] = useState(true);
  const [retiroVisible, setRetiroVisible] = useState(true);
  const [electromovilidadVisible, setElectromovilidadVisible] = useState(true);
  const [eebtVisible, setEebtvisible] = useState(true);
  const [hidroCamVisible  , setHidroCamVisible] = useState(true);
  const [hidAerVisible    , setHidAerVisible] = useState(true);
  const [hidTraMin, setHidTraMinVisible] = useState(true);
  const [hidMarVisible    , setHidMarVisible] = useState(true);
  const [hidMotVisible    , setHidMotVisible] = useState(true);
  const [hidTerVisible    , setHidTerVisible] = useState(true);
  const [solResVisible    , setSolResVisible] = useState(true);
  const [eleIndMinVisible , setEleIndMinVisible] = useState(true);
  const [eleCalResVisible , setEleCalResVisible] = useState(true);
  const [eleCocMinVisible , setEleCocMinVisible] = useState(true);
  const [eleFinComVisible , setEleFinComVisible] = useState(true);
  const [regTerVivVisible , setRegTerVivVisible] = useState(true);
  const [traPubVisible    , setTraPubVisible] = useState(true);
  const [traNoMotVisible  , setTraNoMotVisible] = useState(true);
  const [telVisible       , setTelVisible] = useState(true);
  const [eleTaxColVisible , setEleTaxColVisible] = useState(true);
  const [reemplazoVisible , setReemplazoVisible] = useState(true);
  const [eleBusVisible   , setEleBusVisible] = useState(true);

  return (
    <Settings.Panel.Correct>
      <h4>Medidas de mitigación</h4>
      <Checkbox
        id="introduccion_hidrogeno_mineria"
        label="Hidrógeno en transporte minero"
        checked={settings.introduccion_hidrogeno_mineria.enabled}
        onChange={(e, value) => {
          const newValue = value ? ({
            introduccion_hidrogeno_mineria: {
              ...settings.introduccion_hidrogeno_mineria,
              enabled: true,
              trayectoria: selectedTrayectoriaIntroHidroMin,
            },
          }) : {
            introduccion_hidrogeno_mineria: {
              enabled: false,
            },
          }
          onChange({
            ...settings,
            ...newValue,
          });
          setHidTraMinVisible(value);
        }}
        visible={hidTraMin}
        enabled={settings.introduccion_hidrogeno_mineria.enabled}
        toggleVisible={() => {
          setHidTraMinVisible(!hidTraMin);
        }}
        help={<IntroHidrogenoMineriaHelp />}
      />
      {
        settings.introduccion_hidrogeno_mineria.enabled && hidTraMin && (
          <Settings.Options>
            <label>
              Trayectoria de introducción de hidrógeno en tranporte minero
            </label>
            <Slider
              value={selectedTrayectoriaIntroHidroMin}
              min={1}
              max={2}
              step={1}
              onChange={(e, value) => {
                onChange({
                  ...settings,
                  introduccion_hidrogeno_mineria: {
                    ...settings.introduccion_hidrogeno_mineria,
                    trayectoria: value,
                  },
                })
              }}
            />
            <SliderLegend
              options={[
                {
                  label: 'Tray. 1',
                  value: 1,
                },
                {
                  label: 'Tray. 2',
                  value: 2,
                },
              ]}
              onChange={(value) => {
                onChange({
                  ...settings,
                  introduccion_hidrogeno_mineria: {
                    ...settings.introduccion_hidrogeno_mineria,
                    trayectoria: value,
                  },
                })
              }}
            />
          </Settings.Options>
        )
      }
      <Checkbox
        id="hidrogeno_camiones"
        label="Hidrógeno en camiones de transporte carga por carreteras"
        checked={settings.hidrogeno_camiones.enabled}
        onChange={(e, value) => {
          const newValue = value ? ({
            hidrogeno_camiones: {
              ...settings.hidrogeno_camiones,
              enabled: true,
              trayectoria: selectedTrayectoriaHidroCam,
            },
          }) : {
            hidrogeno_camiones: {
              enabled: false,
            },
          }
          onChange({
            ...settings,
            ...newValue,
          });
          setHidroCamVisible(value); 
        }}
        visible={hidroCamVisible}
        enabled={settings.hidrogeno_camiones.enabled}
        toggleVisible={() => {
          setHidroCamVisible(!hidroCamVisible);
        }}
        help={<HidrogenoCamionesHelp />} //[ID].Help
      />
      {
        settings.hidrogeno_camiones.enabled && hidroCamVisible && (
          <Settings.Options>
            <label>
              Trayectoria de Hidrógeno en camiones de transporte carga por carreteras
            </label>
            <Slider
              value={selectedTrayectoriaHidroCam}
              min={1}
              max={2}
              step={1}
              onChange={(e, value) => {
                onChange({
                  ...settings,
                  hidrogeno_camiones: {
                    ...settings.hidrogeno_camiones,
                    trayectoria: value,
                  },
                })
              }}
            />
            <SliderLegend
              options={[
                {
                  label: 'Tray. 1',
                  value: 1,
                },
                {
                  label: 'Tray. 2',
                  value: 2,
                },
              ]}
              onChange={(value) => {
                onChange({
                  ...settings,
                  hidrogeno_camiones: {
                    ...settings.hidrogeno_camiones,
                    trayectoria: value,
                  },
                })
              }}
            />
          </Settings.Options>
        )
      }
      <Checkbox
        id="hidrogeno_aereo"
        label="Hidrógeno en transporte aéreo"
        checked={settings.hidrogeno_aereo.enabled}
        onChange={(e, value) => {
          const newValue = value ? ({
            hidrogeno_aereo: {
              ...settings.hidrogeno_aereo,
              enabled: true,
              trayectoria: selectedTrayectoriaHidAer,
            },
          }) : {
            hidrogeno_aereo: {
              enabled: false,
            },
          }
          onChange({
            ...settings,
            ...newValue,
          });
          setHidAerVisible(hidAerVisible); 
        }}
        visible={hidAerVisible}
        enabled={settings.hidrogeno_aereo.enabled}
        toggleVisible={() => {
          setHidAerVisible(!hidAerVisible);
        }}
        help={<HidrogenoAereoHelp />} //hidrogeno_aereo.Help
      />
      {
        settings.hidrogeno_aereo.enabled && hidAerVisible && (
          <Settings.Options>
            <label>
              Trayectoria de Hidrógeno en transporte aéreo
            </label>
            <Slider
              value={selectedTrayectoriaHidAer}
              min={1}
              max={2}
              step={1}
              onChange={(e, value) => {
                onChange({
                  ...settings,
                  hidrogeno_aereo: {
                    ...settings.hidrogeno_aereo,
                    trayectoria: value,
                  },
                })
              }}
            />
            <SliderLegend
              options={[
                {
                  label: 'Tray. 1',
                  value: 1,
                },
                {
                  label: 'Tray. 2',
                  value: 2,
                },
              ]}
              onChange={(value) => {
                onChange({
                  ...settings,
                  hidrogeno_aereo: {
                    ...settings.hidrogeno_aereo,
                    trayectoria: value,
                  },
                })
              }}
            />
          </Settings.Options>
        )
      }
      <Checkbox
        id="hidrogeno_maritimo"
        label="Hidrógeno en transporte marítimo"
        checked={settings.hidrogeno_maritimo.enabled}
        onChange={(e, value) => {
          const newValue = value ? ({
            hidrogeno_maritimo: {
              ...settings.hidrogeno_maritimo,
              enabled: true,
              trayectoria: selectedTrayectoriaHidMar,
            },
          }) : {
            hidrogeno_maritimo: {
              enabled: false,
            },
          }
          onChange({
            ...settings,
            ...newValue,
          });
          setHidMarVisible(value); 
        }}
        visible={hidMarVisible}
        enabled={settings.hidrogeno_maritimo.enabled}
        toggleVisible={() => {
          setHidMarVisible(!hidMarVisible);
        }}
        help={<HidrogenoMaritimoHelp />} //hidrogeno_maritimo.Help
      />
      {
        settings.hidrogeno_maritimo.enabled && hidMarVisible && (
          <Settings.Options>
            <label>
              Trayectoria de Hidrógeno en transporte marítimo
            </label>
            <Slider
              value={selectedTrayectoriaHidMar}
              min={1}
              max={2}
              step={1}
              onChange={(e, value) => {
                onChange({
                  ...settings,
                  hidrogeno_maritimo: {
                    ...settings.hidrogeno_maritimo,
                    trayectoria: value,
                  },
                })
              }}
            />
            <SliderLegend
              options={[
                {
                  label: 'Tray. 1',
                  value: 1,
                },
                {
                  label: 'Tray. 2',
                  value: 2,
                },
              ]}
              onChange={(value) => {
                onChange({
                  ...settings,
                  hidrogeno_maritimo: {
                    ...settings.hidrogeno_maritimo,
                    trayectoria: value,
                  },
                })
              }}
            />
          </Settings.Options>
        )
      }
       <Checkbox
        id="hidrogeno_motrices"
        label="Hidrógeno para usos motrices en industria y minería"
        checked={settings.hidrogeno_motrices.enabled}
        onChange={(e, value) => {
          const newValue = value ? ({
            hidrogeno_motrices: {
              ...settings.hidrogeno_motrices,
              enabled: true,
              trayectoria: selectedTrayectoriaHidMot,
            },
          }) : {
            hidrogeno_motrices: {
              enabled: false,
            },
          }
          onChange({
            ...settings,
            ...newValue,
          });
          setHidMotVisible(value); 
        }}
        visible={hidMotVisible}
        enabled={settings.hidrogeno_motrices.enabled}
        toggleVisible={() => {
          setHidMotVisible(!hidMotVisible);
        }}
        help={<HidrogenoMotricesHelp />} //hidrogeno_motrices.Help
      />
      {
        settings.hidrogeno_motrices.enabled && hidMotVisible && (
          <Settings.Options>
            <label>
              Trayectoria de Hidrógeno para usos motrices en industria y minería
            </label>
            <Slider
              value={selectedTrayectoriaHidMot}
              min={1}
              max={2}
              step={1}
              onChange={(e, value) => {
                onChange({
                  ...settings,
                  hidrogeno_motrices: {
                    ...settings.hidrogeno_motrices,
                    trayectoria: value,
                  },
                })
              }}
            />
            <SliderLegend
              options={[
                {
                  label: 'Tray. 1',
                  value: 1,
                },
                {
                  label: 'Tray. 2',
                  value: 2,
                },
              ]}
              onChange={(value) => {
                onChange({
                  ...settings,
                  hidrogeno_motrices: {
                    ...settings.hidrogeno_motrices,
                    trayectoria: value,
                  },
                })
              }}
            />
          </Settings.Options>
        )
      }
       <Checkbox
        id="hidrogeno_termico"
        label="Hidrógeno para usos térmicos en industria y minería"
        checked={settings.hidrogeno_termico.enabled}
        onChange={(e, value) => {
          const newValue = value ? ({
            hidrogeno_termico: {
              ...settings.hidrogeno_termico,
              enabled: true,
              trayectoria: selectedTrayectoriaHidTer,
            },
          }) : {
            hidrogeno_termico: {
              enabled: false,
            },
          }
          onChange({
            ...settings,
            ...newValue,
          });
          setHidTerVisible(value); 
        }}
        visible={hidTerVisible}
        enabled={settings.hidrogeno_termico.enabled}
        toggleVisible={() => {
          setHidTerVisible(!hidTerVisible);
        }}
        help={<HidrogenoTermicoHelp />} //hidrogeno_termico.Help
      />
      {
        settings.hidrogeno_termico.enabled && hidTerVisible && (
          <Settings.Options>
            <label>
              Trayectoria de Hidrógeno para usos térmicos en industria y minería
            </label>
            <Slider
              value={selectedTrayectoriaHidTer}
              min={1}
              max={2}
              step={1}
              onChange={(e, value) => {
                onChange({
                  ...settings,
                  hidrogeno_termico: {
                    ...settings.hidrogeno_termico,
                    trayectoria: value,
                  },
                })
              }}
            />
            <SliderLegend
              options={[
                {
                  label: 'Tray. 1',
                  value: 1,
                },
                {
                  label: 'Tray. 2',
                  value: 2,
                },
              ]}
              onChange={(value) => {
                onChange({
                  ...settings,
                  hidrogeno_termico: {
                    ...settings.hidrogeno_termico,
                    trayectoria: value,
                  },
                })
              }}
            />
          </Settings.Options>
        )
      }
      <Checkbox
        id="reemplazo_combustibles_procesos_term"
        label="Reemplazo de combustibles fósiles por gas natural en procesos térmicos"
        checked={settings.reemplazo_combustibles_procesos_term.enabled}
        onChange={(e, value) => {
          const newValue = value ? ({
            reemplazo_combustibles_procesos_term: {
              ...settings.reemplazo_combustibles_procesos_term,
              enabled: true,
              trayectoria: selectedTrayectoriaReemplazo,
            },
          }) : {
            reemplazo_combustibles_procesos_term: {
              enabled: false,
            },
          }
          onChange({
            ...settings,
            ...newValue,
          });
          setIntrodERVisible(value);
        }}
        visible={reemplazoVisible}
        enabled={settings.reemplazo_combustibles_procesos_term.enabled}
        toggleVisible={() => {
          setReemplazoVisible(!reemplazoVisible);
        }}
        help={<IntroReemplazoCombuHelp />}
      />
      {
        settings.reemplazo_combustibles_procesos_term.enabled && reemplazoVisible && (
          <Settings.Options>
            <label>
              Trayectoria de Introducción E.R. en procesos productivos
            </label>
            <Slider
              value={selectedTrayectoriaReemplazo}
              min={1}
              max={2}
              step={1}
              onChange={(e, value) => {
                onChange({
                  ...settings,
                  reemplazo_combustibles_procesos_term: {
                    ...settings.reemplazo_combustibles_procesos_term,
                    trayectoria: value,
                  },
                })
              }}
            />
            <SliderLegend
              options={[
                {
                  label: 'Tray. 1',
                  value: 1,
                },
                {
                  label: 'Tray. 2',
                  value: 2,
                },
              ]}
              onChange={(value) => {
                onChange({
                  ...settings,
                  reemplazo_combustibles_procesos_term: {
                    ...settings.reemplazo_combustibles_procesos_term,
                    trayectoria: value,
                  },
                })
              }}
            />
          </Settings.Options>
        )
      }
      <Checkbox
        id="introduccion_energias_renovables"
        label="Sistemas solares térmicos en industria y minería"
        checked={settings.introduccion_energias_renovables.enabled}
        onChange={(e, value) => {
          const newValue = value ? ({
            introduccion_energias_renovables: {
              ...settings.introduccion_energias_renovables,
              enabled: true,
              trayectoria: selectedTrayectoriaIntroER,
            },
          }) : {
            introduccion_energias_renovables: {
              enabled: false,
            },
          }
          onChange({
            ...settings,
            ...newValue,
          });
          setIntrodERVisible(value);
        }}
        visible={introERVisible}
        enabled={settings.introduccion_energias_renovables.enabled}
        toggleVisible={() => {
          setIntrodERVisible(!introERVisible);
        }}
        help={<IntroERHelp />}
      />
      {
        settings.introduccion_energias_renovables.enabled && introERVisible && (
          <Settings.Options>
            <label>
              Trayectoria de Introducción E.R. en procesos productivos
            </label>
            <Slider
              value={selectedTrayectoriaIntroER}
              min={1}
              max={2}
              step={1}
              onChange={(e, value) => {
                onChange({
                  ...settings,
                  introduccion_energias_renovables: {
                    ...settings.introduccion_energias_renovables,
                    trayectoria: value,
                  },
                })
              }}
            />
            <SliderLegend
              options={[
                {
                  label: 'Tray. 1',
                  value: 1,
                },
                {
                  label: 'Tray. 2',
                  value: 2,
                },
              ]}
              onChange={(value) => {
                onChange({
                  ...settings,
                  introduccion_energias_renovables: {
                    ...settings.introduccion_energias_renovables,
                    trayectoria: value,
                  },
                })
              }}
            />
          </Settings.Options>
        )
      }
      <Checkbox
        id="solares_residencial"
        label="Sistemas solares térmicos en sector residencial"
        checked={settings.solares_residencial.enabled}
        onChange={(e, value) => {
          const newValue = value ? ({
            solares_residencial: {
              ...settings.solares_residencial,
              enabled: true,
              trayectoria: selectedTrayectoriaSolRes,
            },
          }) : {
            solares_residencial: {
              enabled: false,
            },
          }
          onChange({
            ...settings,
            ...newValue,
          });
          setSolResVisible(value); 
        }}
        visible={solResVisible}
        enabled={settings.solares_residencial.enabled}
        toggleVisible={() => {
          setSolResVisible(!solResVisible);
        }}
        help={<SolaresResidencialHelp />} //solares_residencial.Help
      />
      {
        settings.solares_residencial.enabled && solResVisible && (
          <Settings.Options>
            <label>
              Trayectoria de Sistema solares térmicos en sector residencial
            </label>
            <Slider
              value={selectedTrayectoriaSolRes}
              min={1}
              max={2}
              step={1}
              onChange={(e, value) => {
                onChange({
                  ...settings,
                  solares_residencial: {
                    ...settings.solares_residencial,
                    trayectoria: value,
                  },
                })
              }}
            />
            <SliderLegend
              options={[
                {
                  label: 'Tray. 1',
                  value: 1,
                },
                {
                  label: 'Tray. 2',
                  value: 2,
                },
              ]}
              onChange={(value) => {
                onChange({
                  ...settings,
                  solares_residencial: {
                    ...settings.solares_residencial,
                    trayectoria: value,
                  },
                })
              }}
            />
          </Settings.Options>
        )
      }
      <Checkbox
        id="electrificacion_industria_mineria"
        label="Electrificación de usos motrices en industria y minería"
        checked={settings.electrificacion_industria_mineria.enabled}
        onChange={(e, value) => {
          const newValue = value ? ({
            electrificacion_industria_mineria: {
              ...settings.electrificacion_industria_mineria,
              enabled: true,
              trayectoria: selectedTrayectoriaEleIndMin,
            },
          }) : {
            electrificacion_industria_mineria: {
              enabled: false,
            },
          }
          onChange({
            ...settings,
            ...newValue,
          });
          setEleIndMinVisible(value); 
        }}
        visible={eleIndMinVisible}
        enabled={settings.electrificacion_industria_mineria.enabled}
        toggleVisible={() => {
          setEleIndMinVisible(!eleIndMinVisible);
        }}
        help={<ElectrificacionIndustriaMineriaHelp />} //electrificacion_industria_mineria.Help
      />
      {
        settings.electrificacion_industria_mineria.enabled && eleIndMinVisible && (
          <Settings.Options>
            <label>
              Trayectoria de Electrificación de usos motrices en industria y minería 
            </label>
            <Slider
              value={selectedTrayectoriaEleIndMin}
              min={1}
              max={2}
              step={1}
              onChange={(e, value) => {
                onChange({
                  ...settings,
                  electrificacion_industria_mineria: {
                    ...settings.electrificacion_industria_mineria,
                    trayectoria: value,
                  },
                })
              }}
            />
            <SliderLegend
              options={[
                {
                  label: 'Tray. 1',
                  value: 1,
                },
                {
                  label: 'Tray. 2',
                  value: 2,
                },
              ]}
              onChange={(value) => {
                onChange({
                  ...settings,
                  electrificacion_industria_mineria: {
                    ...settings.electrificacion_industria_mineria,
                    trayectoria: value,
                  },
                })
              }}
            />
          </Settings.Options>
        )
      }
      <Checkbox
        id="electrificacion_calefaccion_residencial"
        label="Electrificación de calefacción en sector residencial"
        checked={settings.electrificacion_calefaccion_residencial.enabled}
        onChange={(e, value) => {
          const newValue = value ? ({
            electrificacion_calefaccion_residencial: {
              ...settings.electrificacion_calefaccion_residencial,
              enabled: true,
              trayectoria: selectedTrayectoriaEleCalRes,
            },
          }) : {
            electrificacion_calefaccion_residencial: {
              enabled: false,
            },
          }
          onChange({
            ...settings,
            ...newValue,
          });
          setEleCalResVisible(value); 
        }}
        visible={eleCalResVisible}
        enabled={settings.electrificacion_calefaccion_residencial.enabled}
        toggleVisible={() => {
          setEleCalResVisible(!eleCalResVisible);
        }}
        help={<ElectrificacionCalefaccionResidencialHelp />} //electrificacion_calefaccion_residencial.Help
      />
      {
        settings.electrificacion_calefaccion_residencial.enabled && eleCalResVisible && (
          <Settings.Options>
            <label>
              Trayectoria de Electrificación de calefacción en sector residencial
            </label>
            <Slider
              value={selectedTrayectoriaEleCalRes}
              min={1}
              max={2}
              step={1}
              onChange={(e, value) => {
                onChange({
                  ...settings,
                  electrificacion_calefaccion_residencial: {
                    ...settings.electrificacion_calefaccion_residencial,
                    trayectoria: value,
                  },
                })
              }}
            />
            <SliderLegend
              options={[
                {
                  label: 'Tray. 1',
                  value: 1,
                },
                {
                  label: 'Tray. 2',
                  value: 2,
                },
              ]}
              onChange={(value) => {
                onChange({
                  ...settings,
                  electrificacion_calefaccion_residencial: {
                    ...settings.electrificacion_calefaccion_residencial,
                    trayectoria: value,
                  },
                })
              }}
            />
          </Settings.Options>
        )
      }
      <Checkbox
        id="electrificacion_coccion_mineria"
        label="Electrificación de cocción en sector residencial"
        checked={settings.electrificacion_coccion_mineria.enabled}
        onChange={(e, value) => {
          const newValue = value ? ({
            electrificacion_coccion_mineria: {
              ...settings.electrificacion_coccion_mineria,
              enabled: true,
              trayectoria: selectedTrayectoriaEleCocMin,
            },
          }) : {
            electrificacion_coccion_mineria: {
              enabled: false,
            },
          }
          onChange({
            ...settings,
            ...newValue,
          });
          setEleCocMinVisible(value); 
        }}
        visible={eleCocMinVisible}
        enabled={settings.electrificacion_coccion_mineria.enabled}
        toggleVisible={() => {
          setEleCocMinVisible(!eleCocMinVisible);
        }}
        help={<ElectrificacionCoccionMineriaHelp />} //electrificacion_coccion_mineria.Help
      />
      {
        settings.electrificacion_coccion_mineria.enabled && eleCocMinVisible && (
          <Settings.Options>
            <label>
              Trayectoria de Electrificación de cocción en sector residencial
            </label>
            <Slider
              value={selectedTrayectoriaEleCocMin}
              min={1}
              max={2}
              step={1}
              onChange={(e, value) => {
                onChange({
                  ...settings,
                  electrificacion_coccion_mineria: {
                    ...settings.electrificacion_coccion_mineria,
                    trayectoria: value,
                  },
                })
              }}
            />
            <SliderLegend
              options={[
                {
                  label: 'Tray. 1',
                  value: 1,
                },
                {
                  label: 'Tray. 2',
                  value: 2,
                },
              ]}
              onChange={(value) => {
                onChange({
                  ...settings,
                  electrificacion_coccion_mineria: {
                    ...settings.electrificacion_coccion_mineria,
                    trayectoria: value,
                  },
                })
              }}
            />
          </Settings.Options>
        )
      }
      <Checkbox
        id="electrificacion_final_comercial"
        label="Electrificación de usos finales en sector comercial"
        checked={settings.electrificacion_final_comercial.enabled}
        onChange={(e, value) => {
          const newValue = value ? ({
            electrificacion_final_comercial: {
              ...settings.electrificacion_final_comercial,
              enabled: true,
              trayectoria: selectedTrayectoriaEleFinCom,
            },
          }) : {
            electrificacion_final_comercial: {
              enabled: false,
            },
          }
          onChange({
            ...settings,
            ...newValue,
          });
          setEleFinComVisible(value); 
        }}
        visible={eleFinComVisible}
        enabled={settings.electrificacion_final_comercial.enabled}
        toggleVisible={() => {
          setEleFinComVisible(!eleFinComVisible);
        }}
        help={<ElectrificacionFinalComercialHelp />} //electrificacion_final_comercial.Help
      />
      {
        settings.electrificacion_final_comercial.enabled && eleFinComVisible && (
          <Settings.Options>
            <label>
              Trayectoria de Electrificación de usos finales en sector comercial
            </label>
            <Slider
              value={selectedTrayectoriaEleFinCom}
              min={1}
              max={2}
              step={1}
              onChange={(e, value) => {
                onChange({
                  ...settings,
                  electrificacion_final_comercial: {
                    ...settings.electrificacion_final_comercial,
                    trayectoria: value,
                  },
                })
              }}
            />
            <SliderLegend
              options={[
                {
                  label: 'Tray. 1',
                  value: 1,
                },
                {
                  label: 'Tray. 2',
                  value: 2,
                },
              ]}
              onChange={(value) => {
                onChange({
                  ...settings,
                  electrificacion_final_comercial: {
                    ...settings.electrificacion_final_comercial,
                    trayectoria: value,
                  },
                })
              }}
            />
          </Settings.Options>
        )
      }
      <Checkbox
        id="eficiencia_energetica_transporte"
        label="Eficiencia energética en transporte"
        checked={settings.eficiencia_energetica_transporte.enabled}
        onChange={(e, value) => {
          const newValue = value ? ({
            eficiencia_energetica_transporte: {
              ...settings.eficiencia_energetica_transporte,
              enabled: true,
              trayectoria: selectedTrayectoriaEficienciaTransp,
            },
          }) : {
            eficiencia_energetica_transporte: {
              enabled: false,
            },
          }
          onChange({
            ...settings,
            ...newValue,
          });
          setEficienciaTranspVisible(value);
        }}
        visible={eficienciaTranspVisible}
        enabled={settings.eficiencia_energetica_transporte.enabled}
        toggleVisible={() => {
          setEficienciaTranspVisible(!eficienciaTranspVisible);
        }}
        help={<EficienciaTransporteHelp />}
      />
      {
        settings.eficiencia_energetica_transporte.enabled && eficienciaTranspVisible && (
          <Settings.Options>
            <Help title={(
              <label>
                Trayectoria de Eficiencia Energética en Transporte
              </label>
            )}>
              <SmallChart
                id="eficiencia_energetica_transporte"
                info="Rendimiento nuevos vehículos livianos y medianos."
              />
            </Help>
            <Slider
              value={selectedTrayectoriaEficienciaTransp}
              min={1}
              max={3}
              step={1}
              onChange={(e, value) => {
                onChange({
                  ...settings,
                  eficiencia_energetica_transporte: {
                    ...settings.eficiencia_energetica_transporte,
                    trayectoria: value,
                  },
                })
              }}
            />
            <SliderLegend
              options={[
                {
                  label: 'Tray. 1',
                  value: 1,
                },
                {
                  label: 'Tray. 2',
                  value: 2,
                },
                {
                  label: 'Tray. 3',
                  value: 3,
                },
              ]}
              onChange={(value) => {
                onChange({
                  ...settings,
                  eficiencia_energetica_transporte: {
                    ...settings.eficiencia_energetica_transporte,
                    trayectoria: value,
                  },
                })
              }}
            />
          </Settings.Options>
        )
      }
      <Checkbox
        id="eficiencia_energetica_industria_mineria"
        label="Eficiencia energética en industria y minería"
        checked={settings.eficiencia_energetica_industria_mineria.enabled}
        onChange={(e, value) => {
          const newValue = value ? ({
            eficiencia_energetica_industria_mineria: {
              ...settings.eficiencia_energetica_industria_mineria,
              enabled: true,
              trayectoria: selectedTrayectoriaEficienciaMin,
            },
          }) : {
            eficiencia_energetica_industria_mineria: {
              enabled: false,
            },
          }
          onChange({
            ...settings,
            ...newValue,
          });
          setEficienciaMinVisible(value);
        }}
        visible={eficienciaMinVisible}
        enabled={settings.eficiencia_energetica_industria_mineria.enabled}
        toggleVisible={() => {
          setEficienciaMinVisible(!eficienciaMinVisible);
        }}
        help={<EficienciaMineriaHelp />}
      />
      {
        settings.eficiencia_energetica_industria_mineria.enabled && eficienciaMinVisible && (
          <Settings.Options>
            <label>
              Trayectoria de Eficiencia Energética en Industria y Minería
            </label>
            <Slider
              value={selectedTrayectoriaEficienciaMin}
              min={1}
              max={2}
              step={1}
              onChange={(e, value) => {
                onChange({
                  ...settings,
                  eficiencia_energetica_industria_mineria: {
                    ...settings.eficiencia_energetica_industria_mineria,
                    trayectoria: value,
                  },
                });
              }}
            />
            <SliderLegend
              options={[
                {
                  label: 'Tray. 1',
                  value: 1,
                },
                {
                  label: 'Tray. 2',
                  value: 2,
                },
              ]}
              onChange={(value) => {
                onChange({
                  ...settings,
                  eficiencia_energetica_industria_mineria: {
                    ...settings.eficiencia_energetica_industria_mineria,
                    trayectoria: value,
                  },
                })
              }}
            />
          </Settings.Options>
        )
      }
      <Checkbox
        id="reglamentacion_termica_viviendas"
        label="Mejoras en la reglamentación térmica de viviendas"
        checked={settings.reglamentacion_termica_viviendas.enabled}
        onChange={(e, value) => {
          const newValue = value ? ({
            reglamentacion_termica_viviendas: {
              ...settings.reglamentacion_termica_viviendas,
              enabled: true,
              trayectoria: selectedTrayectoriaRegTerViv,
            },
          }) : {
            reglamentacion_termica_viviendas: {
              enabled: false,
            },
          }
          onChange({
            ...settings,
            ...newValue,
          });
          setRegTerVivVisible(value); 
        }}
        visible={regTerVivVisible}
        enabled={settings.reglamentacion_termica_viviendas.enabled}
        toggleVisible={() => {
          setRegTerVivVisible(!regTerVivVisible);
        }}
        help={<ReglamentacionTermicaViviendasHelp />} //reglamentacion_termica_viviendas.Help
      />
      {
        settings.reglamentacion_termica_viviendas.enabled && regTerVivVisible && (
          <Settings.Options>
            <label>
              Trayectoria de Mejoras en la reglamentación térmica de viviendas
            </label>
            <Slider
              value={selectedTrayectoriaRegTerViv}
              min={1}
              max={2}
              step={1}
              onChange={(e, value) => {
                onChange({
                  ...settings,
                  reglamentacion_termica_viviendas: {
                    ...settings.reglamentacion_termica_viviendas,
                    trayectoria: value,
                  },
                })
              }}
            />
            <SliderLegend
              options={[
                {
                  label: 'Tray. 1',
                  value: 1,
                },
                {
                  label: 'Tray. 2',
                  value: 2,
                },
              ]}
              onChange={(value) => {
                onChange({
                  ...settings,
                  reglamentacion_termica_viviendas: {
                    ...settings.reglamentacion_termica_viviendas,
                    trayectoria: value,
                  },
                })
              }}
            />
          </Settings.Options>
        )
      }
      <Checkbox
        id="transporte_publico"
        label="Fomento al transporte público"
        checked={settings.transporte_publico.enabled}
        onChange={(e, value) => {
          const newValue = value ? ({
            transporte_publico: {
              ...settings.transporte_publico,
              enabled: true,
              trayectoria: selectedTrayectoriaTraPub,
            },
          }) : {
            transporte_publico: {
              enabled: false,
            },
          }
          onChange({
            ...settings,
            ...newValue,
          });
          setTraPubVisible(value); 
        }}
        visible={traPubVisible}
        enabled={settings.transporte_publico.enabled}
        toggleVisible={() => {
          setTraPubVisible(!traPubVisible);
        }}
        help={<TransportePublicoHelp />} //transporte_publico.Help
      />
      {
        settings.transporte_publico.enabled && traPubVisible && (
          <Settings.Options>
            <label>
              Trayectoria de Fomento al transporte público
            </label>
            <Slider
              value={selectedTrayectoriaTraPub}
              min={1}
              max={2}
              step={1}
              onChange={(e, value) => {
                onChange({
                  ...settings,
                  transporte_publico: {
                    ...settings.transporte_publico,
                    trayectoria: value,
                  },
                })
              }}
            />
            <SliderLegend
              options={[
                {
                  label: 'Tray. 1',
                  value: 1,
                },
                {
                  label: 'Tray. 2',
                  value: 2,
                },
              ]}
              onChange={(value) => {
                onChange({
                  ...settings,
                  transporte_publico: {
                    ...settings.transporte_publico,
                    trayectoria: value,
                  },
                })
              }}
            />
          </Settings.Options>
        )
      }
      <Checkbox
        id="transporte_no_motorizado"
        label="Transporte no motorizado"
        checked={settings.transporte_no_motorizado.enabled}
        onChange={(e, value) => {
          const newValue = value ? ({
            transporte_no_motorizado: {
              ...settings.transporte_no_motorizado,
              enabled: true,
              trayectoria: selectedTrayectoriaTraNoMot,
            },
          }) : {
            transporte_no_motorizado: {
              enabled: false,
            },
          }
          onChange({
            ...settings,
            ...newValue,
          });
          setTraNoMotVisible(value); 
        }}
        visible={traNoMotVisible}
        enabled={settings.transporte_no_motorizado.enabled}
        toggleVisible={() => {
          setTraNoMotVisible(!traNoMotVisible);
        }}
        help={<TransporteNoMotorizadoHelp />} //transporte_no_motorizado.Help
      />
      {
        settings.transporte_no_motorizado.enabled && traNoMotVisible && (
          <Settings.Options>
            <label>
              Trayectoria de Transporte no motorizado
            </label>
            <Slider
              value={selectedTrayectoriaTraNoMot}
              min={1}
              max={2}
              step={1}
              onChange={(e, value) => {
                onChange({
                  ...settings,
                  transporte_no_motorizado: {
                    ...settings.transporte_no_motorizado,
                    trayectoria: value,
                  },
                })
              }}
            />
            <SliderLegend
              options={[
                {
                  label: 'Tray. 1',
                  value: 1,
                },
                {
                  label: 'Tray. 2',
                  value: 2,
                },
              ]}
              onChange={(value) => {
                onChange({
                  ...settings,
                  transporte_no_motorizado: {
                    ...settings.transporte_no_motorizado,
                    trayectoria: value,
                  },
                })
              }}
            />
          </Settings.Options>
        )
      }
      <Checkbox
        id="teletrabajo"
        label="Teletrabajo"
        checked={settings.teletrabajo.enabled}
        onChange={(e, value) => {
          const newValue = value ? ({
            teletrabajo: {
              ...settings.teletrabajo,
              enabled: true,
              trayectoria: selectedTrayectoriaTel,
            },
          }) : {
            teletrabajo: {
              enabled: false,
            },
          }
          onChange({
            ...settings,
            ...newValue,
          });
          setTelVisible(value); 
        }}
        visible={telVisible}
        enabled={settings.teletrabajo.enabled}
        toggleVisible={() => {
          setTelVisible(!telVisible);
        }}
        help={<TeletrabajoHelp />} //teletrabajo.Help
      />
      {
        settings.teletrabajo.enabled && telVisible && (
          <Settings.Options>
            <label>
              Trayectoria de Teletrabajo
            </label>
            <Slider
              value={selectedTrayectoriaTel}
              min={1}
              max={2}
              step={1}
              onChange={(e, value) => {
                onChange({
                  ...settings,
                  teletrabajo: {
                    ...settings.teletrabajo,
                    trayectoria: value,
                  },
                })
              }}
            />
            <SliderLegend
              options={[
                {
                  label: 'Tray. 1',
                  value: 1,
                },
                {
                  label: 'Tray. 2',
                  value: 2,
                },
              ]}
              onChange={(value) => {
                onChange({
                  ...settings,
                  teletrabajo: {
                    ...settings.teletrabajo,
                    trayectoria: value,
                  },
                })
              }}
            />
          </Settings.Options>
        )
      }
      <Checkbox
        id="electromovilidad"
        label="Electromovilidad vehículos livianos"
        checked={settings.electromovilidad.enabled}
        onChange={(e, value) => {
          const newValue = value ? ({
            electromovilidad: {
              ...settings.electromovilidad,
              enabled: true,
              trayectoria: selectedTrayectoriaElectromovilidad,
            },
          }) : {
            electromovilidad: {
              enabled: false,
            },
          }
          onChange({
            ...settings,
            ...newValue,
          });
          setElectromovilidadVisible(value);
        }}
        toggleVisible={() => {
          setElectromovilidadVisible(!electromovilidadVisible);
        }}
        enabled={settings.electromovilidad.enabled}
        visible={electromovilidadVisible}
        help={<ElectromovilidadLivianoHelp />}
      />
      {
        settings.electromovilidad.enabled && electromovilidadVisible && (
          <Settings.Options>
            <Help title={(
                <label>
                  Trayectoria de electromovilidad
                </label>
              )}>
                <SmallChart
                  id="tray_electromovilidad"
                  info="Porcentaje de participación de  vehículos eléctricos"
                  data={trayectoriasElectromovilidad.find(item => item.value === selectedTrayectoriaElectromovilidad)}
                />
            </Help>
            <Slider
              value={selectedTrayectoriaElectromovilidad}
              min={1}
              max={3}
              step={1}
              onChange={(e, value) => {
                onChange({
                  ...settings,
                  electromovilidad: {
                    ...settings.electromovilidad,
                    trayectoria: value,
                  },
                })
              }}
            />
            <SliderLegend
              options={[
                {
                  label: 'Tray. 1',
                  value: 1,
                },
                {
                  label: 'Tray. 2',
                  value: 2,
                },
                {
                  label: 'Tray. 3',
                  value: 3,
                },
              ]}
              onChange={(value) => {
                onChange({
                  ...settings,
                  electromovilidad: {
                    ...settings.electromovilidad,
                    trayectoria: value,
                  },
                })
              }}
            />
          </Settings.Options>
        )
      }
      <Checkbox
        id="electromovilidad_taxis_y_colectivos"
        label="Electromovilidad en taxis y colectivos"
        checked={settings.electromovilidad_taxis_y_colectivos.enabled}
        onChange={(e, value) => {
          const newValue = value ? ({
            electromovilidad_taxis_y_colectivos: {
              ...settings.electromovilidad_taxis_y_colectivos,
              enabled: true,
              trayectoria: selectedTrayectoriaEleTaxCol,
            },
          }) : {
            electromovilidad_taxis_y_colectivos: {
              enabled: false,
            },
          }
          onChange({
            ...settings,
            ...newValue,
          });
          setEleTaxColVisible(value); 
        }}
        visible={eleTaxColVisible}
        enabled={settings.electromovilidad_taxis_y_colectivos.enabled}
        toggleVisible={() => {
          setEleTaxColVisible(!eleTaxColVisible);
        }}
        help={<ElectromovilidadTaxisColectivosHelp />} //electromovilidad_taxis_y_colectivos.Help
      />
      {
        settings.electromovilidad_taxis_y_colectivos.enabled && eleTaxColVisible && (
          <Settings.Options>
            <label>
              Trayectoria de Electromovilidad en taxis y colectivos
            </label>
            <Slider
              value={selectedTrayectoriaEleTaxCol}
              min={1}
              max={2}
              step={1}
              onChange={(e, value) => {
                onChange({
                  ...settings,
                  electromovilidad_taxis_y_colectivos: {
                    ...settings.electromovilidad_taxis_y_colectivos,
                    trayectoria: value,
                  },
                })
              }}
            />
            <SliderLegend
              options={[
                {
                  label: 'Tray. 1',
                  value: 1,
                },
                {
                  label: 'Tray. 2',
                  value: 2,
                },
              ]}
              onChange={(value) => {
                onChange({
                  ...settings,
                  electromovilidad_taxis_y_colectivos: {
                    ...settings.electromovilidad_taxis_y_colectivos,
                    trayectoria: value,
                  },
                })
              }}
            />
          </Settings.Options>
        )
      }
       <Checkbox
        id="electromovilidad_buses"
        label="Electromovilidad en buses"
        checked={settings.electromovilidad_buses.enabled}
        onChange={(e, value) => {
          const newValue = value ? ({
            electromovilidad_buses: {
              ...settings.electromovilidad_buses,
              enabled: true,
              trayectoria: selectedTrayectoriaEleBus,
            },
          }) : {
            electromovilidad_buses: {
              enabled: false,
            },
          }
          onChange({
            ...settings,
            ...newValue,
          });
          setEleBusVisible(value);
        }}
        toggleVisible={() => {
          setEleBusVisible(!eleBusVisible);
        }}
        enabled={settings.electromovilidad_buses.enabled}
        visible={eleBusVisible}
        help={<ElectromovilidadBusesHelp />}
      />
      {
        settings.electromovilidad_buses.enabled && eleBusVisible && (
          <Settings.Options>
            <Help title={(
              <label>
                Trayectoria introducción de buses electricos
              </label>
            )}>
              <SmallChart
                id="electromovilidad_buses"
                info="Participacion buses."
              />
            </Help>
            <Slider
              value={selectedTrayectoriaEleBus}
              min={1}
              max={2}
              step={1}
              onChange={(e, value) => {
                onChange({
                  ...settings,
                  electromovilidad_buses: {
                    ...settings.electromovilidad_buses,
                    trayectoria: value,
                  },
                })
              }}
            />
            <SliderLegend
              options={[
                {
                  label: 'Tray. 1',
                  value: 1,
                },
                {
                  label: 'Tray. 2',
                  value: 2,
                },
              ]}
              onChange={(value) => {
                onChange({
                  ...settings,
                  electromovilidad_buses: {
                    ...settings.electromovilidad_buses,
                    trayectoria: value,
                  },
                })
              }}
            />
          </Settings.Options>
        )
      }
      <Checkbox
        id="introduccion_er_baja_tension"
        label="Introducción de energías renovables en baja tensión"
        checked={settings.introduccion_er_baja_tension.enabled}
        onChange={(e, value) => {
          const newValue = value ? ({
            introduccion_er_baja_tension: {
              ...settings.introduccion_er_baja_tension,
              enabled: true,
              trayectoria: selectedTrayectoriaEEBT,
            },
          }) : {
            introduccion_er_baja_tension: {
              enabled: false,
            },
          }
          onChange({
            ...settings,
            ...newValue,
          });
          setEebtvisible(value);
        }}
        toggleVisible={() => {
          setEebtvisible(!eebtVisible);
        }}
        enabled={settings.introduccion_er_baja_tension.enabled}
        visible={eebtVisible}
        help={<ERBajaTensionHelp />}
      />
      {
        settings.introduccion_er_baja_tension.enabled && eebtVisible && (
          <Settings.Options>
            <Help title={(
              <label>
                Trayectoria de Introducción Energías Renovables en baja Tensión
              </label>
            )}>
              <SmallChart
                id="introduccion_er_baja_tension"
                info="Potencia instalada paneles solares fotovoltaicos."
              />
            </Help>
            <Slider
              value={selectedTrayectoriaEEBT}
              min={1}
              max={2}
              step={1}
              onChange={(e, value) => {
                onChange({
                  ...settings,
                  introduccion_er_baja_tension: {
                    ...settings.introduccion_er_baja_tension,
                    trayectoria: value,
                  },
                })
              }}
            />
            <SliderLegend
              options={[
                {
                  label: 'Tray. 1',
                  value: 1,
                },
                {
                  label: 'Tray. 2',
                  value: 2,
                },
              ]}
              onChange={(value) => {
                onChange({
                  ...settings,
                  introduccion_er_baja_tension: {
                    ...settings.introduccion_er_baja_tension,
                    trayectoria: value,
                  },
                })
              }}
            />
          </Settings.Options>
        )
      }
      <Checkbox
        id="retiro_centrales_carbon"
        label="Retiro de centrales a carbón"
        checked={settings.retiro_centrales_carbon.enabled}
        onChange={(e, value) => {
          const newValue = value ? ({
            retiro_centrales_carbon: {
              ...settings.retiro_centrales_carbon,
              enabled: true,
              trayectoria: selectedTrayectoriaRetiroCarbon,
            },
          }) : {
            retiro_centrales_carbon: {
              enabled: false,
            },
          }
          onChange({
            ...settings,
            ...newValue,
          });
          setRetiroVisible(value);
        }}
        toggleVisible={() => {
          setRetiroVisible(!retiroVisible);
        }}
        enabled={settings.retiro_centrales_carbon.enabled}
        visible={retiroVisible}
        help={<RetiroCentralesHelp />}
      />
      {
        settings.retiro_centrales_carbon.enabled && retiroVisible && (
          <Settings.Options>
            <Help title={(
                <label>
                  Trayectoria de retiro de centrales
                </label>
              )}>
                <SmallChart
                  id="tray_retiro_centrales"
                  info="Potencia instalada centrales a carbón."
                  data={trayectoriasRetiroCarbon.find(item => item.value === selectedTrayectoriaRetiroCarbon)}
                />
            </Help>
            <Slider
              value={selectedTrayectoriaRetiroCarbon}
              min={1}
              max={2}
              step={1}
              onChange={(e, value) => {
                onChange({
                  ...settings,
                  retiro_centrales_carbon: {
                    ...settings.retiro_centrales_carbon,
                    trayectoria: value,
                  },
                })
              }}
            />
            <SliderLegend
              options={[
                {
                  label: 'Tray. 1',
                  value: 1,
                },
                {
                  label: 'Tray. 2',
                  value: 2,
                },
              ]}
              onChange={(value) => {
                onChange({
                  ...settings,
                  retiro_centrales_carbon: {
                    ...settings.retiro_centrales_carbon,
                    trayectoria: value,
                  },
                })
              }}
            />
          </Settings.Options>
        )
      }
      
     
      
      
      
      
    </Settings.Panel.Correct>
  );
}

EnergeticPolicies.propTypes = {
  onChange: func,
  settings: object,
};

export default EnergeticPolicies;
