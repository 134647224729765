import React from 'react';
import HelpContent from './HelpContent';
import preciosPaneles from 'img/precios_paneles.png';
import evolucionInstalaciones from 'img/evolucion_instalaciones.png';

const ERBajaTensionHelp = () => (
  <HelpContent>
    <h1>Introducción de energías renovables en baja tensión</h1>
    <p>
      Esta medida consiste en la introducción de energía renovable en sistemas de distribución en
      baja tensión. En particular, se considera la introducción de paneles fotovoltaicos en viviendas
      para reemplazar su consumo eléctrico.
      La reducción del consumo eléctrico a nivel residencial implica una reducción de la proyección
      de la demanda eléctrica del sistema reduciendo y, a su vez, una reducción de las emisiones de GEI del sector
      eléctrico nacional.
    </p>
    <p>
    Los supuestos de la trayectoria 1 son los siguientes:
    </p>
    <p>    
    Entrada paulatina de energía solar fotovoltaica para autoconsumo en el sector residencial hasta alcanzar una capacidad instalada de 1171 MW el año 2050.
    </p>
    <p>    
    Entrada paulatina de energía solar fotovoltaica para autoconsumo en el sector comercial hasta alcanzar una capacidad instalada de 4849 MW el año 2050.
    </p>
    
    <p>  
    Los supuestos de la trayectoria 2 son los siguientes:
    </p>
    <p>  
    Entrada paulatina de energía solar fotovoltaica para autoconsumo en el sector residencial hasta alcanzar una capacidad instalada de 7000 MW el año 2050.
    </p>
  </HelpContent>
);

export default ERBajaTensionHelp;
