import React from 'react';
import HelpContent from './HelpContent';
import preciosBaterias from 'img/precios_baterias.png';

const ElectromovilidadLivianoHelp = () => (
  <HelpContent>
    <h1>Electromovilidad en vehículos livianos</h1>
    <p>
      Esta medida consiste en la introducción de vehículos eléctricos en reemplazo de los vehículos livianos de pasajeros y vehículos comerciales.
    </p>
    
    <p>
    Los supuestos de la trayectoria 1 son los siguientes:
    </p>
    <p>    
      Se alcanza una participación de 40% de vehículos eléctricos y un 20% de vehículo híbridos al año 2050. 
    </p>
    <p>
    Los supuestos de la trayectoria 2 son los siguientes:
    </p>  
    <p>
      Se alcanza una participación de 60% de vehículos eléctricos al 2050. 
    </p>
    <p>
    Los supuestos de la trayectoria 3 son los siguientes:
    </p>  
    <p>
      Se alcanza una participación de 85% de vehículos eléctricos al 2050. 
    </p>




  </HelpContent>
);

export default ElectromovilidadLivianoHelp;
