import React from 'react';
import HelpContent from './HelpContent';
import preciosBaterias from 'img/precios_baterias.png';
import electromovilidad_buses from 'img/electromovilidad_buses.png';

const ElectromovilidadBusesHelp = () => (
  <HelpContent>
    <h1>Electromovilidad en buses del transporte público</h1>
    <p>
      La electromovilidad es un término general utilizado para describir la utilización de vehículos
      impulsados por motores eléctricos. La electrificación del transporte es vista comúnmente
      como una medida clave para reducir las emisiones de gases de efecto invernadero del sector
      transporte.
    </p>
    <p>
      Esta medida tiene como objetivo el reemplazo de los buses del transporte público que utilizan diésel por 
      buses eléctricos.
    </p>

    <img src={electromovilidad_buses} alt="Electromovilidad transporte público" />
    <label>Figura: Electromovilidad buses </label>

    <p>
    Los supuestos de la trayectoria 1 son los siguientes:
    </p>
    <p>    
      Se alcanza una participación de 100% de buses del transporte público urbano al 2040. 
    </p>
    <p>
    Los supuestos de la trayectoria 2 son los siguientes:
    </p>  
    <p>
    Se alcanza una participación de 100% de buses del transporte público urbano al 2035.
    </p>
  </HelpContent>
);

export default ElectromovilidadBusesHelp;
