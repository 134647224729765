export const SECTORES  = [
  {
    label: 'Generación eléctrica',
    value: 'generacion_electrica',
  },
  {
    label: 'Minería',
    value: 'mineria',
  },
  {
    label: 'Industria',
    value: 'industria',
  },
  {
    label: 'Comercial',
    value: 'comercial',
  },
  {
    label: 'Público',
    value: 'publico',
  },
  {
    label: 'Residencial',
    value: 'residencial',
  },
  {
    label: 'Transporte',
    value: 'transporte',
  },
];

export const UMBRALES = [
  {
    label: '0 MW',
    value: 0,
  },
  {
    label: '50 MW',
    value: 50,
  },
  {
    label: '100 MW',
    value: 100,
  },
];

export const OFFSETS = [
  {
    label: '0 % del cap',
    value: 0,
  },
  {
    label: '10 % del cap',
    value: 10,
  },
  {
    label: '20 % del cap',
    value: 20,
  },
];

export const YEAR_CATEGORY = 'año';
export const ESCENARIO_BASE = 'Escenario Base';
export const ESCENARIO_BASE_LOWER = 'escenario base';
export const BASE = 'base';
export const MIN_YEAR = 2020;
export const MAX_YEAR = 2050;
export const ELECTRICIDAD = 'electricidad';
export const FECHA_ACTUALIZACION = '5 de mayo de 2024';
export const COLORS = [
  'rgba(244, 67, 54, 1)',
  'rgba(233, 30, 99, 1)',
  'rgba(156, 39, 176, 1)',
  'rgba(103, 58, 183, 1)',
  'rgba(63, 81, 181, 1)',
  'rgba(33, 150, 243, 1)',
  'rgba(3, 169, 244, 1)',
  'rgba(0, 188, 212, 1)',
  'rgba(0, 150, 136, 1)',
  'rgba(76, 175, 80, 1)',
  'rgba(139, 195, 74, 1)',
  'rgba(205, 220, 57, 1)',
  'rgba(255, 235, 59, 1)',
  'rgba(255, 193, 7, 1)',
  'rgba(255, 152, 0, 1)',
  'rgba(255, 87, 34, 1)',
  'rgba(121, 85, 72, 1)',
  'rgba(158, 158, 158, 1)',
  'rgba(96, 125, 139, 1)',

  'rgba(244, 67, 54, 1)',
  'rgba(233, 30, 99, 1)',
  'rgba(156, 39, 176, 1)',
  'rgba(103, 58, 183, 1)',
  'rgba(63, 81, 181, 1)',
  'rgba(33, 150, 243, 1)',
];

export const NATURAL_SOURCES = [
  'biomasa',
  'eolica',
  'geotermia',
  'hidraulica embalse',
  'hidraulica pasada',
  'hidraulica serie',
  'solar csp',
  'solar fv',
];

export const POLICIES = [
  {
  "id": "introduccion_hidrogeno_mineria",
  "label": "Hidrógeno en transporte minero"
},{
  "id": "hidrogeno_camiones",
  "label": "Hidrógeno en camiones de transporte carga por carreteras"
},{
  "id": "hidrogeno_aereo",
  "label": "Hidrógeno en transporte aéreo"
},{
  "id": "hidrogeno_maritimo",
  "label": "Hidrógeno en transporte marítimo"
},{
  "id": "hidrogeno_motrices",
  "label": "Hidrógeno para usos motrices en industria y minería"
},{
  "id": "hidrogeno_termico",
  "label": "Hidrógeno para usos térmicos en industria y minería"
},{
  "id": "reemplazo_combustibles_procesos_term",
  "label": "Reemplazo de combustibles fósiles por gas natural en procesos térmicos"
},{
  "id": "introduccion_energias_renovables",
  "label": "Sistemas solares térmicos en industria y minería"
},{
  "id": "solares_residencial",
  "label": "Sistemas solares térmicos en sector residencial"
},{
  "id": "electrificacion_industria_mineria",
  "label": "Electrificación de usos motrices en industria y minería"
},{
  "id": "electrificacion_calefaccion_residencial",
  "label": "Electrificación de calefacción en sector residencial"
},{
  "id": "electrificacion_coccion_mineria",
  "label": "Electrificación de cocción en sector residencial"
},{
  "id": "electrificacion_final_comercial",
  "label": "Electrificación de usos finales en sector comercial"
},{
  "id": "eficiencia_energetica_transporte",
  "label": "Eficiencia energética en transporte"
},{
  "id": "eficiencia_energetica_industria_mineria",
  "label": "Eficiencia energética en industria y minería"
},{
  "id": "reglamentacion_termica_viviendas",
  "label": "Mejoras en la reglamentación térmica de viviendas"
},{
  "id": "transporte_publico",
  "label": "Fomento al transporte público"
},{
  "id": "transporte_no_motorizado",
  "label": "Transporte no motorizado"
},{
  "id": "teletrabajo",
  "label": "Teletrabajo"
},{
  "id": "electromovilidad",
  "label": "Electromovilidad vehículos livianos"
},{
  "id": "electromovilidad_taxis_y_colectivos",
  "label": "Electromovilidad en taxis y colectivos"
},{
  "id": "electromovilidad_buses",
  "label": "Electromovilidad en buses"
},{
  "id": "introduccion_er_baja_tension",
  "label": "Introducción de energías renovables en baja tensión"
},{
  "id": "retiro_centrales_carbon",
  "label": "Retiro de centrales a carbón"
}]
export const INSTRUMENTS = [
  {
    id:"impuesto_carbono",
    label:"Impuesto al carbono"
  },
  {
    id:"permisos_transables",
    label:"Sistema de permisos transables"
  }
]