import React from 'react';
import HelpContent from './HelpContent';
//import proyeccionParticipacion from 'img/proyeccion_participacion.png';

const ElectrificacionFinalComercialHelp = () => (
  <HelpContent>
   <h1>Electrificación de usos finales del sector comercial</h1>
   <p>
    Esta medida evalua el impacto de la electrificación de los usos finales del sector comercial.
    </p>
    <p>
    Trayetoria 1: El 72% de la energía consumida en el sector comercial proviene de electricidad.
    </p>
    <p>
    Trayetoria 2: El 90% de la energía consumida en el sector comercial proviene de electricidad.
    </p>
  </HelpContent>
);

export default ElectrificacionFinalComercialHelp;
