import BigNumber from './layout/bigNumber';
import Fact from './layout/fact';
import Wrapper from './layout/wrapper';
import React from 'react';


const Generacion = ({base, año, newValue}) => {
return (
    <Wrapper>
      <Fact>
        <h4>
          La participación de la energia renovable en el año {año} es de
        </h4>
        <BigNumber>
          <h1>
            { base } %
          </h1>
        </BigNumber>
      </Fact>
      <Fact>
        <h4>
          La participación de centrales a carbón en el año {año} es de
        </h4>
        <BigNumber>
          <h1>
            { newValue } %
          </h1>
        </BigNumber>
      </Fact>
    </Wrapper>
  );
}

export default Generacion;