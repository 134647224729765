import React from 'react';
import cadenaHidrogeno from 'img/cadena_hidrogeno.png';
import HelpContent from './HelpContent';

/*
 * Ayuda Reemplazo de combustibles fósiles por gas natural en procesos térmicos
 */

const IntroReemplazoCombuHelp = () => (
  <HelpContent>
    <h1>Reemplazo de combustibles fósiles por gas natural en procesos térmicos</h1>
    <p>
    Esta medida tiene el objetivo de reducir emisiones de GEI mediante el reemplazo de combustibles altos en emisiones 
    por gas natural. Esta medida aplica a todos los procesos térmicos incluyendo calderas y hornos del sector industrial y minero. 
    </p>
    <p>
      Trayectoria 1: Se reemplazan combustibles fósiles hasta alcanzar un 4% de gas natural en usos térmicos.
    </p>
    <p>
      Trayectoria 2: Se reemplazan combustibles fósiles hasta alcanzar un 6% de gas natural en usos térmicos.
    </p>
  </HelpContent>
);

export default IntroReemplazoCombuHelp;
