import React, { useState } from 'react';
import styled from 'styled-components';
import ModeSelection from './ModeSelection';
import Chart from './Chart';
import { chartSettings } from 'config';

const Wrapper = styled.div`
  position: relative;
  ${props => props.index > 0 && 'margin-top: 10px;'}
`;

const Remove = styled.div`
  cursor: pointer;
  text-decoration: underline;
  color: #333;
  font-size: 12px;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const CheckboxWrap = styled.div`
  font-size: 12px;
  z-index: 99;
  padding: 0 0 10px 5px;
  color: var(--dark-grey-color);

  label {
    cursor: pointer;
  }
`;

const ChartMode = ({
  index,
  onHide,
  currentScenario,
  settings,
}) => {
  // Modo del gráfico: Por ejemplo: Emisiones, generacion etc,.
  const [mode, setMode] = useState(chartSettings[0].id);
  // Multiple or single scenario
  const [multipleScenarios, setMultipleScenarios] = useState(false);

  // Obtener opciones de configuración del gráfico actual
  const currentChartSettings = chartSettings.find(item => item.id === mode);

  // get dataset path based on current scenario
  const datasetPath = currentScenario ? currentScenario.datasets[mode] : null;

  const handleCheckbox = () => {
    setMultipleScenarios(!multipleScenarios);
  }
  return (
    <Wrapper index={index}>
      <ModeSelection
        mode={mode}
        onChange={value => {
          setMode(value);
          setMultipleScenarios(false);
        }}
        prepend={
          onHide && (
            <Remove onClick={onHide}>
              Ocultar
            </Remove>
          )
        }
      />
      {
        currentChartSettings.multipleEnabled && (
          <CheckboxWrap>
            <label htmlFor={`check` + index}>Ver multiples escenarios</label>{' '}
            <input id={`check` + index} type="checkbox" checked={multipleScenarios} onChange={handleCheckbox} />
          </CheckboxWrap>
        )
      }
      {
        multipleScenarios ? (
          <Chart
            id={`multiple_scenario_container` + index}
            mode={mode}
            currentScenario={currentScenario}
            datasetPath={`./datasets/${mode}/Escenarios.csv`}
            chartSettings={currentChartSettings}
            isMultiscenario
            settings={settings}
          />
        ) : (
          <Chart
            id={`chart_container` + index}
            mode={mode}
            currentScenario={currentScenario}
            datasetPath={datasetPath}
            chartSettings={currentChartSettings}
            showKPI={currentChartSettings ? true: false}
            allowDownloads
            settings={settings}
          />
        )
      }
    </Wrapper>
  )
};

export default ChartMode;
