import React from 'react';
import HelpContent from './HelpContent';
import HidrogenoAviones from 'img/avion_hidrogeno.PNG';

const HidrogenoAereoHelp = () => (
  <HelpContent>
    <h1>Introducción de hidrógeno en transporte aéreo</h1>
    <p>
    Esta medida evalúa el impacto de la introducción de hidrógeno en el transporte aéreo nacional. 
    La siguiente figura muestra un prototipo de avión comercial que funcionaría con hidrógeno, el cual se encuentra
    en desarrollo por la empresa AirBus.
    </p>
	<img src={HidrogenoAviones} alt="Hidrógeno en transporte aéreo" />
    <label>Figura: Avión a hidrógeno AirbusZEROe. Fuente: Airbus https://www.airbus.com/newsroom/press-releases/en/2020/09/airbus-reveals-new-zeroemission-concept-aircraft.html  </label>
    
    <p>
      La trayectoría 1 considera que el 50% de los aviones utilizará hidrógeno verde hacia el año 2050.
    </p>
    <p>
      La trayectoría 2 considera que el 100% de los aviones utilizará hidrógeno verde hacia el año 2050.
    </p>
  </HelpContent>
);

export default HidrogenoAereoHelp;
