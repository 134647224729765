import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Dashboard from 'components/pages/Dashboard';
import AcercaDe from 'components/pages/AcercaDe';
import defaultTheme from 'utils/theming';
import { Route, Switch, HashRouter } from "react-router-dom";

const theme = createMuiTheme(defaultTheme);

/**
 * Principal contenedor de la aplicación.
 * Utiliza react router para la configuración de las rutas
 */
function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <HashRouter>
      <Switch>
        <Route path="/acercade">
          <AcercaDe/>
        </Route>
        <Route path="/">
          <Dashboard/>
        </Route>
      </Switch>
      </HashRouter>
    </MuiThemeProvider>
  );
}

export default App;
