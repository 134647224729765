import React from 'react';
import HelpContent from './HelpContent';
import retiro_carbon from 'img/retiro_carbon.png';

const RetiroCentralesHelp = () => (
  <HelpContent>
    <h1>Retiro de centrales a carbón</h1>
    <p>
      El retiro de centrales a carbón consiste en la desconexión de todas aquellas centrales eléctricas
      que utilicen carbón como fuente primaria de energía. Este retiro puede obedecer a razones
      técnicas, económicas, ambientales y tecnológicas.
    </p>
    <p>
      En enero del 2018, el Gobierno de Chile y las empresas socias de la Asociación de Generadoras
      de Chile, AES Gener, Colbun, Enel y Engie acordaron no iniciar nuevos proyectos a carbón que
      no cuenten con sistemas de captura y almacenamiento de carbono (CCS). Asimismo,
      acordaron establecer un cronograma y las condiciones para el cese programado de las
      centrales a carbón en operación que no cuenten con sistemas de captura y almacenamiento
      de carbono u otras tecnologías equivalentes.
    </p>

    <img src={retiro_carbon} alt="Retiro de centrales a carbón" />
    <label>Figura: Central a carbón</label>

    <p>
    Los supuestos de modelación son los siguientes:
    </p>
    <p>
      Trayectoria 1: El retiro de todas las centrales a carbón se alcanza en el año 2040.
    </p>
    <p>
      Trayectoria 2: El retiro de todas las centrales a carbón se alcanza en el año 2030.
    </p>
  </HelpContent>
);

export default RetiroCentralesHelp;
