import React from 'react';
import HelpContent from './HelpContent';
import standardRendimiento from 'img/standard_rendimiento.png';
import proyeccionRendimiento from 'img/proyeccion_rendimiento.png';

const EficienciaTransporteHelp = () => (
  <HelpContent>
    <h1>
      Eficiencia energética en transporte
    </h1>
    <p>
      Los estándares de rendimiento energético en el sector transporte son una herramienta de
      política pública mediante la cual se fijan valores de rendimiento (medido en km/lge 1 o Lge/100
      km) mínimo para el promedio anual de vehículos vendidos por fabricantes o importadores en
      el territorio. 
    </p>
    <p>
      La aplicación de estándares de rendimiento (o equivalentemente de emisiones de CO<sub>2</sub> ) son una
      herramienta ampliamente utilizada por distintos países en el mundo, y está orientada a
      mejorar el rendimiento de los vehículos nuevos vendidos en el respectivo territorio,
      reduciendo de esta forma el consumo de combustibles fósiles y por consecuencia las
      emisiones de gases de efecto invernadero del sector. Dependiendo el país y el enfoque que
      éstos apliquen, pueden ser medidos en km/L o gCO<sub></sub>2 /km y utilizar distintos descriptores, sin
      embargo, los efectos globales son similares.
    </p>
    <p>
      Esta clase de estándares son aplicados en forma diferenciada a las distintas categorías de
      vehículos, siendo los vehículos livianos de pasajeros los primeros sobre los cuales suelen
      establecerse estándares de rendimiento energético. El rendimiento promedio (mixto)
      perteneciente a este segmento en Chile durante el 2018 fue de 15,34 km/l. De no aplicarse
      estándares, los rendimientos crecerán marginalmente, alejándose del rendimiento de países
      que los han implementado. 
    </p>
    <p>
      Si bien se espera que en una primera etapa los estándares sean aplicados a vehículos livianos
      de pasajeros, se espera que en el futuro otros estándares sean aplicados a las categorías de
      medianos y pesados.
    </p>
    <p>
    Las medidas son evaluadas considerando las siguientes supuestos:
    </p>
    <p>
    Trayectoria 1: En ésta se establecen únicamente metas de rendimiento energético para vehículos livianos de pasajeros (LDV) de acuerdo a:
    </p>
    <p>
      •	2021 – 2026 valor meta de 14 [km/lge]
      </p>
    <p>  
      •	2027 – 2050 valor meta de 21 [km/lge]
    </p>
    <p>
    Trayectoria 2: Para vehículos livianos de pasajeros se proponen metas de rendimiento equivalentes a los promulgados por la Unión Europea con desfase de 10 años:
    </p>
    <p>
    Metas de rendimiento para vehículos livianos de pasajeros (LDV):
    </p>
    <p>
      •	2021 – 2024 valor meta de 14 [km/lge] 
    </p>
    <p>  
      •	2025 – 2030 valor meta de 18.0 [km/lge] (130 gCO2/km promulgado)
    </p>
    <p>  
    •	2031 – 2050 Valor meta de 24.6 [km/lge] (95 gCO2/km promulgado)
    </p>
    <p>
    Metas de rendimiento para vehículos medianos:
    </p>
    <p>
      •	2024 – 2027 valor meta de 13 [km/lge]
    </p>
    <p>  
      •	2028 – 2034 valor meta de 15 [km/lge]
    </p>
    <p>  
      •	2035 – 2050 valor meta de 20 [km/lge]
    </p>
    <p>
    Trayectoria 3: Para vehículos livianos de pasajeros se proponen metas de rendimiento equivalentes a los promulgados y propuestos por la Unión Europea con desfase aproximado de 5 años.
    </p>
    <p>
    Metas de rendimiento para vehículos livianos de pasajeros (LDV):
    </p>
    <p>
      •	2021 – 2025 valor meta de 18.0 [km/lge] (130 gCO2/km promulgado)
    </p>
    <p>  
      •	2026 – 2034 valor meta de 24.6 [km/lge] (95 gCO2/km promulgado)
    </p>
    <p>  
    •	2035 – 2050 Valor meta de 24.6 [km/lge] (67 gCO2/km propuesto)
    </p>
    <p>
    Metas de rendimiento para vehículos medianos:
    </p>
    <p>
      •	2024 – 2027 valor meta de 13 [km/lge]
    </p>
    <p>  
      •	2028 – 2034 valor meta de 15 [km/lge]
    </p>
    <p>  
    •	2035 – 2050 valor meta de 20 [km/lge]
    </p>
    <p>
    Metas de rendimiento para vehículos pesados:
    </p>
    <p>
      •	2027 – 2050 Mejora del 20% respecto a los valores observados en 2018
    </p>
   
  </HelpContent>
);

export default EficienciaTransporteHelp;
