import React from 'react';
import HelpContent from './HelpContent';
import Electrificacion_Coccion_Hogar from 'img/Electrificacion_Coccion_Hogar.png';

const ElectrificacionCoccionMineriaHelp = () => (
  <HelpContent>
   <h1>Electrificación de cocción en hogares</h1>
    <p>
    Esta medida evalúa el impacto de la introducción de cocinas eléctricas que reemplacen el uso de cocinas convencionales que utilizan gas natural 
    o gas licuado. La siguiente figura muestra algunos ejemplos de cocina eléctrica vitrocerámica y una de platos eléctricos.
    </p>
	  <img src={Electrificacion_Coccion_Hogar} alt="Ejemplo de artefactos eléctricos para cocción"/>
    <label>Figura: Electrificación de coccón </label>
    <p>
    La trayectoría 1 considera que el 40% los hogares utilizan un sistema de cocción eléctrica.
    </p>
    <p>
    La trayectoría 2 considera que el 70% los hogares utilizan un sistema de cocción eléctrica.
    </p>
  </HelpContent>
);

export default ElectrificacionCoccionMineriaHelp;
