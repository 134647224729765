export const categoryLabel = (cat) => {
  const labels = {
    // emisiones GEI
    'generacion electrica': 'Generación Eléctrica',
    'industria y mineria': 'Industria y Minería',
    publico: 'Público',
    'otras industrias energia': 'Otras Industrias Energía',
    'otros sectores': 'Otros Sectores',
    'emisiones fugitivas': 'Emisiones Fugitivas',
    'procesos industriales': 'Procesos Industriales',
    forestal: 'Forestal',

    // demanda energia
    azucar: 'Azúcar',
    industrias_varias: 'Industrias varias',
    minas_varias: 'Minas varias',
    petroquimica: 'Petroquímica',
    siderurgia: 'Siderúrgica',
    transporte_aereo: 'Transporte aéreo',
    transporte_caminero_carga: 'Trans. caminero \ncarga',
    transporte_caminero_pasajeros: 'Trans. caminero \npasajeros',
    transporte_ferroviario_carga: 'Trans. ferroviario \ncarga',
    transporte_ferroviario_pasajeros: 'Trans. ferroviario \npasajeros',
    transporte_maritimo: 'Transporte marítimo',

    // generacion
    carbon: 'Carbón',
    eolica: 'Eólica',
    'hidraulica embalse': 'Hidráulica embalse',
    'hidraulica pasada': 'Hidráulica pasada',
    'hidraulica serie': 'Hidráulica serie',

    // escenarios
    'Escenario Base': 'Escenario Base',
    'Observatorio Carbono Neutralidad': 'Observatorio Carbono Neutralidad',
    'Alcanzando la Carbono Neutralidad': 'Alcanzando la Carbono Neutralidad',
    'Electrificacion de usos motrices en industria y mineria - Minas varias': 'Electrificación de usos motrices en industria y minería - Minas varias',
    'Electrificacion de usos motrices en industria y mineria - Industrias varias': 'Electrificación de usos motrices en industria y minería - Industrias varias',
    'Sistemas solares termicos en industria y mineria': 'Sistemas solares térmicos en industria y minería',
    'Electrificacion de usos motrices en industria y mineria - Sector cobre': 'Electrificación de usos motrices en industria y minería - Sector cobre',
    'Electrificacion de usos finales en sector comercial - Uso motriz': 'Electrificación de usos finales en sector comercial - Uso motriz',
    'Generacion de biogas': 'Generación de biogas',
    'Electrificacion de usos motrices en industria y mineria - Electrificacion termica': 'Electrificación de usos motrices en industria y minería - Electrificación térmica',
    'Hidrogeno en transporte minero': 'Hidrógeno en transporte minero',
    'Hidrogeno en camiones de transporte carga por carreteras': 'Hidrógeno en camiones de transporte carga por carreteras',
    'Electromovilidad en taxis y colectivos': 'Electromovilidad en taxis y colectivos',
    'Electromovilidad vehiculos medianos': 'Electromovilidad vehículos medianos',
    'Electromovilidad en buses region Metropolitana': 'Electromovilidad en buses región Metropolitana',
    'Electromovilidad en buses regiones': 'Electromovilidad en buses regiones',
    'Electromovilidad vehiculos livianos': 'Electromovilidad vehículos livianos',
    'Introduccion de energias renovables en baja tension': 'Introducción de energías renovables en baja tensión',
    'Electrificacion de calefaccion en sector residencial': 'Electrificación de calefacción en sector residencial',
    'Sistemas solares termicos en sector residencial': 'Sistemas solares térmicos en sector residencial',
    'Electrificacion de usos finales en sector comercial - Calefaccion': 'Electrificación de usos finales en sector comercial - Calefacción',
    'Retiro de centrales a carbon': 'Retiro de centrales a carbón',
    'Hidrogeno en transporte aereo': 'Hidrógeno en transporte aéreo',

  };

  return labels[cat]
    ? labels[cat] : cat.charAt(0).toUpperCase() + cat.slice(1);; 
};

export const parseValue = value => ( value ? parseFloat(value.replace(/,/g, '')) : null);

export const truncOneDecimal = (value) => {
  const parts = String(value).split(',');
  if (parts.length > 1) {
    return parts[0] + ',' + parts[1][0];
  }
  return value;
}
export const truncTwoDecimals = (value) => {
  const parts = String(value).split(',');
  if (parts.length > 1) {
    return parts[0] + ',' + parts[1][0-1];
  }
  return value;
}
export const numberToTwoDecimals = (value) => {
  return Number(value).toFixed(2)
}
export const toOneDecimal = (dic) => {
  const new_dict = {};
  for(var x in dic){
      new_dict[x] = Number(dic[x]).toFixed(1)
  }
  return new_dict
}
export const toTwoDecimals = (dic) => {
  const new_dict = {};
  for(var x in dic){
      new_dict[x] = Number(dic[x]).toFixed(2)
  }
  return new_dict
}