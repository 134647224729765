import React,  { useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal'; 

const Link = styled.div`
  font-size: 12px;
  width: 100%;
  text-align: right;
  padding: 10px 10px 0 0;
  text-decoration: underline;
  color:  #666;
  cursor: pointer;
`;

const Info = styled.div`
  width: calc(100% - 20px);
  background-color: #dedede;
  padding: 10px;
  margin: 10px;
  border-radius: 3px;
`;

const CloseIcon = styled.i`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 20px;
`;

const MoreInfoPopUp = ({ label, info, children }) => {
  const [infoVisible, setInfoVisible] = useState(false);

  return (
    <div>
      <Link onClick={() => setInfoVisible(!infoVisible)}>
        { infoVisible ? 'Ocultar' : label }
      </Link>
      {
        infoVisible && (
          <Modal
            ariaHideApp={false}
            isOpen={infoVisible}
            onRequestClose={() => setInfoVisible(false)}
            style={{
              zIndex: 99999,
            }}
          >
            <CloseIcon className="fas fa-times" onClick={() => setInfoVisible(false)} />
            { info }
        </Modal>
        )
      }
    </div>
  );
};

export default MoreInfoPopUp;
