import React,  { useEffect, useState } from 'react';
import axios from 'axios';
import Header from 'components/layout/Header';
import Container from 'components/layout/Container';
import Loader from 'components/ui/Loader';
import DashboardContainer from 'containers/DashboardContainer';
import { SCENARIOS_FILE_PATH } from 'config';
import Footer from 'components/layout/Footer';
import NavHome from 'components/layout/NavHome';
/**
 * Dashboard.js
 * En este componente se realiza la carga de la información de los escenarios.
 */
const Dashboard = () => {
  const [scenarios, setScenarios] = useState(null);

  useEffect(() => {
    // Load scenarios json file
    axios.get(SCENARIOS_FILE_PATH)
      .then(response => setScenarios(response.data))
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div>
      <Header />
      <NavHome />
      <Container>
        {
          scenarios ? (
            <DashboardContainer scenarios={scenarios} />
          ) : (
            <Loader />
          )
        }
      </Container>
      <center>
        <Footer/>
      </center>
    </div>
  );
};

export default Dashboard;
