import React from "react";
import styled from "styled-components";
import logoCE from "img/CE_UCH_BYN.png";
import { Link } from "react-router-dom";
import { FECHA_ACTUALIZACION } from "utils/constants";

const Wrapper = styled.div`
  margin-bottom: 0px;
  background-color: white;
  width: 100%;
  color: black;
  h4 {
    color: var(--medium-grey-color);
  }
  a {
    color: black;
    text-decoration: none;
    font-size: 14px;
    @media (max-width: 720px) {
      font-size: calc(5px + 1vmin);
    }
  }
`;
const Table = styled.div`
  display: table;
  @media (max-width: 720px) {
    width: 350px;
  }
`;
const Title = styled.div`
  font-weight: bold;
  font-size: 12px;
  margin-right: 20px;
  @media (max-width: 720px) {
    font-size: 10px;
  }
`;
const TitleImg = styled.img`
  width: 100px;
  @media (max-width: 720px) {
    width: 80px;
  }
`;
const Columnas = styled.div`
  display: table-cell;
  margin-right: 20px;
  margin-left: 20px;
  padding: 5px;
  align-items: center;
  font-size: 12px;
  justify-content: center;

  ${(props) => props.table_img && "width: 400px"}
  ${(props) => props.table_img && "text-align: right"} 
  ${(props) => props.table_img && "justify-content: right"} 
  ${(props) => props.table && "width: 180px"} 
  ${(props) => props.table && "text-align: left"} 
  @media (max-width: 720px) {
    font-size: 8px;
    margin-right: 5px;
    margin-left: 5px;

    ${(props) => props.table_img && "width: 60%"}
    ${(props) => props.table && "width: 40%"}
  }
`;
const Fecha = styled.div`
  background-color: white;
  font-size: 14px;
  padding: 5px;
  display: block;

  @media (max-width: 720px) {
    font-size: 8px;
  }
`;

/**
 * Footer.js
 * Muestra los componentes en el foot page.
 */
const Footer = () => (
  <Wrapper>
    <Table>
      <Columnas table_img>
        <Title>
          {" "}Clúster de simulación de escenarios de mitigación de cambio climático{" "}
        </Title>
        <br />
        <TitleImg src={logoCE} alt="Logo Centro de Energía" />
      </Columnas>
      <Columnas table>
        <h4>Contenido</h4>
        <Link to="/acercade">Modelo de Proyección PMR</Link>
        <br />
        <Link to="/">Simulación de escenarios</Link>
        <br />
      </Columnas>
      <Columnas table>
        <h4>Iniciativas</h4>
        <a href="http://www.diplomacambioclimatico.cl/" target="_blank" rel="noopener noreferrer">
          Diplomado Cambio Climático
        </a>
      </Columnas>
      <Columnas table>
        <h4>Contacto</h4>
        <a href="http://centroenergia.cl/contacto/" target="_blank" rel="noopener noreferrer" >
          Centro de Energía
        </a>
      </Columnas>
    </Table>
    <center>
      <Fecha>Fecha de última actualización: {FECHA_ACTUALIZACION}.</Fecha>
    </center>
  </Wrapper>
);

export default Footer;
