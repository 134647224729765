import React from 'react';
import HelpContent from './../HelpContent';

const EscenarioBaseHelp = () => (
  <HelpContent>
    <h1>
        Escenario Base
    </h1>
    <p> Este escenario proyecta la situación del país suponiendo que todo se mantiene como en la actualidad.</p>
    <p> No se considera la aplicación de medidas adicionales a las ya implementadas.</p>
   
  </HelpContent>
);

export default EscenarioBaseHelp;
