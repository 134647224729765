import React from 'react';
import HelpContent from './HelpContent';
import SST_residencial from 'img/SST_casas.PNG';

const SolaresResidencialHelp = () => (
  <HelpContent>
    <h1>Sistema Solar Térmico en sector residencial</h1>
    <p>
    Esta medida analiza el impacto de la introducción de Sistemas Solares Térmicos en viviendas para los procesos de calentamiento de agua.
    </p>
    <p>
     La siguiente figura muestra un ejemplo de implementación de esta tecnología.
     </p>

     <img src={SST_residencial} alt="SST en sector residencial" />
    <label>Figura: Sistema Solar Térmico. Fuente: SUN Chile</label>
  
    <p>
    Trayectoria: El 7% de la energía para agua caliente sanitaria proviene de Sistema Solares Térmicos hacia el 2050.
    </p>
    <p>
    Trayectoria: El 30% de la energía para agua caliente sanitaria proviene de Sistema Solares Térmicos hacia el 2050.
    </p>
  </HelpContent>
);

export default SolaresResidencialHelp;
