import React, { useState } from 'react';
import Settings from 'components/ui/Settings';
import Checkbox from 'components/ui/Checkbox';
import Select from 'components/ui/Select';
import SliderLegend from 'components/ui/SliderLegend';
import Slider from 'components/ui/Slider';
import Help from 'components/ui/Help';
import SmallChart from 'components/ui/SmallChart';
import { SECTORES, UMBRALES, OFFSETS } from 'utils/constants';
import { trayectoriasSPET } from 'config';
import PermisosTransablesHelp from 'components/help/PermisosTransablesHelp';
import ImpuestoCarbonoHelp from 'components/help/ImpuestoCarbonoHelp';

/**
 * Controles para configurar los instrumentos económicos
 */
const EconomicInstruments = ({ settings, onChange }) => {
  const selectedTrayectoriaSpet = settings.permisos_transables.trayectoria || 2;

  const [spetVisible, setSpetVisible] = useState(true);
  const [icVisible, setIcVisible] = useState(true);

  return (
    <Settings.Panel>
      <h4>Instrumentos económicos</h4>
      
      <Checkbox
        id="impuesto_carbono"
        label="Impuesto al carbono"
        checked={settings.impuesto_carbono.enabled}
        /*disabled // Deshabilitado */
        onChange={(e, value) => {
          if (value) {
            onChange({
              ...settings,
              impuesto_carbono: {
                enabled: true,
              },
            });
          } else {
            onChange({
              ...settings,
              impuesto_carbono: {
                enabled: false,
                trayectoria: 2,
                sectores: ['generacion_electrica', 'mineria', 'industria']
              },
            });
          }
          setIcVisible(value);
        }}
        toggleVisible={() => {
          setIcVisible(!icVisible);
        }}
        enabled={settings.impuesto_carbono.enabled}
        visible={icVisible}
        help={<ImpuestoCarbonoHelp />}
      />
      {
        settings.impuesto_carbono.enabled && icVisible && (
          <Settings.Options>
            <Slider
              value={selectedTrayectoriaSpet}
              min={1}
              max={3}
              step={1}
              onChange={(e, value) => {
                onChange({
                  ...settings,
                  permisos_transables: {
                    ...settings.permisos_transables,
                    trayectoria: value,
                  },
                })
              }}
            />
            <SliderLegend
              options={[
                {
                  label: 'Tray. 1',
                  value: 1,
                },
                {
                  label: 'Tray. 2',
                  value: 2,
                },
                {
                  label: 'Tray. 3',
                  value: 3,
                }
              ]}
              onChange={(value) => {
                onChange({
                  ...settings,
                  impuesto_carbono: {
                    ...settings.impuesto_carbono,
                    trayectoria: value,
                  },
                })
              }}
            />
            <div>
              <label>Sectores</label>
              <Select
                options={SECTORES}
                value={settings.impuesto_carbono.sectores}
                onChange={(value) => {
                  onChange({
                    ...settings,
                    impuesto_carbono: {
                      ...settings.impuesto_carbono,
                      sectores: value,
                    },
                  })
                }}
                multiple
              />
            </div>       
          </Settings.Options>
        )
      }

      <Checkbox
        id="permisos_transables"
        label="Sistema de permisos transables"
        checked={settings.permisos_transables.enabled}
        onChange={(e, value) => {
          if (value) {
            onChange({
              ...settings,
              permisos_transables: {
                enabled: true,
              },
            });
          } else {
            onChange({
              ...settings,
              permisos_transables: {
                enabled: false,
                trayectoria: 2,
                sectores: ['generacion_electrica', 'mineria', 'industria'],
                umbral: 50,
                offset: 10,
              },
            });
          }
          setSpetVisible(value);
        }}
        toggleVisible={() => {
          setSpetVisible(!spetVisible);
        }}
        enabled={settings.permisos_transables.enabled}
        visible={spetVisible}
        help={<PermisosTransablesHelp />}
      />
      {
        settings.permisos_transables.enabled && spetVisible && (
          <Settings.Options>
            <Help title={(
              <label>
                Trayectoria del SPET
              </label>
            )}>
              {/* Gráfico de trayectorias de SPET */}
              <SmallChart
                id="trayectoria_impuesto"
                data={trayectoriasSPET.find(item => item.value === selectedTrayectoriaSpet)}
                info="Límite máximo de emisiones."
              />
            </Help>
            <Slider
              value={selectedTrayectoriaSpet}
              min={1}
              max={3}
              step={1}
              onChange={(e, value) => {
                onChange({
                  ...settings,
                  permisos_transables: {
                    ...settings.permisos_transables,
                    trayectoria: value,
                  },
                })
              }}
            />
            <SliderLegend
              options={[
                {
                  label: 'Tray. 1',
                  value: 1,
                },
                {
                  label: 'Tray. 2',
                  value: 2,
                },
                {
                  label: 'Tray. 3',
                  value: 3,
                }
              ]}
              onChange={(value) => {
                onChange({
                  ...settings,
                  permisos_transables: {
                    ...settings.permisos_transables,
                    trayectoria: value,
                  },
                })
              }}
            />
            <div>
              <label>Sectores</label>
              <Select
                options={SECTORES}
                value={settings.permisos_transables.sectores}
                onChange={(value) => {
                  onChange({
                    ...settings,
                    permisos_transables: {
                      ...settings.permisos_transables,
                      sectores: value,
                    },
                  })
                }}
                multiple
              />
            </div>
            <div>
              <label>Umbral</label>
              <Select
                options={UMBRALES}
                value={settings.permisos_transables.umbral}
                onChange={(value) => {
                  onChange({
                    ...settings,
                    permisos_transables: {
                      ...settings.permisos_transables,
                      umbral: value,
                    },
                  })
                }}
              />
            </div>
            <div>
              <label>Offset</label>
              <Select
                options={OFFSETS}
                value={settings.permisos_transables.offset}
                onChange={(value) => {
                  onChange({
                    ...settings,
                    permisos_transables: {
                      ...settings.permisos_transables,
                      offset: value,
                    },
                  })
                }}
              />
            </div>
            
          </Settings.Options>
        )
      }
    </Settings.Panel>
  );
}

export default EconomicInstruments;
