import React from 'react';
import HelpContent from './HelpContent';
//import proyeccionParticipacion from 'img/proyeccion_participacion.png';

const TransportePublicoHelp = () => (
  <HelpContent>
    <h1>Transporte Publico</h1>
    <p>
    Esta medida analiza el impacto del aumento de la participación del transporte público. De acuerdo a la última Encuesta Origen-Destino para Santiago 
    del año 2015, el 28,7% de los viajes urbanos se realizaron en transporte público, mientras el 38,7% en medios no motorizados y 32,6% en modo particular.
    La participación del transporte público es utilizada en promedio 30,4%, siendo la Arica la ciudad en donde tiene una menor participación (23%), y
    Temuco la con mayor participación (35%). Respecto a la participación del uso de vehículos particulares, en promedio tiene una utilización de 38,6% en 
    el total de los viajes, para las ciudades analizadas.
    </p>

    <p>
    La introducción masiva de este tipo de transporte deberá considerar variables de calidad, tiempos de viajes y confort, para que sea una alternativa 
    viable al transporte privado. 
    </p>
    
    <p>
    La trayectoría 1 considera que el una reducción de 10% de los viajes motorizados en vehículos particulares producto de medidas que 
    promueven el transporte público en buses.
    </p>
    <p>
    La trayectoría 2 considera que el una reducción de 20% de los viajes motorizados en vehículos particulares producto de medidas que 
    promueven el transporte público en buses.
    </p>

  </HelpContent>
);

export default TransportePublicoHelp;
