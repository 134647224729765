import BigNumber from './layout/bigNumber';
import Fact from './layout/fact';
import Wrapper from './layout/wrapper';
import React from 'react';

const Intensity = ({perc, newValue, año}) => {
    return (
        <Wrapper>
          <Fact>
            <h4>
              Intensidad de emisiones para el año {año}
            </h4>
            <BigNumber>
              <h1>
                { Number(newValue).toLocaleString('es-ES').replace('.', ',') }
              </h1>
              <label>
                ton CO<sub>2e</sub> / Millones CLP
              </label>
            </BigNumber>
          </Fact>
          <Fact>
            <h4>
              Reducción de intensidad de emisiones con respecto al año 2007
            </h4>
            <BigNumber>
              <h1>
                { Number(Math.floor(perc)).toLocaleString('es-ES') } %
              </h1>
            </BigNumber>
          </Fact>
        </Wrapper>
      );
}

export default Intensity;