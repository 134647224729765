import React from 'react';
import cadenaHidrogeno from 'img/cadena_hidrogeno_2.PNG';
import HelpContent from './HelpContent';

/*
 * Ayuda para Introducción Energías Renovables 
 */

const IntroHidrogenoMineriaHelp = () => (
  <HelpContent>
    <h1>Introducción de hidrógeno en tranporte minero</h1>
    <p>
      Esta medida tiene como objetivo la reducción de emisiones de GEI aprovechando el uso de
      energías renovables no convencionales en procesos productivos. En particular, se considera 
      la incorporación de hidrogeno verde como combustible para tracción de camiones de
      la minería del cobre y como combustible para otros procesos térmicos.
    </p>
    <p>
      El reemplazo de combustibles fósiles por combustibles no convencionales como
      el hidrogeno (H2) ha sido considerado como una medida clave para reducir emisiones de GEI en el
      largo plazo. En el estudio desarrollado por CORFO (2018) plantean un escenario 100%
      renovable para el año 2035, identificando como medida necesaria para alcanzar esta meta la
      aplicación de hidrogeno en celdas de combustibles para vehículos pesados, utilización de
      hidrogeno en procesos térmicos, entre otras aplicaciones. Además, se analiza la incorporación
      de tecnología solar en la producción de hidrogeno verde.
    </p>

    <p>
      En la figura se muestra la cadena de producción analizada por el estudio del comité solar de
      CORFO y las aplicaciones de este combustible en transporte pesado y usos térmicos para
      reducir emisiones de GEI en procesos productivos.
    </p> 
    
    <img src={cadenaHidrogeno} alt="Hidrógeno minería cobre" />
    <label>Figura: Cadena de producción del hidrógeno</label>
    
    <p>
      Trayectoria 1: Los camiones CAEX de las minas de rajo abierto utilizan hidrógeno y el 37% del consumo de diésel es reemplazado por hidrógeno verde hacia el año 2050.
    </p>
    <p>
      Trayectoria 2: Los camiones CAEX de las minas de rajo abierto utilizan hidrógeno y el 50% del consumo de diésel es reemplazado por hidrógeno verde hacia el año 2050.
    </p>
  </HelpContent>
);

export default IntroHidrogenoMineriaHelp;
