import React from 'react';
import HelpContent from './HelpContent';
//import proyeccionParticipacion from 'img/proyeccion_participacion.png';

const ImpuestoCarbonoHelp = () => (
  <HelpContent>
    <h1>Impuesto al carbono</h1>
    <p>
    Este instrumento económico tiene como objetivo la penalización de las emisiones de gases de efecto invernadero 
    mediante la aplicación de un impuesto. Actualmente en Chile existe un impuesto a las fuentes fijas cuyas emisiones anuales
    superan las 25.000 tCO2 al año. El impuesto tiene un valor de 5 US$/tCO2.
    </p>
    <p>
    Esta medida evalúa el incremento del impuesto hasta valores cercanos a los 33 US$/tCO2.
    </p>
  </HelpContent>
);

export default ImpuestoCarbonoHelp;
