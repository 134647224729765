import React, { useState, useEffect} from 'react';
import SubTitle from './SubTitle';
import { truncOneDecimal } from 'utils/helpers';
import { Page, Text, View, Document, StyleSheet, Image, Canvas, Font } from '@react-pdf/renderer';
import logoCentro from 'img/logo-centro-energia.jpg'; //antes se usaba el .png, por eso se dejo en el repositorio

const style = StyleSheet.create({
    wrapper : {
        paddingTop: "10",
        display: 'flex',
        textAlign: 'justify',
        justifyContent: 'space-between',
    },
    fact : {
        // padding: '20',
        // margin: '20',
        fontSize: '12',
    },
    img : {
        display: 'block',
        margin: '20 auto 0',
    },
    h4 : { 
        // margin: '0 0 0 0',
        // color: '#7b91b1',
    },
    b :  {
        fontSize: '18px',
        // fontWeight: 'normal',
        display: 'inline-block',
        margin: '0 4',
    },
    h1 : {
        // width: '100%',
        textAlign: 'center',
        // fontWeight: 'normal',
        // fontSize: '32',
        // margin: 0,
        // fontFamily: "'Montserrat', sans-serif",
        // fontWeight: '600',
        // fontSize: '80',
    },
    label : {
        color: "drak-blue",
        display: 'block',
        width: '200',
        margin: '0 auto',
        textAlign: 'center',
    },
    page: {
      fontSize: 11,
      paddingTop: 30,
      paddingLeft: 60,
      paddingRight: 60,
      lineHeight: 1.5,
      flexDirection: 'column',
    }, 
    logo: {
        width: 74,
        marginTop: '10',
        marginLeft: '30',
        marginRight: 'auto'
    },
    chart: {
      width: 200,
      marginHorizontal: 120,
    },
    center: {
      textAlign: 'center'
    },
    titleContainer:{
        flexDirection: 'row',
        // marginTop: 24,
    },
    reportTitle:{
        color: 'black',
        // letterSpacing: 2,
        fontSize: 12,
        textAlign: 'left',
        // fontWeight: 'bold',
        fontStyle: 'bold',
        paddingTop: '15',
        paddingLeft: 15,
        // textTransform: 'uppercase',
    },
    chartTitle:{
      paddingTop: '25',
      textAlign: "center",
      fontSize: '9',
    },
    bulletPoint: {
      width: 10,
      // fontSize: 10,
    },
    subidc : { 
        verticalAlign:'sub', 
        // fontFamily: 'Inconsolata',    
        // fontSize: 2,
    },


})

/**
 * Permite mostrar los KPIs asociados a ciertos indicadores 
 */
const KPIPdf = ({ mode, currentScenario, base, newValue, año }) => {

  if (mode === 'emisiones_netas') {

    const amount = Number(newValue.toFixed(1));
    const amountCars = Math.floor(amount * 1000000 / 560);
    const perCapita = Number((newValue / 18).toFixed(1));
    

    return (
      <View >
      <SubTitle title={"KPI año " + año}></SubTitle>

        <View style={style.fact} wrapper={false}>
          <View style={style.fact}>
          <Text style={style.reportTitle} wrapper={false}> <Text style={style.bulletPoint}>•</Text> Emisiones netas totales para el año {año} </Text>
          <Text style={style.h1}>
                {amount.toLocaleString('es-ES') } millones de toneladas de CO<Text style={style.subidc}>2e</Text>
          </Text>
        </View>

        <View style={style.fact} wrapper={false}>
          <Text style={style.reportTitle} wrapper={false}> <Text style={style.bulletPoint}>•</Text> En este escenario la carbono neutralidad : </Text>
          
            { amount > 0 ?
            <Text style={style.h1}>
              No se cumple al año {año}
          </Text>
          : <Text style={style.h1}>
            Si cumple al año {año}
        </Text>
        }
        </View>

        <View style={style.fact} wrapper={false}>
            <Text style={style.reportTitle}>
            <Text style={style.bulletPoint}>•</Text> Las emisiones netas per cápita de este escenario
              corresponde a:
            </Text>

              <Text style={style.h1}>
                { perCapita.toFixed(1).replace('.', ',') } toneladas de CO<Text style={style.subidc}>2e</Text> al año {año}
              </Text>
              {/* <Text style={style.label}>
                toneladas de CO2e al año {año}
              </Text> */}
        </View>
      </View>
      </View>
    );
  }

  if (currentScenario.name === 'Escenario Base') {
    return null;
  }


  if (mode === 'emisiones_gei') {
    const amount = Number((base - newValue).toFixed(1));
    const amountCars = Math.floor(amount * 1000000 / 560);
    const perCapita = Number((newValue / 18).toFixed(1));

    let emisionesChart = document.getElementById("emisiones");
    let canvasData = emisionesChart.getElementsByTagName("canvas")[0].toDataURL()
    return (
      <View  >
        <SubTitle title={"KPI año " + año}></SubTitle>
        <View style={style.fact} wrapper={false}>
        <Text style={style.reportTitle}> <Text style={style.bulletPoint}>•</Text> Reducción de Emisiones GEI para el año {año} 
        </Text>
        <Text style={style.h1}>
                {amount.toLocaleString('es-ES') } millones de toneladas de CO
            <Text>2e</Text>
        </Text>
        </View>
        {/* <Text style={style.label}>
           
        </Text>  */}
        {/* <View >
            <Text style={style.h1}>
            { amount.toLocaleString('es-ES') }
            </Text>
            <Text style={style.label}>
            millones de toneladas de CO2e
            </Text>
        </View> */}
        <View style={style.fact} wrapper={false}>
            <Text style={style.reportTitle}>
            <Text style={style.bulletPoint}>•</Text> La emisión per cápita de GEI de este escenario
              corresponde a:
            </Text>

              <Text style={style.h1}>
                { perCapita.toFixed(1).replace('.', ',') } toneladas de CO<Text style={style.subidc}>2e</Text> al año {año}
              </Text>
              {/* <Text style={style.label}>
                toneladas de CO2e al año {año}
              </Text> */}
        </View>
        <View  wrap={false}>
          <Text style={style.reportTitle}>
          <Text style={style.bulletPoint}>•</Text> Esta reducción equivale a la emisión de CO<Text style={style.subidc}>2e</Text> de{' '}
            { Math.ceil(amountCars).toLocaleString('es-ES') }{' '}
            automóviles recorriendo la distancia por
            carretera entre Santiago y Coyhaique.
          </Text>
          {/* <Image src={co2} /> */}
        </View>
        <View wrap={false}>
          <Text style={style.chartTitle} >
          Comparación emisiones per capita de Chile({año}) con el resto del mundo 
          </Text>
          {
          canvasData &&  <Image src={canvasData} style={style.chart}/>
          }
        </View>
        
      </View>
    );
  }
  if (mode === 'demanda_energia') {
    const amount = base - newValue;
    const cars = Number(amount / 0.0082).toFixed(2);
    const homeValue = Math.floor(amount / 0.00215);

    return (
        <View>
        <SubTitle title={"KPI año " + año}></SubTitle>
        <View style={style.fact} wrapper={false}>
          <Text style={style.reportTitle}>
          <Text style={style.bulletPoint}>•</Text> Reducción de consumo de energía para el año {año}
          </Text>
          <View>
            <Text style={style.h1}>
              { Number(Math.floor(amount)).toLocaleString('es-ES') } TCal
            </Text>
          </View>
        </View>
        <View>
          <View style={style.fact} wraper={false}>
              {/* <View style={{ width: 240 }}>
                <Image src={home} style={style.img}/>
              </View> */}
              <Text style={style.reportTitle}>
              <Text style={style.bulletPoint}>•</Text> Esta reducción equivale al Consumo de energía eléctrica de { Number(Math.floor(homeValue)).toLocaleString('es-ES') } hogares chilenos en un año
              </Text>
            </View>
          <View style={style.fact} wrapper={false}>
              {/* <View style={{ width: 190 }}>
                <Image src={car} style={style.img}/>
              </View> */}
              <Text style={style.reportTitle}>
              <Text style={style.bulletPoint}>•</Text>Esta reducción equivale al Consumo promedio anual de { Math.ceil(Number(cars)).toLocaleString('es-ES') } automóviles
              </Text>
          </View>
        </View>
      </View>
    );
  }
  

  if (mode === 'intensidad_emisiones') {
    const value2007 = 1.02;
    const diff = value2007 - newValue;
    const perc = ((diff/value2007) * 100).toFixed(2);

    return (
      <View>
        <SubTitle title={"KPI año " + año}></SubTitle>
        <View style={style.fact} wrapper={false}>
          <Text style={style.reportTitle}>
          <Text style={style.bulletPoint}>•</Text> Intensidad de emisiones para el año {año}
          </Text>
            <Text style={style.h1}>
              { Number(newValue).toLocaleString('es-ES') } ton CO<Text style={style.subidc}>2e</Text> / Millones CLP
            </Text>
        </View>
        <View style={style.fact} wrapper={false}>
          <Text style={style.reportTitle}>
          <Text style={style.bulletPoint}>•</Text> Reducción de intensidad de emisiones con respecto al año 2007
          </Text>
            <Text style={style.h1}>
              { Number(Math.floor(perc)).toLocaleString('es-ES') } %
            </Text>
        </View>
      </View>
    );
  }

  if (mode === 'generacion') {

    return (
      <View>
        <SubTitle title={"KPI año " + año}></SubTitle>
        <View style={style.fact} wrapper={false}>
          <Text style={style.reportTitle}>
          <Text style={style.bulletPoint}>•</Text> La participación de la energia renovable en el año {año} es de
          </Text>
            <Text style={style.h1}>
              { base } %
            </Text>
        </View>
        <View style={style.fact} wrapper={false}>
          <Text style={style.reportTitle}>
          <Text style={style.bulletPoint}>•</Text> La participación de centrales a carbón en el año {año} es de
          </Text>
            <Text style={style.h1}>
              { newValue } %
            </Text>
        </View>
      </View>
    );
  }

  if (mode === 'factor_emision_red_electrica') {

    return (
      <View>
        <SubTitle title={"KPI año " + año}></SubTitle>
        <View style={style.fact} wrapper={false}>
          <Text style={style.reportTitle}>
          <Text style={style.bulletPoint}>•</Text> El factor de emisión de la red eléctrica en el año {año} es de
          </Text>
            <Text style={style.h1}>
            {  newValue.toFixed(2).replace('.', ',') } tCO2/MWh
            </Text>
        </View>
      </View>
    );
  }
  if (mode === 'pib') {
    const perc = (newValue - base) / base * 100;

    return (
      <View>
        <SubTitle title={"KPI año " + año}></SubTitle>
        <View style={style.fact} wrapper={false}>
          <Text syle={style.reportTitle}>
          <Text style={style.bulletPoint}>•</Text> La variación del PIB para el año {año} es de
          </Text>
            <Text style={style.h1}>
              { truncOneDecimal(Number(perc).toLocaleString('es-ES')) } %
            </Text>
        </View>
      </View>
    );
  }
  
  return null;
};

export default KPIPdf;
