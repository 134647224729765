import styled from 'styled-components';

const Fact = styled.div`
padding: 20px;
margin: 20px;
font-size: 14px;
border-radius: 4px;
border: 1px solid #e4e7f0;
background-color: #fff;

${props => props.baseline && 'align-self: baseline'}

h4 { 
  margin: 0 0 20px 0;
  color: #7b91b1;
}

.success {
  color: #0D9207;
}

.warning {
  color: #F92403;
}


img {
  display: block;
  margin: 20px auto 0;
}

b {
  font-size: 18px;
  font-weight: normal;
  display: inline-block;
  margin: 0 4px;
}
`;

export default Fact;