import React from 'react';
import styled from 'styled-components';
import { bool, string, func, array, any } from 'prop-types';
import Creatable from 'react-select/lib/Creatable';
import ReactSelect from 'react-select';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

const getColor = (props) => {
  if (props.warning) {
    return 'var(--warning-color);';
  }
  if (props.error) {
    return 'var(--error-color);';
  }
  return 'var(--light-grey-color);';
};

const Wrapper = styled.div`
  && {
    font-size: 16px;

    & > div {
      min-width: 200px;
      
      & > div {
        min-height: 36px;
        border: 1px solid ${props => getColor(props)};

        &:hover {
          border: 1px solid ${props => getColor(props)};
        }

        &:active {
          border: 1px solid ${props => getColor(props)};
        }

        & + div {
          z-index: 9999;
        }

      }
    }

    button {
      width: 100%;
    }
  }
`;

const customStyles = {
  multiValueRemove: provided => ({
    ...provided,
    ':hover': {
      backgroundColor: 'var(--primary-color)',
      color: 'white',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isFocused ? 'black' : 'var(--medium-grey-color)',
    backgroundColor: state.isFocused ? 'var(--lighter-grey-color)' : 'white',
    ':hover': {
      backgroundColor: 'var(--lighter-grey-color)',
    },
  }),
};

class Select extends React.Component {
  handleChange = (newValue) => {
    const { multiple } = this.props;
    if (multiple) {
      this.props.onChange(newValue.map(item => item.value));
    } else {
      this.props.onChange(newValue ? newValue.value : '');
    }
  };

  getValue = () => {
    const { value, options, multiple } = this.props;
    const foundValue = multiple ? options.filter(opt => (value && value.indexOf(opt.value) !== -1 ))
      : options.find(opt => opt.value === value);

    return foundValue || {
      label: value,
      value,
    };
  }

  render() {
    const {
      placeholder,
      options,
      multiple,
      fullWidth,
      allowCustom,
      error,
      warning,
    } = this.props;

    const SelectComponent = allowCustom ? Creatable : 
      multiple ? ReactMultiSelectCheckboxes : ReactSelect;

    return (
      <Wrapper error={error} warning={warning}>
        <SelectComponent
          styles={customStyles}
          onChange={this.handleChange}
          options={options}
          placeholder={placeholder}
          isMulti={multiple}
          fullWidth={fullWidth}
          value={this.getValue()}
          defaultValue={this.getValue()}
          placeholderButtonLabel={multiple ? 'Selecciona...' : null}
          getDropdownButtonLabel={multiple ? ({ value }) => {
            if (value.length < 3) {
              return value.map(item => item.label).join(',');
            }
            return `${value.length} seleccionados.`;
          } : null}
        />
      </Wrapper>
    );
  }
}

Select.propTypes = {
  placeholder: string,
  multiple: bool,
  options: array,
  onChange: func,
  fullWidth: bool,
  allowCustom: bool,
  error: bool,
  warning: bool,
  value: any,
};

export default Select;
