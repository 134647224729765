import styled from 'styled-components';
import Panel from 'components/ui/Panel';

const Settings = {};

Settings.Panel = styled(Panel)`
  padding: 10px 0 10px 0;
  font-size: 14px;
  margin-bottom: 10px;
  ${props => props.disabled ? 'pointer-events: none;' : ''}
  width: 100%;


  h4 {
    text-transform: uppercase;
    color: var(--light-grey-color);
    font-weight: normal;
    font-size: 12px;
    padding: 0 10px;
    margin-bottom: 5px;
  }
`;

Settings.Panel.Correct = styled.div`
  padding: 10px 0 10px 0;
  font-size: 14px;
  margin-bottom: 10px;
  ${props => props.disabled ? 'pointer-events: none;' : ''}
  width: 100%;
  overflow-y: scroll;
  max-height: 450px;


  h4 {
    text-transform: uppercase;
    color: var(--light-grey-color);
    font-weight: normal;
    font-size: 12px;
    padding: 0 10px;
    margin-bottom: 5px;
  }
`;

Settings.Options = styled.div`
  padding: 20px;
  background-color: #f3f3f3;

  label {
    display: inline-block;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 10px;

    i {
      cursor: pointer;
      margin-left: 10px;
      font-size: 14px;
    }
  }
`;

Settings.Item = styled.div`
  padding: 10px 20px;

  & > label {
    display: inline-block;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 20px;

    i {
      cursor: pointer;
      margin-left: 10px;
      font-size: 14px;
    }
  }
`;

export default Settings;
