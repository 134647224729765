import React from 'react';
import HelpContent from './HelpContent';
import HidrogenoCamiones from 'img/camion_hidrogeno.png';

const HidrogenoCamionesHelp = () => (
  <HelpContent>
    <h1>Introducción de hidrógeno en camiones de transporte de carga</h1>
    <p>
      Esta medida evalúa el impacto de la introducción de camiones de hidrógeno 
	  para el transporte de carga. A nivel internacional, se han desarrollado prototitos de camiones a hidrógeno. A modo de ejemplo, 
    la siguiente figura un camión un camión de carga a hidrógeno en una estación de servicio.
    </p>
	<img src={HidrogenoCamiones} alt="Hidrógeno en transporte de carga"/>
    <label>Figura: Estación de carga de camiones de hidrógeno. Fuente: Nikola Motor https://nikolamotor.com/energy  </label>
    
    <p>
      La trayectoría 1 considera que el 70% de los camiones utilizará hidrógen hacia el año 2050.
    </p>
    <p>
      La trayectoría 2 considera que el 85% de los camiones utilizará hidrógen hacia el año 2050.
    </p>
  </HelpContent>
);

export default HidrogenoCamionesHelp;
