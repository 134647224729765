import React from 'react';
import styled from 'styled-components';
import Select from 'components/ui/Select';
import Settings from 'components/ui/Settings';
import MoreInfo from 'components/ui/MoreInfo';
import { chartSettings } from 'config';

const Container = styled.div`
  ${props => props.paddingTop && 'padding-top: 15px;'}
  background-color: white;
  border-radius: 3px;
`;

const Wrapper = styled.div`
  padding: 0 10px;
`;

/**
 * Permite seleccionar los diferentes indicadores,
 * por ejemplo: Emisiones GEI, Intensidad Emisiones. 
 */
const ModeSelection = ({
  mode,
  onChange,
  prepend,
}) => {
  const modeSettings = chartSettings.find(item => item.id === mode);

  return (
    <Container paddingTop={prepend}>
      { prepend }
      <Settings.Panel>
        <h4>Indicador</h4>
        <Wrapper>
          <Select
            value={mode}
            options={
              chartSettings.map((item) => ({
                label: item.label,
                value: item.id,
              }))
            }
            onChange={onChange}
          />
        </Wrapper>
        {
          modeSettings && modeSettings.description && (
            <MoreInfo label="Más información">
              { modeSettings.description }
            </MoreInfo>
          )
        }
      </Settings.Panel>
    </Container>
  );
}

export default ModeSelection;
