import styled from 'styled-components';

export default styled.div`
  padding: 20px;

  h1 {
    margin-top: 0;
    @media (max-width: 720px) {
      font-size: 18px;
    }
  }

  p{
    @media (max-width: 720px) {
      font-size: 13px;
    }
  }

  img {
    display: block;
    margin: 0 auto;
    height: auto;
    max-width: 600px;
    @media (max-width: 720px) {
      display: block;
      max-width: 100%;
      height: auto;
  
  }
  }

  label {
    font-style: italic;
    font-size: 11px;
    display: block;
    margin: 10px auto 40px auto;
    width: 100%;
    text-align: center;
    color: navy;
  }
`;
