import styled from 'styled-components';

export default styled.div`
  border-radius: 3px;
  width: 100%;
  min-height: 20px;
  background-color: white;
  padding: 10px;
  margin-bottom: 15px;

  h4 {
    margin: 0;
  }
`;
