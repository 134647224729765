import React from 'react';
import HelpContent from './HelpContent';
//import proyeccionParticipacion from 'img/proyeccion_participacion.png';

const HidrogenoTermicoHelp = () => (
  <HelpContent>
    <h1>Uso de hidrógeno en procesos térmicos de la industria y minería</h1>
    <p>
    Esta medida evalúa el impacto del reemplazo de combustibles fósiles por hidrógeno en los procesos térmicos de la industria y minería.
    </p>
    <p>
    La trayectoría 1 considera que un 3% de reemplazo de combustibles fósiles por hidrógeno hacia el año 2050.
    </p>
    <p>
    La trayectoría 2 considera que un 10% de reemplazo de combustibles fósiles por hidrógeno hacia el año 2050.
    </p>
  </HelpContent>
);

export default HidrogenoTermicoHelp;
