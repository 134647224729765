import React from 'react';
import HelpContent from './HelpContent';
import spetFigura1 from 'img/spet_figura_1.png';
import spetFigura2 from 'img/spet_figura_2.png';

/**
 * Ayuda para SPET
 */
const PermisosTransablesHelp = () => (
  <HelpContent>
    <h1>
      Sistemas de Permisos de Emisión Transable
    </h1>
    <p>
      Los Sistemas de Permisos de Emisión Transable (SPET) son instrumentos de mercado,
      diseñados para reducir emisiones de gases de efecto invernadero. Los gobiernos determinan
      un límite máximo (tope o cap en inglés) de emisiones totales en uno o más sectores de la
      economía (ver siguiente figura). Las empresas de los sectores que participan de este sistema
      deben contar con permisos por cada tonelada emitida a la atmósfera.
    </p>
    <img src={spetFigura1} alt="SPET Figura 1" />
    <label>
      Figura: Tope o cap de emisiones (Fuente: International Carbon Action Partnership)
    </label>
    <p>
      Los países que han implementado este tipo de sistemas generalmente incluyen a las empresas
      del sector generación eléctrica e industria. No obstante, otros sectores también podrían
      participar de este tipo de sistema, como se muestra en la siguiente figura.
    </p>
    <img src={spetFigura2} alt="SPET Figura 2" />
    <label>
      Figura: Sectores que participan de SPET a nivel internacional. Fuente: International Carbon Action Partnership, 2015.
    </label>
    <p>
      Los permisos de emisiones pueden ser asignados a las empresas de forma gratuita o
      subastados. Estos permisos pueden transarse, generando oferta y demanda de permisos de
      emisión entre actores del mercado, y a su vez creando una mayor flexibilidad en los tiempos y
      fórmulas para reducir emisiones.
    </p>
    <p>
      Los offsets o créditos de compensación son un mecanismo de reducción de emisiones fuera
      del alcance o sectores que participan del SEPT. Mediante este mecanismo una empresa podría
      compensar sus emisiones implementando accediendo a derechos de emisión de bajo costo.
      Asimismo, los offsets dan la posibilidad de generar y vender offsets genera incentivos para
      reducir emisiones en otros sectores y regiones (Fuente: International Carbon Action
      Partnership).
    </p>
  </HelpContent>
);

export default PermisosTransablesHelp;
