import React, { useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Icon = styled.i`
  cursor: pointer;
  position: relative;
  top: 2px;
  left: 5px;
`;

const Help = ({ title , children}) => {
  const [visible, setVisible] = useState(false); 
  return (
    <div>
      <Wrapper>
        { title }
        {
          visible ? (
            <Icon onClick={() => setVisible(false)} className="fas fa-times-circle" />
          ) : (
            <Icon onClick={() => setVisible(true)} className="fas fa-info-circle" />
          )
        }
      </Wrapper>
      {
        visible ? children : null
      }
    </div>
  );
}

export default Help;
