import React from 'react';
import HelpContent from './HelpContent';
import proyeccionParticipacion from 'img/proyeccion_participacion.png';

const EficienciaMineriaHelp = () => (
  <HelpContent>
    <h1>Eficiencia energética en industria y minería</h1>
    <p>
      La implementación de medidas de eficiencia energética en los sectores productivos tiene como
      objetivo reducir las emisiones, tanto directas como indirectas, mediante la disminución del
      consumo de energéticos en los procesos necesarios para la producción industrial o minera. Los
      proyectos de eficiencia energética usualmente son rentables con periodos de recuperación de
      capital razonables (menos de 5 años), no obstante, existen barreras para su masificación tales
      como altos costos de inversión, desconocimiento, falta de personal adecuado para su
      implementación, entre otros factores.
    </p>
    <p>
      La primera medida analizada corresponde a la implementación de un estándar mínimo de
      eficiencia energética para motores eléctricos comercializados en el país. Según el diagnóstico
      realizado por River Consultores (2014) el parque actual de motores es equivalente a una
      eficiencia del tipo IE1 (equivalente a 84,7% para motores menores a 10 HP). Como primera
      recomendación se evalúa implementar la restricción al estándar mínimo de eficiencia del tipo
      IE2 (equivalente a 87,7% para motores menores a 10 HP). Adicionalmente, en dicho estudio se
      estima que el recambio total del parque se alcanzaría el año 2030 como se muestra en la
      siguiente figura. Esta tendencia corresponde a la trayectoria 1 modelada.
    </p>
    <img src={proyeccionParticipacion} alt="Proyección de participación de motores eficientes" />
    <label>
      Proyección de participación de motores eficientes
    </label>
    <p>
      Además de mejorar la eficiencia de motores eléctricos, es posible la aplicación de medidas de
      eficiencia energética en procesos térmicos en el sector industrial y minero. En particular,
      existen grandes oportunidades de reducción de costos y de emisiones de GEI en procesos de
      generación de vapor o agua caliente. Se estima que es posible reducción de consumo de un 15
      % aplicando medidas como:
    </p>
    <ul>
      <li>Reparación de fugas en líneas de vapor.</li>
      <li>Control de carga en calderas.</li>
      <li>Mejorar la operación de las calderas.</li>
      <li>Instalación de economizadores.</li>
      <li>Instalación de recuperadores de calor.</li>
      <li>Mejorar la eficiencia en los quemadores.</li>
    </ul>
    <p>
      Actualmente, existen iniciativas a nivel nacional para fomentar la eficiencia energética en los
      sectores productivos tanto del sector público como el sector privado. En la Primera Encuesta
      Nacional de Eficiencia Energética se llega a la conclusión que existe espacio para impulsar la
      eficiencia energética en la industria.
    </p>
  </HelpContent>
);

export default EficienciaMineriaHelp;
