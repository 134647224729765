import React, { useState } from 'react';
import styled from 'styled-components';
import deepEqual from 'deep-equal';
import ScenarioSelector from './ScenarioSelector';
import EconomicInstruments from './EconomicInstruments';
import EnergeticPolicies from './EnergeticPolicies';
import GeneralParameters from './GeneralParameters';
import ChartMode from './ChartMode';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
  @media(max-width:720px) {
    display: block;
  }
`;

const Sidebar = styled.div`
  width: ${props => props.narrow ? '360px' : '420px'};
  margin-right: 10px;
  overflow-x: hidden;

  @media(max-width:720px) {
    width: 100%;
  }
`;

const Main = styled.div`
  width: 100%;
`;

const AddMode = styled.div`
  width: 100%;
  margin: 10px 0;
  display: flex;
  justify-content: flex-end;

  button {
    padding: 10px;
    appearance: none;
    float: right;
    margin: 10px 0;
    font-size: 16px;
    border: none;
    border-radius: 3px;
    background-color: var(--light-grey-color);
    color: white;
    cursor: pointer;
  }
`;

/**
 * Dada la combinación actual de medidas, obtener 
 * si hay un escenario precalculado
 * */ 
const getMatchingScenario = (scenarios, settings) => {
  for (let scenario of scenarios) {
    if (deepEqual(settings, scenario.settings)) {
      return scenario;
    }
  }
  return null;
}

const DashboardContainer = ({ scenarios }) => {
  // Opciones del menú izquierdo, por ejemplo: Instrumentos económicos
  const [settings, setSettings] = useState(scenarios.find(item => item.name === 'Escenario Base').settings);
  
  // Obtenemos la ruta del set de datos, basado en la configuración de las medidas. 
  const currentScenario = getMatchingScenario(scenarios, settings);

  // setup visible charts
  const [visibleCharts, setVisibleCharts] = useState([true]);

  // whether or not we're alread showing all the possible charts
  const amountVisible = visibleCharts.filter(item => item).length;

  // hide chart on index === i
  const hideChart = (i) => {
    setVisibleCharts(visibleCharts.map((item, j) => {
      if (i === j) {
        return false;
      }
      return item;
    }));
  };

  const showAdditionalChart = () => {
    setVisibleCharts([
      ...visibleCharts,
      true,
    ]);
  }
  
  return (
    <Wrapper>
      <Sidebar>
        <ScenarioSelector
          onChange={setSettings}
          scenarios={scenarios}
          selected={currentScenario ? currentScenario.name : 'custom'}
        />
        <EconomicInstruments
          settings={settings}
          onChange={setSettings}
        />
        <EnergeticPolicies
          settings={settings}
          onChange={setSettings}
        />
        <GeneralParameters
          settings={settings}
          onChange={setSettings}
        />
      </Sidebar>
      <Main>
        {
          visibleCharts.map((visible, i) => (
            visible && (
              <ChartMode
                key={i}
                index={i}
                onHide={amountVisible > 1 ? () => hideChart(i) : null}
                currentScenario={currentScenario}
                settings={settings}
              />
            )
          ))
        }
        {
          amountVisible < 5 && (
            <AddMode>
              <button type="button" onClick={showAdditionalChart}>
                Agregar indicador +
              </button>
            </AddMode>
          )
        }
      </Main> 
    </Wrapper>
  );
}

export default DashboardContainer;
