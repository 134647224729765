import React from 'react';
import HelpContent from './HelpContent';
//import proyeccionParticipacion from 'img/proyeccion_participacion.png';

const ElectrificacionIndustriaMineriaHelp = () => (
  <HelpContent>
   <h1>Electrificación de usos usos motrices en la industria y minería</h1>
   <p>
    Esta medida evalua el impacto de la electrificación de los usos motrices de la industria y minería.
    </p>
    <p>
    La trayectoría 1 considera que el 89% de los usos motrices se electrifican hacia el año 2050.
    </p>
    <p>
    La trayectoría 2 considera que el 95% de los usos motrices se electrifican hacia el año 2050.
    </p>
  </HelpContent>
);

export default ElectrificacionIndustriaMineriaHelp;
