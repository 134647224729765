import React from 'react';
import HelpContent from './HelpContent';
import HidrogenoMotrices from 'img/maritimo_hidrogeno.PNG';

const HidrogenoMotricesHelp = () => (
  <HelpContent>
    <h1>Uso de hidrógeno en motores de la industria y minería</h1>
    <p>
    Esta medida evalúa el impacto del reemplazo de combustibles fósiles por hidrógeno en los usos motrices de la industria y minería.
    </p>
    <p>
    La trayectoría 1 considera que un 12% de reemplazo de combustibles fósiles por hidrógeno verde en el sector industrias varias hacia el año 2050.
    </p>
    <p>
    La trayectoría 2 considera que un 20% de reemplazo de combustibles fósiles por hidrógeno verde en el sector industrias varias hacia el año 2050.
    </p>
  </HelpContent>
);

export default HidrogenoMotricesHelp;
