import React from 'react';
import styled from 'styled-components';
import Container from 'components/layout/Container';
import pmrLogo from 'img/pmr-logo.png';
import logoCentro from 'img/logo-centro-energia.jpg'; //antes se usaba el .png, por eso se dejo en el repositorio
import flag from 'img/flag.png';

const HeaderMain = styled.div`
  background-color: white;
  width: 100%;
`

const Wrapper = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding-bottom: 10px;
  margin-bottom: 20px;

  @media (max-width: 720px) {
    display: block;
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 25px;

  & > img {
    display: block;
    width: 110px;
    margin-bottom: 15px;
  }

  @media (max-width: 720px) {
    font-size: 14px;
  }
`;

const Logos = styled.div`
  display: flex;
  align-items: baseline;
  padding-top: 10px;

  img {
    align-self: flex-end;
  }
`;

const PMRLogo = styled.img`
  width: 200px;

  @media (max-width: 720px) {
    width: 120px;
  }
`;

const CentroLogo = styled.img`
  width: 120px;
  margin-right: 20px;

  @media (max-width: 720px) {
    width: 80px;
  }
`;

const Header = () => (
  <HeaderMain>
    <Wrapper>
      <Title>
       <br />
       Clúster de simulación de escenarios de mitigación de cambio climático
      </Title>
      <Logos>
        <CentroLogo src={logoCentro} alt="centroenergia" />
      </Logos>
    </Wrapper>
  </HeaderMain>
);

export default Header;
