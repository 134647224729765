import BigNumber from './layout/bigNumber';
import Fact from './layout/fact';
import Wrapper from './layout/wrapper';
import React from 'react';

const Potencia = ({año, kpiDict}) => {
    return (
        <Wrapper>
            <div>
                <Fact>
                    <h4>
                        La potencia instalada en energía solar fotovoltaica es:
                    </h4>
                    <BigNumber>
                        <h1>
                            {kpiDict["potencia_instalada_solar_fv"].toFixed(0).replace('.', ',')}
                        </h1>
                        <label> MW en el año {año}.</label>
                    </BigNumber>
                </Fact>
                <Fact>
                    <h4>
                        La potencia instalada en energía solar CSP es:
                    </h4>
                    <BigNumber>
                        <h1>
                            {kpiDict["potencia_instalada_solar_csp"].toFixed(0).replace('.', ',')}
                        </h1>
                        <label> MW en el año {año}.</label>
                    </BigNumber>
                </Fact>
                <Fact>
                    <h4>
                        La potencia instalada en energía eolica es:
                    </h4>
                    <BigNumber>
                        <h1>
                            {kpiDict["potencia_instalada_eolica"].toFixed(0).replace('.', ',')}
                        </h1>
                        <label> MW en el año {año}.</label>
                    </BigNumber>
                </Fact>
                <Fact>
                    <h4>
                        La potencia instalada en sistema de almacenamiento es:
                    </h4>
                    <BigNumber>
                        <h1>
                            {kpiDict["potencia_instalada_almacenamiento"].toFixed(0).replace('.', ',')}
                        </h1>
                        <label> MW en el año {año}.</label>
                    </BigNumber>
                </Fact>
            </div>
        </Wrapper>
    );
}

export default Potencia;