import BigNumber from './layout/bigNumber';
import Fact from './layout/fact';
import Wrapper from './layout/wrapper';
import React from 'react';
import co2 from 'img/emission.svg';
import EmisionesChart from '../EmisionesChart';


const Gei = ({amount, amountCars, perCapita, kpiDict, año}) => {
    return (
      <Wrapper>
        <div>
          <Fact>
            <h4>Reducción de Emisiones GEI para el año {año}</h4>
            <BigNumber>
              <h1>
                { amount.toLocaleString('es-ES').replace('.', ',') }
              </h1>
              <label>
                millones de toneladas de CO<sub>2e</sub>
              </label>
            </BigNumber>
          </Fact>
          <Fact>
            <h4>
              La emisión per cápita de GEI de este escenario
              corresponde a:
            </h4>
            <BigNumber>
              <h1>
                { perCapita.toFixed(1).replace('.', ',') }
              </h1>
              <label>
                toneladas de CO<sub>2e</sub> al año {año}
              </label>
            </BigNumber>
          </Fact>
          <Fact>
            <h4>Emisiones en el 2030</h4> 
            <BigNumber>
              <h1>
                { kpiDict["tco2_2030"].toFixed(1).replace('.', ',') }
              </h1>
              <label>millones tCO2e.</label></BigNumber>
          </Fact>
          <Fact>
          <h4>
            En el año 2030 este escenario
          </h4>
          <BigNumber> 
            { kpiDict["tco2_2030"] > 95 ? <h1 className="warning">supera</h1> : <h1 className="success">no supera</h1>} 
            <label>
              el límite de emisiones de 95 millones de tCO2.
            </label>
          </BigNumber>
          </Fact>
          <Fact>
            <h4>Emisiones acumuladas entre 2020 y 2030 son</h4> 
            <BigNumber>
              <h1>
                { kpiDict["tco2_entre_2020_2030"].toFixed(1).replace('.', ',') }
              </h1>
              <label>millones tCO2e.</label></BigNumber>
          </Fact>
          <Fact>
          <h4>
            Este escenario 
          </h4>
          <BigNumber> 
            { kpiDict["tco2_entre_2020_2030"] > 1100 ? <h1 className="warning">supera</h1> : <h1 className="success">no supera</h1>} 
            <label>
              el presupuesto de emisiones de 1.100 millones tCO2eq para el periodo 2020-2030
            </label>
          </BigNumber>
          </Fact>
          <Fact>
            <h4>
              Comparación emisiones per capita de Chile con el resto del mundo 
            </h4>
            <EmisionesChart chile={perCapita} />
          </Fact>
        </div>
        <Fact baseline>
          <h4>
            Esta reducción equivale a la emisión de CO<sub>2e</sub> de{' '}
            <b>{ Math.ceil(amountCars).toLocaleString('es-ES') }</b>{' '}
            automóviles recorriendo la distancia por
            carretera entre Santiago y Coyhaique.
          </h4>
          <img src={co2} alt="CO2" width={180} />
        </Fact>
      </Wrapper>
    );
  }

export default Gei;