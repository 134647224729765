import React from 'react';
import HelpContent from './../HelpContent';

const EscenarioMitigacionAceleradaHelp = () => (
  <HelpContent>
    <h1>
        Escenario Alcanzando la Carbono Neutralidad
    </h1>
    <p> Este escenario proyecta las emisiones a nivel nacional considerando la implementación de las principales medidas de mitigación propuestas en el NDC del año 2020 (retiro de centrales a carbón, electromovididad, introducción de hidroógeno verde, electrificación de usos finales en el sector residencial, etc.).
      A diferencia del escenario Observatorio Carbono Neutralidad, acá se supone un nivel de implementación mas ambicioso de las medidas de mitigacion.
    </p>
      
    <p> Los resultados de este escenario permiten alcanzar la carbononeutralidad en emisiones netas.</p>

  
  </HelpContent>
);

export default EscenarioMitigacionAceleradaHelp;
