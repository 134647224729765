import React from 'react';
import HelpContent from './HelpContent';
import Electrificacion_Hogar from 'img/Electrificacion_Hogar.png';

const ElectrificacionCalefaccionResidencialHelp = () => (
  <HelpContent>
    <h1>Electrificación de calefacción en hogares</h1>
    <p>
    Esta medida evalúa el impacto de la introducción de sistemas de calefacción eléctrico que reemplacen el uso de combustibles fósiles y biomasa.
    La siguiente figura muestra algunos ejemplos de artefactos de calefacción eléctrica, tales como la estufa infrarrojas y el aire acondicionado.
    </p>
	  <img src={Electrificacion_Hogar} alt="Ejemplo de artefactos eléctricos para calefacción"/>
    <label>Figura: Electrificación de calefacción </label>
    <p>
    Trayectoria 1: 30% de las cassa y 30% de los departamentos utilizan calefaccionan con electricidad al 2050. 
    </p>
    <p>
    Trayectoria 2: 57% de las cassa y 70% de los departamentos utilizan calefaccionan con electricidad al 2050.
    </p>
  </HelpContent>
);

export default ElectrificacionCalefaccionResidencialHelp;
