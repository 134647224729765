import React from 'react';
import Netas from './kpis/KpiNeta';
import Gei from './kpis/kpiGei';
import Energetic_Demand from './kpis/kpiDemandaEnergetica';
import Intensity from './kpis/kpiIntensidad';
import Generacion from './kpis/kpiGeneracion';
import Potencia from './kpis/kpiPotencia';
import Factor_Emision from './kpis/kpiFactorEmision';
import PibKpi from './kpis/kpiPib';


/**
 * Permite mostrar los KPIs asociados a ciertos indicadores 
 */
const KPI = ({ mode, currentScenario, base, newValue, kpiDict, año }) => {

  if (mode === 'emisiones_netas') {
    const amount = Number(Number(newValue).toFixed(1));
    const amountCars = Math.floor(amount * 1000000 / 560);
    const perCapita = Number(((newValue)/ 18).toFixed(1));
    
    return (
      <Netas amount={amount} amountCars={amountCars} perCapita={perCapita} año={año}/>
    );
  }
  // Para revisar que kpi's trae kpiDict, revisar Chart.js.
  // Es probable que este vacio ya que trae KPI's adicionales al que viene en base/newvalue
  /* Ejemplo de uso
  if (mode == "indicador_especifico") {
    hacerAlgoCon(kpiDict["nombre_indicador"]);
  } 
  */
  
  if (mode === 'emisiones_gei') {

    const amount = Number((base - newValue).toFixed(1));
    const amountCars = Math.floor(amount * 1000000 / 560);
    const perCapita = Number((newValue / 18).toFixed(1));
    
    return (
      <Gei amount={amount} amountCars={amountCars} perCapita={perCapita} kpiDict={kpiDict} año={año}/>
      );
  }

  if (mode === 'demanda_energia') {
    const amount = base - newValue;
    const cars = Number(amount / 0.0082).toFixed(2);
    const homeValue = Math.floor(amount / 0.00215);
    return(<Energetic_Demand amount={amount} cars={cars} homeValue={homeValue} año={año}/>);
  }

  if (mode === 'intensidad_emisiones') {
    const value2007 = 1.02;
    let diff = 0;
    let perc;
    if (currentScenario.name === 'Escenario Base') {
      diff = value2007 - base;
      perc = ((diff/value2007) * 100).toFixed(2);
      return(<Intensity perc={perc} newValue={base} año={año}/>)
    }
    else{
      diff = value2007 - newValue;
      perc = ((diff/value2007) * 100).toFixed(2);
      return(<Intensity perc={perc} newValue={newValue} año={año}/>)
    }
    

    return(<Intensity perc={perc} newValue={newValue} año={año}/>)
  }

  if (mode === 'generacion') {
    return(<Generacion base={base} año={año} newValue={newValue}/>)
  }

  if (mode === 'potencia_instalada') {
    return(<Potencia año={año} kpiDict={kpiDict}/>)
  }

  if(mode === 'factor_emision_red_electrica') {
    newValue = Number(newValue);
    return(<Factor_Emision año={año} newValue={newValue}/>)

  }

  if (mode === 'pib') {
    const perc = (newValue - base) / base * 100;

    return(<PibKpi año={año} perc={perc}/>)
    
  }
  
  return null;
};

export default KPI;