import React from 'react';
import Settings from 'components/ui/Settings';
import Select from 'components/ui/Select';
import MoreInfoPopUp from 'components/ui/MoreInfoPopUp';
import EscenarioBaseHelp from 'components/help/escenarios/EscenarioBaseHelp';
import EscenarioObservatorioCarbonoNeutralHelp from 'components/help/escenarios/EscenarioObservatorioCarbonoNeutralHelp';
import EscenarioAlcanzandoCarbonoNeutralidadHelp from 'components/help/escenarios/EscenarioAlcanzandoCarbonoNeutralidadHelp';
import EscenarioMitigacionAcelerada2Help from 'components/help/escenarios/EscenarioMitigacionAcelerada2Help';
import EscenarioMitigacionCarbonoNeutralHelp from 'components/help/escenarios/EscenarioCarbonoNeutralHelp';
import EscenarioBaseTestingHelp from 'components/help/escenarios/EscenarioBaseTestingHelp';
import EscenarioCustomHelp from 'components/help/escenarios/EscenarioCustomHelp';

/**
 * Permite elegir entre los escenarios predeterminados,
 * O "valores personalizados"
 */
const ScenarioSelector = ({ selected, scenarios, onChange }) => {
  const options = scenarios.filter(sce => (sce.name !== 'custom' && sce.visible)).map(scenario => ({
    label: scenario.name,
    value: scenario.name,
  }));
  options.push({
    label: 'Valores personalizados',
    value: 'custom',
  });
  return (
    <Settings.Panel>
      <h4>Escenarios predefinidos</h4>
      <div style={{ padding: '0 10px' }}>
        <Select
          value={selected}
          options={options}
          onChange={value => {
            onChange(scenarios.find(s => s.name === value).settings);
          }}
        />
      </div>
      <MoreInfoPopUp label="Más información" info={ parseHelp(selected) }>
        Descripción Escenario
      </MoreInfoPopUp>
    </Settings.Panel>
  );
}

//dejar esto mejor
function parseHelp(value) {
  //console.log(value);
  if(value === "Escenario Base") {
    return <EscenarioBaseHelp/>;
  }
  //else if(value === "Escenario Retiro Centrales") {
  //  return <EscenarioRetiroCentralesHelp/>;
  //}
 // else if(value === "Escenario Electromovilidad") {
 //  return <EscenarioElectroMovilidadHelp/>;
 //}
  else if(value === "Observatorio Carbono Neutralidad") {
    return <EscenarioObservatorioCarbonoNeutralHelp/>;
  }
  else if(value === "Alcanzando la Carbono Neutralidad") {
    return <EscenarioAlcanzandoCarbonoNeutralidadHelp/>;
  }
  //else if(value === "Escenario Mitigación Acelerada 2") {
  //  return <EscenarioMitigacionAcelerada2Help/>;
  //}
  //else if(value === "Escenario Carbono Neutral") {
  //  return <EscenarioMitigacionCarbonoNeutralHelp/>;
  //}
  //else if(value === "Escenario Base Testing") {
  //  return <EscenarioBaseTestingHelp/>;
  //}
  //else { //value == custom
  //  return <EscenarioCustomHelp/>;
  //}
}

export default ScenarioSelector;
