import BigNumber from './layout/bigNumber';
import Fact from './layout/fact';
import Wrapper from './layout/wrapper';
import React from 'react';
import { truncOneDecimal } from 'utils/helpers';

const PibKpi = ({año, perc}) => {
    return (
        <Wrapper>
            <div>
                <Fact>
                    <h4>
                        La variación del PIB para el año {año} es de
                    </h4>
                    <BigNumber>
                        <h1>
                            {truncOneDecimal(Number(perc).toLocaleString('es-ES'))} %
                        </h1>
                    </BigNumber>
                </Fact>
            </div>
        </Wrapper>
    );
}

export default PibKpi;