import React,  { useEffect, useState } from 'react';
import Header from 'components/layout/Header';
import styled from 'styled-components';
import Footer from 'components/layout/Footer';
import NavAcercaDe from 'components/layout/NavAcercaDe';
import { Divider } from '@material-ui/core';
const Wrapper = styled.div`
  background-color: white;	
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
  margin-left: 5px;
  margin-right: 5px;
  padding-left: 20px;
  padding-right: 20px;
`;

/**
 * AcercaDe.js
 * Pagina estática que explica para que sirve la herramienta PMR.
 */
const AcercaDe = () => {

  return (
    <div> 
      <Header />
      <NavAcercaDe />
      <Wrapper>
       
	   <div>
	   <h1>Descripción de herramienta de simulación</h1>
       
		<p>
		Las simulaciones de esta página web fueron realizadas utilizando el Modelo Energético PMR desarrollado por el  <a href ="https://centroenergia.cl/" > Centro de Energía de la Universidad de Chile </a>. Este modelo es una herramienta de simulación y análisis para evaluar el impacto de distintos escenarios de mitigación de cambio climático. La herramienta computacional permite evaluar el impacto combinado de distintas medidas de mitigación que contribuyan a cumplir la meta de carbono neutralidad de emisiones en Chile hacia el año 2050. 
		</p>
		<p>	
		A continuación se describen algunos ejemplos de instrumentos y medidas de mitigación que se pueden modelar con esta herramienta:
		
		<ul>
		<li>Impuesto a las emisiones de gases de efecto invernadero.</li>
		<li>Sistema de Permisos de Emisiones Transables.</li>
		<li>Sistema de Norma de Emisiones propuesto en Ley de Cambio Climático.</li>
		<li>Presupuesto de emisiones.</li>
		<li>Límite de emisiones sectoriales.</li>
		<li>Electromovilidad en vehículos particulares, taxis y transporte público.</li>
		<li>Hidrógeno en transporte y procesos industriales.</li>
		<li>Electrificación de sistemas de calefacción.</li>
		<li>Retiro de centrales a carbón y gas natural.</li>
		<li>Introducción de sistemas de almacenamiento.</li>
		<li>Eficiencia energética.</li>
		<li>Transporte no motorizado.</li>
		<li>Etc.</li>
		</ul>
		</p>
		
		<p>
		Las principales salidas del modelo son:
		<ul>
		<li>Proyección de oferta de generación eléctrica por central y tipo de tecnología.</li>
		<li>Proyección de demanda de energía para todos los sectores del Balance Nacional de Energía.</li>
		<li>Proyección de demanda de demanda eléctrica.</li>
		<li>Proyección de capacidad instalada.</li>
		<li>Proyección de emisiones de gases de efecto invernadero (GEI).</li>
		<li>Proyección de costos de inversión, operación y mantenimiento, para todos los sectores del Balance de Energía.</li>
		</ul>
		</p>
	
		<p>
		Algunas características del modelo:
		<ul>
		<li>Modelo matemático basado en enfoque de optimización.</li>
		<li>Modelo integrado de oferta y demanda. Se proyecta en forma integrada tanto la oferta como la demanda energía, considerando la integración de ambos sectores.</li>
		<li>Proyección de la demanda de energía para los sectores transporte, industria y minería, comercial, público y residencial.</li>
		<li>Proyección de oferta de energía considerando distintas fuentes y tecnologías: centrales solares fotovoltaicas, CSP, eólica, sistemas de almacenamiento, hidroelectricidad, geotermia, etc.</li>
		<li>Representación de restricciones de corto plazo del sector generación eléctrica</li>
		<li>Proyección de todos los energéticos primarios y secundarios representados en el Balance Nacional de Energía: diésel, gasolina, kerosene, gas natural, gas licuado, carbón, electricidad, hidrógeno, etc.</li>
		<li>Proyección de emisiones basada en las metodologías del Inventario de Gases de Efecto Invernadero.</li>
		</ul>
		</p>
		
		<p>
		Para más consultas sobre esta herramienta computacional y sus proyecciones, <a href="mailto:contacto@centroenergia.cl">  contactar a desarrolladores. </a>
		</p>

		<p>
		Referencias de interés donde se ha utilizado el Modelo PMR:
		<ul>
		<li>Centro de Energía, 2019. Desarrollo de una herramienta de modelamiento y simulación de políticas climáticas. Estudio desarrollado para AGCI y Ministerio de Energía.</li>
		<li>Centro de Energía, 2019. Estudio de actualización y complementación de herramientas de prospectivas de  Largo Plazo asociadas a la demanda energética. Disponible en <a href ="https://www.cne.cl/wp-content/uploads/2020/03/Informe-final.pdf"> el siguiente link.</a></li> 
		<li>Carlos Benavides, Manuel Díaz, Raúl O’ Ryan, Sebastián Gwinner, Erick Sierra (2021). Methodology to analyse the impact of an emissions trading system in Chile, 
			Climate Policy, 21:8, 1099-1110, <a href ="https://doi.org/10.1080/14693062.2021.1954869" > Link a revista. </a> </li> 
		<li>Centro de Energía, 2021. Comparación de Instrumentos Económicos del Proyecto de Ley Marco de Cambio Climático en base al Modelo Energético PMR. 
			<a href ="https://4echile.cl/wp-content/uploads/2021/12/Informe-Final-Modelo-PMR.pdf"> Disponible en el siguiente link.</a> </li> 
		<li>Carlos Benavides, Manuel Díaz, Rodrigo Palma, et. al, 2021. Opciones para lograr la carbono-neutralidad en Chile: una evaluación bajo incertidumbre. <a href = "http://dx.doi.org/10.18235/0003527"> Disponible en página del BID. </a></li> 
		<li> Carlos Benavides, Manuel Díaz, Rodrigo Palma, et. al, 2022. Recomendaciones y Pasos Necesarios para Desplegar un Esquema de Instrumentos Económicos Que Catalice la 
			Transición Energética Necesaria para Cumplir con la NDC de Chile y el Objetivo de Cero Emisiones de GEI.
			<a href = "https://documents1.worldbank.org/curated/en/433541647323669007/pdf/Recomendaciones-y-Pasos-Necesarios-para-Desplegar-un-Esquema-de-Instrumentos-Economicos-Que-Catalice-la-Transicion-Energetica-Necesaria-para-Cumplir-con-la-NDC-de-Chile-y-el-Objetivo-de-Cero-Emisiones-de-Gei.pdf"> Disponible en página del Banco Mundial. </a> </li> 
		<li>Vicente Sepúlveda (2022). Modelo de proyección de escenarios de carbono neutralidad bajo incertidumbre. Memoria de título. Disponible en <a href = "https://repositorio.uchile.cl/handle/2250/185729"> el siguiente link.</a></li>
		<li>Diego Cancino (2022). Escenarios de carbono neutralidad para Chile. Memoria de título. Disponible en <a href = "https://repositorio.uchile.cl/handle/2250/189406"> el siguiente link.</a></li>
		<li>Maximiliano Donoso (2022). Evaluación de escenarios de uso de hidrógeno en Chile mediante un modelo de planificación energética. Memoria de título. Disponible en <a href = "https://repositorio.uchile.cl/handle/2250/189188"> el siguiente link.</a></li>
		<li>Vicente Sepúlveda, Carlos Benavides (2022). Carbon Neutrality Scenario Projection Model under Uncertainty, 2022 IEEE International Conference on Automation/XXV Congress of the Chilean Association of Automatic Control (ICA-ACCA), Curicó, Chile, 2022, pp. 1-6. Disponible en <a href = "https://doi.org/10.1109/ICA-ACCA56767.2022.10005931"> el siguiente link.</a></li>
		<li> Centro de Energía, Centro de Ciencia del Clima y la Resiliencia (CR)2 (2019-2024). Taller de simulación en Diplomado de Cambio Climático y Desarrolllo Bajo en Carbono. Información del diplomado <a href = "https://www.diplomacambioclimatico.cl/"> en el siguiente link. </a> </li> 
		<li>Centro de Energía, Centro de Ciencia del Clima y la Resiliencia (CR)2 (2022-2024). Observatorio Carbono Neutralidad. Plataforma disponible en: <a href = "https://observatoriocarbononeutral.cl/"> https://observatoriocarbononeutral.cl/ </a> </li> 
		</ul>

		</p>
		
		</div>
		
      </Wrapper>
	  <Divider variant='middle'/>
	  <center><Footer/></center>
    </div>
  );
};

export default AcercaDe;
