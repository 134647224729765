import styled from 'styled-components';

const BigNumber = styled.div`
  color: var(--primary-color);

  h1 {
    width: 100%;
    text-align: center;
    font-weight: normal;
    font-size: 48px;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    font-size: 80px;
  }

  label {
    color: var(--dark-blue-color);;
    display: block;
    width: 200px;
    margin: 0 auto;
    text-align: center;
  }
`;

export default BigNumber;