import React from 'react';
import HelpContent from './HelpContent';
import TransporteNoMotorizado from 'img/particion_bicicleta.PNG';

const TransporteNoMotorizadoHelp = () => (
  <HelpContent>
    <h1>Transporte No Motorizado</h1>
    <p>
    Esta medida analiza el impacto de la promoción de los usos de transporte no motorizado en la reducción de emisiones de GEI. La planificación y desarrollo 
    de las ciudades deberá considerar la integración de estas formas de transporte, tales como el uso de bicicleta y la caminata.
    </p>
    <p>
     A nivel internacional se observan que existen ciudades con políticas de masificación del transporte en bicicleta. En un número importante de ciudades la participación 
     modal supera el 20% y en las ciudades de mayor partición el uso de la bicicleta incluso alcanza incluso el 45%. La siguiente muestra la participación modal del modo de transporte bicicleta 
     para distintas ciudades del mundo.
     </p>

     <img src={TransporteNoMotorizado} alt="Particion modal bicicleta" />
    <label>Figura: Participación de viajes en modo bicicleta. Fuente: Elaboración propia a partir de información obtenida 
    en http://www.cityclock.org/urban-cycling-mode-share/#.VLZ83PmG8vw</label>
  
    <p>
    La trayectoría 1 considera que el 10% de los viajes privados son reemplazaos por modos no motorizados.
    </p>
    <p>
    La trayectoría 2 considera que el 20% de los viajes privados son reemplazaos por modos no motorizados.
    </p>
  </HelpContent>
);

export default TransporteNoMotorizadoHelp;
