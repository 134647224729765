import React from 'react';
import HelpContent from './../HelpContent';

const EscenarioMitigacionAceleradaHelp = () => (
  <HelpContent>
    <h1>
        Escenario Observatorio Carbono Neutralidad
    </h1>
    <p> Este escenario proyecta las emisiones a nivel nacional considerando la implementación de las principales medidas de mitigación propuestas en el NDC del año 2020 (retiro de centrales a carbón, electromovididad, introducción de hidroógeno verde, electrificación de usos finales en el sector residencial, etc.). </p>
      
    <p> Los resultados de este escenario corresponde a los análisis del observatorio de Carbono Neutralidad presentados el 9 de abril de 2024.</p>

  
  </HelpContent>
);

export default EscenarioMitigacionAceleradaHelp;
