import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 30px 0;

  & > div {
    font-size: 11px;
    text-transform: uppercase;
    cursor: pointer;
  }
`;

/**
 * Permite construir la leyenda para un slider, con los posibles valores 
 */
const SliderLegend = ({ options, onChange }) => (
  <Wrapper>
    {
      options && options.map((option) => (
        <div key={option.value} onClick={() => {
          onChange(option.value)
        }}>
          { option.label }
        </div>
      ))
    }
  </Wrapper>
);

export default SliderLegend;
