import React,  { useState } from 'react';
import styled from 'styled-components';

const Link = styled.div`
  font-size: 12px;
  width: 100%;
  text-align: right;
  padding: 10px 10px 0 0;
  text-decoration: underline;
  color:  #666;
  cursor: pointer;
`;

const Info = styled.div`
  width: calc(100% - 20px);
  background-color: #dedede;
  padding: 10px;
  margin: 10px;
  border-radius: 3px;
`;

const MoreInfo = ({ label, children }) => {
  const [infoVisible, setInfoVisible] = useState(false);

  return (
    <div>
      <Link onClick={() => setInfoVisible(!infoVisible)}>
        { infoVisible ? 'Ocultar' : label }
      </Link>
      {
        infoVisible && (
          <Info>
            { children }
          </Info>
        )
      }
    </div>
  );
};

export default MoreInfo;
